import { Route } from 'vue-router';

import { RouteName, routeNames } from '@/router/model';
import type { RootState } from '@/store/model';

type SupplyShipmentValidator = (route: Route, rootState: RootState) => boolean;

// SHIPMENT STEPS

const validateSupplyShipmentScanSupplyBoxStep: SupplyShipmentValidator = (_route, rootState) =>
  rootState.supplies.shippingFlow.scanSupplyBoxScanState !== null;

/**
 * Validators to determine when the `Next` button should be enabled on
 * supply transfer steps.
 */
export const supplyShipmentStepValidators: { [key in RouteName]?: SupplyShipmentValidator } = {
  [routeNames.SUPPLY_SHIPMENT_SCAN_SUPPLY_BOX]: validateSupplyShipmentScanSupplyBoxStep,
};

// TODO: Shared flow config now supports validation logic, we can refactor
// to use it.
/** Determine whether the `Next` button should be enabled on the current step. */
export const getIsCurrentSupplyShipmentStepValid = (
  route: Route,
  rootState: RootState,
): boolean => {
  const routeName = route.name;
  if (routeName) {
    return supplyShipmentStepValidators[routeName]?.(route, rootState) ?? true;
  }
  return true;
};
