import { Route } from 'vue-router';

import { getSupplyTransferProcedureIDs } from '@/functions/supplies/get';
import { RouteName, routeNames } from '@/router/model';
import type { RootState } from '@/store/model';

type SupplyTransferValidator = (route: Route, rootState: RootState) => boolean;

// Helpers

/**
 * Validation helper to define if all required conditions around scanning
 * supplyTransfer procedure kit are fulfilled.
 */
const isSupplyTransferScanProcedureKitStepValid = (
  procedureID: string | undefined,
  rootState: RootState,
): boolean => !!rootState.procedures.procedures[procedureID ?? '']?.kitSupplyItem;

/**
 * Validation helper to define if all required conditions around scanning
 * supplyTransfer procedure supplyItems are fulfilled.
 */
const isSupplyTransferScanProcedureSuppliesValid = (
  procedureID: string | undefined,
  rootState: RootState,
): boolean => !!rootState.procedures.procedures[procedureID ?? '']?.supplyItems;

/**
 * Validation helper to define if all required conditions around
 * the instruction sheet being scanned are fulfilled.
 */
const isSupplyTransferProcedureScanInstructionSheetStepValid = (
  procedureID: string | undefined,
  rootState: RootState,
): boolean =>
  rootState.supplies.packingFlow
    .procedures[procedureID ?? '']?.supplyTransferProcedureInstructionSheetScanned
  ?? false;

/**
 * Validation helper to define if all required conditions around
 * the requisition form being scanned are fulfilled.
 */
const isSupplyTransferProcedureScanRequisitionFormStepValid = (
  procedureID: string | undefined,
  rootState: RootState,
): boolean =>
  rootState.supplies.packingFlow
    .procedures[procedureID ?? '']?.supplyTransferProcedureRequisitionFormScanned
  ?? false;

/**
 * Validation helper to define if all required conditions around scanning
 * for a single supplyTransfer procedure are fulfilled (kit + supplyItems).
 */
export const isSupplyTransferProcedureValid = (
  procedureID: string | undefined,
  rootState: RootState,
): boolean => isSupplyTransferScanProcedureKitStepValid(procedureID, rootState)
  && isSupplyTransferScanProcedureSuppliesValid(procedureID, rootState)
  && isSupplyTransferProcedureScanInstructionSheetStepValid(procedureID, rootState);

const isSupplyTransferProceduresStepValid = (
  supplyTransferID: string | undefined,
  rootState: RootState,
): boolean => {
  if (!supplyTransferID) return false;

  const procedureIDs = getSupplyTransferProcedureIDs(supplyTransferID);
  return procedureIDs.every((procedureID) =>
    isSupplyTransferProcedureValid(procedureID, rootState),
  );
};

// ROOT STEPS

const validateSupplyTransferProceduresStep: SupplyTransferValidator = (route, rootState) => {
  const { supplyTransferID } = route.params;
  return isSupplyTransferProceduresStepValid(supplyTransferID, rootState);
};

// PROCEDURE STEPS

const validateSupplyTransferScanProcedureKitStep: SupplyTransferValidator = (route, rootState) => {
  const { procedureID } = route.params;
  return isSupplyTransferScanProcedureKitStepValid(procedureID, rootState);
};

const validateSupplyTransferScanProcedureSuppliesStep: SupplyTransferValidator = (_route, rootState) => {
  const { scanProcedureSuppliesScanState } = rootState.supplies.packingFlow;
  // Data may still be loading, do not validate with 0 kits.
  const noSuppliesToScan = !scanProcedureSuppliesScanState.length;
  const someSupplyNotScanned = scanProcedureSuppliesScanState.some((supply) => !supply.isScanned);

  const validationFailed = noSuppliesToScan || someSupplyNotScanned;
  return !validationFailed;
};

/**
 * Always true, as we do not store data representing whether the user has
 * printed the sheet, and it is possible that the PDF could open in the same
 * tab. The user must scan the printed sheet in the next step, which we can
 * validate.
 */
const validateSupplyTransferPrintInstructionSheetStep: SupplyTransferValidator = (_route, _rootState) => true;

/**
 * Always true, as we do not store data representing whether the user has
 * printed the sheet, and it is possible that the PDF could open in the same
 * tab. The user must scan the printed sheet in the next step, which we can
 * validate.
 */
const validateSupplyTransferPrintRequisitionFormStep: SupplyTransferValidator = (_route, _rootState) => true;

const validateSupplyTransferScanInstructionSheetStep: SupplyTransferValidator = (route, rootState) => {
  const { procedureID } = route.params;
  return isSupplyTransferProcedureScanInstructionSheetStepValid(procedureID, rootState);
};

const validateSupplyTransferScanRequisitionFormStep: SupplyTransferValidator = (route, rootState) => {
  const { procedureID } = route.params;
  return isSupplyTransferProcedureScanRequisitionFormStepValid(procedureID, rootState);
};

const validateSupplyTransferCloseProcedureKitStep: SupplyTransferValidator = (_route, _rootState) => true;

// SHIPMENT STEPS

const validateSupplyTransferScanSupplyBoxStep: SupplyTransferValidator = (route, rootState) => {
  const { supplyShipmentID } = route.params;
  if (supplyShipmentID) {
    const supplyShipment = rootState.supplies.shipments[supplyShipmentID];
    return !!supplyShipment?.shipmentBoxSupplyItem;
  }
  return false;
};

const validateSupplyTransferScanKitsToSupplyBoxStep: SupplyTransferValidator = (_route, rootState) => {
  const { scanKitsToSupplyBoxScanState } = rootState.supplies.packingFlow;
  // Data may still be loading, do not validate with 0 kits.
  const noKitsToScan = !scanKitsToSupplyBoxScanState.length;
  const someKitNotScanned = scanKitsToSupplyBoxScanState.some((kit) => !kit.isScanned);

  const validationFailed = noKitsToScan || someKitNotScanned;
  return !validationFailed;
};

/**
 * Always true, as we do not store data representing whether the user has
 * printed the label. The user must scan the printed label in the next step,
 * which we can validate.
 */
const validateSupplyTransferPrintShippingLabelStep: SupplyTransferValidator = () => true;

const validateSupplyTransferScanShippingLabelStep: SupplyTransferValidator = (route, rootState) => {
  const { supplyShipmentID } = route.params;
  if (supplyShipmentID) {
    const supplyShipment = rootState.supplies.shipments[supplyShipmentID];
    return !!supplyShipment?.scannedShippingLabelBarcode;
  }
  return false;
};

const validateSupplyTransferSupplyBoxPhotoStep: SupplyTransferValidator = (route, rootState) => {
  const { supplyShipmentID } = route.params;
  if (supplyShipmentID) {
    const supplyShipment = rootState.supplies.shipments[supplyShipmentID];
    return !!supplyShipment?.shipmentBoxImage;
  }
  return false;
};

const validateSupplyTransferDonePackingStep: SupplyTransferValidator = (_route, _rootState) => true;

/**
 * Validators to determine when the `Next` button should be enabled on
 * supply transfer steps.
 */
export const supplyTransferStepValidators: { [key in RouteName]?: SupplyTransferValidator } = {
  [routeNames.SUPPLY_TRANSFER_PROCEDURES]: validateSupplyTransferProceduresStep,
  [routeNames.SUPPLY_TRANSFER_SCAN_PROCEDURE_KIT]: validateSupplyTransferScanProcedureKitStep,
  [routeNames.SUPPLY_TRANSFER_SCAN_PROCEDURE_SUPPLIES]: validateSupplyTransferScanProcedureSuppliesStep,
  [routeNames.SUPPLY_TRANSFER_PRINT_REQUISITION_FORM]: validateSupplyTransferPrintRequisitionFormStep,
  [routeNames.SUPPLY_TRANSFER_SCAN_REQUISITION_FORM]: validateSupplyTransferScanRequisitionFormStep,
  [routeNames.SUPPLY_TRANSFER_PRINT_INSTRUCTION_SHEET]: validateSupplyTransferPrintInstructionSheetStep,
  [routeNames.SUPPLY_TRANSFER_SCAN_INSTRUCTION_SHEET]: validateSupplyTransferScanInstructionSheetStep,
  [routeNames.SUPPLY_TRANSFER_CLOSE_PROCEDURE_KIT]: validateSupplyTransferCloseProcedureKitStep,
  [routeNames.SUPPLY_TRANSFER_SCAN_SUPPLY_BOX]: validateSupplyTransferScanSupplyBoxStep,
  [routeNames.SUPPLY_TRANSFER_SCAN_KITS_TO_SUPPLY_BOX]: validateSupplyTransferScanKitsToSupplyBoxStep,
  [routeNames.SUPPLY_TRANSFER_PRINT_SHIPPING_LABEL]: validateSupplyTransferPrintShippingLabelStep,
  [routeNames.SUPPLY_TRANSFER_SCAN_SHIPPING_LABEL]: validateSupplyTransferScanShippingLabelStep,
  [routeNames.SUPPLY_TRANSFER_SUPPLY_BOX_PHOTO]: validateSupplyTransferSupplyBoxPhotoStep,
  [routeNames.SUPPLY_TRANSFER_DONE_PACKING]: validateSupplyTransferDonePackingStep,
};

// TODO: Shared flow config now supports validation logic, we can refactor
// to use it.
/** Determine whether the `Next` button should be enabled on the current step. */
export const getIsCurrentSupplyTransferStepValid = (
  route: Route,
  rootState: RootState,
) => {
  const { supplyTransferID } = route.params;
  if (!supplyTransferID) return false;
  const flowIsLoading = !rootState.supplies.packingFlow
    .supplyTransferFlowLoadingCompleted[supplyTransferID];
  if (flowIsLoading) return false;

  const routeName = route.name;
  if (routeName) {
    return supplyTransferStepValidators[routeName]?.(route, rootState) ?? true;
  }
  return true;
};
