import {
  ByID,
  ByName,
  DbRef,
  Itinerary,
  ServiceRegion,
  Shift,
  ShiftOffer,
  ShiftOfferStatus,
} from '@caresend/types';
import {
  AnyGetters,
  firebaseBind,
  firebaseUnbind,
  toastError,
  toastErrorAndReport,
} from '@caresend/ui-components';
import {
  formatLocalDate,
  formatTimeZoneDateSimple,
  nullishFilter,
  timeZoneDateMatchesLocalDate,
} from '@caresend/utils';
import dayjs from 'dayjs';
import update from 'immutability-helper';

import { getShiftsOfferedRequest } from '@/database/firebase/API';
import type { RootState } from '@/store/model';
import { getNextDay, sortItineraries } from '@/store/modules/shifts/helpers';
import { ShiftOfferData, ShiftStateData, ShiftsActions, ShiftsState } from '@/store/modules/shifts/model';

type S = ShiftsState;

const initData = (): ShiftStateData => ({
  shiftDeclineReason: undefined,
});

const shiftsState: S = {
  data: initData(),
  shifts: {},
};

const shiftsMutations = {
  'shifts/DELETE_SHIFT': (state: S, id: string) => {
    state.shifts = update(state.shifts ?? {}, {
      $unset: [id],
    });
  },

  'shifts/SET_SHIFT_DECLINE_REASON': (state: S, newDeclineReason: string | undefined) => {
    state.data.shiftDeclineReason = newDeclineReason;
  },

  'shifts/SET_SHIFT': (state: S, newShift: Shift) => {
    state.shifts = {
      ...state.shifts,
      [newShift.id]: newShift,
    };
  },

  'shifts/SET_SHIFTS': (state: S, newShifts: ByID<Shift>) => {
    state.shifts = update(state.shifts, {
      $merge: newShifts,
    });
  },

  'shifts/RESET_SHIFTS': (state: S) => {
    state.shifts = {};
  },
};

const shiftsActions: ShiftsActions = {
  'shifts/bindShift': async ({ commit, dispatch }, { id, fetchItineraryData }) => {
    const path = `${DbRef.SHIFTS}/${id}`;
    try {
      await firebaseBind<Shift>(path, async (item) => {
        if (!item) {
          return dispatch('shifts/unbindShift', { id });
        }
        commit('shifts/SET_SHIFTS', {
          [item.id]: item,
        });
        const itineraryIDs = Object.keys(item.itineraries ?? {});
        /**
         * If requested, fetch the itinerary data for each itinerary
         * associated with this shift
         */
        if (fetchItineraryData && itineraryIDs.length > 0) {
          const promises = itineraryIDs.map(async (itineraryID) => {
            await dispatch('waypoint/fetchItineraryData', itineraryID);
          });
          await Promise.all(promises);
        }
      });
    } catch (e) {
      toastError('Error getting shift details');
    }
  },

  'shifts/bindUserShifts': async ({ dispatch, rootState }) => {
    const { user } = rootState.auth;
    const allUserShiftIDs = Object.keys(user?.shifts ?? {});
    const promises = allUserShiftIDs?.map(async (id) => {
      try {
        await dispatch('shifts/bindShift', { id });
      } catch (error) {
        toastErrorAndReport(error);
      }
    });
    await Promise.all(promises);
  },

  'shifts/loadOfferedShifts': async ({ commit }) => {
    const { shifts } = await getShiftsOfferedRequest();
    commit('shifts/SET_SHIFTS', shifts);
  },

  'shifts/unbindShift': ({ commit }, { id }) => {
    const path = `${DbRef.SHIFTS}/${id}`;
    firebaseUnbind(path);
    commit('shifts/DELETE_SHIFT', id);
  },
};

const shiftsGetters = {
  /** Sorted most recent first */
  'shifts/getAllPastShifts': (
    state: S,
    getters: AnyGetters,
    rootState: RootState,
  ): Shift[] => {
    const allUserShifts = shiftsGetters[
      'shifts/getAllUserShiftsSorted'
    ](state, getters, rootState);

    allUserShifts.reverse();

    const userBrowserDate = formatLocalDate(Date.now(), 'YYYY-MM-DD');

    return allUserShifts.filter((shift) => {
      if (!shift.startTime.timestamp) return false;
      const shiftDate = formatTimeZoneDateSimple(shift.startTime, 'YYYY-MM-DD');
      // String date comparison works with YYYY-MM-DD format.
      return shiftDate < userBrowserDate;
    });
  },

  /** Sorted soonest first */
  'shifts/getAllUpcomingShifts': (
    state: S,
    getters: AnyGetters,
    rootState: RootState,
  ): Shift[] => {
    const allUserShifts = shiftsGetters[
      'shifts/getAllUserShiftsSorted'
    ](state, getters, rootState);

    const userBrowserDate = formatLocalDate(Date.now(), 'YYYY-MM-DD');

    return allUserShifts.filter((shift) => {
      if (!shift.startTime.timestamp) return false;
      const shiftDate = formatTimeZoneDateSimple(shift.startTime, 'YYYY-MM-DD');
      // String date comparison works with YYYY-MM-DD format.
      return shiftDate >= userBrowserDate;
    });
  },

  'shifts/getShiftItineraries': (state: S, _getters: AnyGetters, rootState: RootState) =>
    (shiftID: string): Itinerary[] => {
      const shift = state.shifts[shiftID];
      if (!shift) return [];

      const itineraries = Object.keys(shift.itineraries ?? {})
        .map((itineraryID) => rootState.waypoint.itineraries[itineraryID])
        .filter(nullishFilter)
        .sort(sortItineraries);

      return itineraries;
    },

  /**
   * By formatted date.
   *
   * Example: `{ 'Saturday, August 6': [...(shifts)] }`
   */
  'shifts/getUserShiftsByDate': (
    state: S,
    getters: AnyGetters,
    rootState: RootState,
  ) => (
    /** Maximum number of days to include */
    dayLimit: number,
    /**
     * If true, only shifts within 24 hours and later returned.
     * If false, only shifts within 24 hours and earlier returned.
     */
    past = false,
  ): ByName<Shift[]> => {
    const getter = past
      ? 'shifts/getAllPastShifts'
      : 'shifts/getAllUpcomingShifts';
    const allShiftsInCategory = shiftsGetters[getter](state, getters, rootState);

    let dayCount = 0;

    // Group shifts by date, with limit
    const shiftsByDate = allShiftsInCategory.reduce<ByName<Shift[]>>((
      byDate,
      shift,
    ) => {
      if (dayCount >= dayLimit) return byDate;
      const shiftDate = formatTimeZoneDateSimple(shift.startTime, 'dddd, MMMM D');
      if (byDate[shiftDate]) {
        return update(byDate, { [shiftDate]: { $push: [shift] } });
      }
      dayCount += 1;
      return update(byDate, { [shiftDate]: { $set: [shift] } });
    }, {});

    return shiftsByDate;
  },

  'shifts/getAllUserShiftsSorted': (
    state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ): Shift[] => {
    const { user } = rootState.auth;
    if (!user) return [];
    const allUserShiftIDs = Object.keys(user?.shifts ?? {});

    return allUserShiftIDs.map((id) => state.shifts[id])
      .filter(nullishFilter)
      .sort((a, b) => (a.startTime?.timestamp ?? 0) - (b.startTime?.timestamp ?? 0));
  },

  'shifts/getShiftByID': (state: S) => (shiftID: string | undefined): Shift | undefined =>
    shiftID ? state.shifts[shiftID] : undefined,

  'shifts/getNextShiftDate': (state: S) => (currentDaysToLoad: number, displayPastItems: boolean): number =>
    getNextDay(state.shifts, currentDaysToLoad, displayPastItems),

  'shifts/getShiftByDate': (state: S) => (date: string): Shift | undefined =>
    Object.values(state.shifts)
      .find(({ startTime }) => timeZoneDateMatchesLocalDate(startTime, date)),

  'shifts/getShiftOfferTitleByID': (state: S) => (shiftID: string): string => {
    const timestamp = state.shifts[shiftID]?.startTime.timestamp;
    if (!timestamp) return 'Accept your shift';

    return `Shift for ${dayjs(timestamp).format('dddd')}, ${dayjs(timestamp).format('MMMM D')}`;
  },

  'shifts/getShiftOfferByShiftID': (
    state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (shiftID: string): ShiftOffer | undefined => {
    const nurse = rootState.auth.user;
    if (!nurse) return;
    const shiftOffer = rootState.auth.user?.shiftOffers?.[shiftID];
    if (!shiftOffer) {
      const shift = state.shifts[shiftID];
      return shift?.nursesOffered?.[nurse.id];
    }
    return shiftOffer;
  },

  /**
   * By formatted date.
   *
   * Example:
   * ```
   * {
   *   'Saturday, August 6': [
   *      { shift: (...), shiftOffer: (...) },
   *      ...
   *    ]
   * }
   * ```
   */
  'shifts/getShiftOfferDataByDate': (
    state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ): ByName<ShiftOfferData[]> => {
    const shiftOffers = Object.values(rootState.auth.user?.shiftOffers ?? {});
    const pendingOffers = shiftOffers.filter((offer) =>
      offer.status === ShiftOfferStatus.NO_RESPONSE,
    );
    // Create offerData and sort by date
    const pendingOfferDataByDate: ShiftOfferData[] = pendingOffers
      .map((offer) => {
        const { shiftID } = offer;
        const shift = state.shifts[shiftID];

        if (!shift) return;
        return {
          shift,
          shiftOffer: offer,
        };
      })
      .filter(nullishFilter)
      .sort((a, b) =>
        (a.shift.startTime?.timestamp ?? 0) - (b.shift.startTime?.timestamp ?? 0),
      );

    // Group offers by date
    const offerDataByDate = pendingOfferDataByDate.reduce<ByName<ShiftOfferData[]>>((
      data,
      offerData,
    ) => {
      const { shift } = offerData;
      const shiftDate = formatTimeZoneDateSimple(shift.startTime, 'dddd, MMMM D');
      const newShiftDataForDate = update(data[shiftDate] ?? [], {
        $push: [offerData],
      });
      return {
        ...data,
        [shiftDate]: newShiftDataForDate,
      };
    }, {});

    return offerDataByDate;
  },

  'shifts/getShiftServiceRegions': (
    _state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (shift: Shift): ServiceRegion[] | undefined => {
    if (!shift?.serviceRegions) return;
    return Object.keys(shift.serviceRegions)
      .map((id) => rootState.variables.variables?.serviceRegions[id])
      .filter(nullishFilter);
  },
};

export type ShiftsGetters = typeof shiftsGetters;

export const shiftsModule = {
  actions: shiftsActions,
  getters: shiftsGetters,
  mutations: shiftsMutations,
  state: shiftsState,
};

export type ShiftsModule = typeof shiftsModule;
