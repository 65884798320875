import { getStore, useOrdersStore } from '@caresend/ui-components';
import { deduplicateArray, getLatLngDeviationInFt, isNullish, nullishFilter } from '@caresend/utils';
import dayjs from 'dayjs';

export const getActualWaypointDuration = (
  waypointID: string,
): number => {
  const waypoint = getStore().getters['waypoint/getWaypointByID'](waypointID);
  /** get duration in minutes */
  return dayjs(Date.now()).diff(waypoint?.status.timestamp, 'minute');
};

/**
 * the sum of the estimated durations on all of the waypoint actions on the waypoint
 */
export const getEstimatedWaypointDuration = (
  waypointID: string,
): number => {
  const waypointActions = getStore().getters['waypoint/getWaypointActionsByWaypointID'](waypointID);
  const estimatedWaypointActionsDurationArray = waypointActions?.map(
    (action) => action.duration.actionDuration,
  );
  /** get sum of all waypoint action estimated durations in minutes */
  const estimatedDuration = estimatedWaypointActionsDurationArray?.reduce(
    (totalEstimatedDuration, actionDuration) => totalEstimatedDuration + actionDuration,
  );
  return estimatedDuration;
};

export const waypointDurationWasLongerThanExpected = (
  waypointID: string,
): boolean => {
  const actualWaypointDuration = getActualWaypointDuration(waypointID);
  const estimatedWaypointDuration = getEstimatedWaypointDuration(waypointID);
  return actualWaypointDuration > estimatedWaypointDuration;
};

export const getIsClinicianTooFarFromWaypoint = (
  waypointID: string,
): boolean => {
  const store = getStore();

  const currentLocation = store.state.auth.user?.location?.current;
  const waypoint = store.getters['waypoint/getWaypointByID'](waypointID);

  if (
    isNullish(waypoint)
    || isNullish(currentLocation)
  ) return false;

  const deviationInFeet = getLatLngDeviationInFt(
    waypoint.location,
    currentLocation,
  );

  return deviationInFeet > 1000;
};

export const getFirstProcedureByWaypointID = (
  waypointID: string | undefined,
) => {
  const store = getStore();

  const waypoint = store.getters['waypoint/getWaypointByID'](waypointID);
  const firstWaypointAction = store.getters[
    'waypoint/getWaypointActionByID'
  ](Object.values(waypoint?.waypointActions ?? {})[0]?.id);

  const firstProcedureID = Object.keys(firstWaypointAction?.procedures ?? {})[0];
  if (!firstProcedureID) return undefined;
  return store.state.procedures.procedures[firstProcedureID];
};

export const getOrderByWaypointID = (
  waypointID: string,
) => {
  const ordersStore = useOrdersStore();
  const firstProcedure = getFirstProcedureByWaypointID(waypointID);
  return ordersStore.getters.getOrder(firstProcedure?.orderID);
};

export const getBookingByWaypointID = (
  waypointID: string | undefined,
) => {
  const firstProcedure = getFirstProcedureByWaypointID(waypointID);
  if (!firstProcedure?.bookingID) return;
  return getStore().state.bookings.bookings[firstProcedure?.bookingID];
};

export const getPrescriberIDByWaypointID = (
  waypointID: string,
) => getOrderByWaypointID(waypointID)?.prescriberID;

export const getPrescriberIDByProcedureID = (
  procedureID: string,
) => {
  const store = getStore();
  const ordersStore = useOrdersStore();

  const procedure = store.state.procedures.procedures[procedureID];
  return ordersStore.getters.getOrder(procedure?.orderID)?.prescriberID;
};

export const getBookingByProcedureID = (
  procedureID: string,
) => {
  const store = getStore();

  const procedure = store.state.procedures.procedures[procedureID];
  if (!procedure?.bookingID) return;
  return store.state.bookings.bookings[procedure?.bookingID];
};

export const getBookingByWaypointActionID = (
  waypointActionID: string,
) => {
  const waypointAction = getStore().getters['waypoint/getWaypointActionByID'](waypointActionID);
  const firstProcedure = Object.values(waypointAction?.procedures ?? {})[0];
  if (isNullish(firstProcedure)) return null;
  return getBookingByProcedureID(firstProcedure.id);
};

export const getWaypointByProcedureID = (
  procedureID: string,
) => {
  const store = getStore();

  const procedure = store.state.procedures.procedures[procedureID];
  const waypointAction = store.getters['waypoint/getWaypointActionByID'](
    Object.keys(procedure?.waypointActions ?? {})[0],
  );
  return store.getters['waypoint/getWaypointByID'](waypointAction?.waypointID);
};

export const getOrderIDsByWaypointID = (
  waypointID: string,
): string[] => {
  const store = getStore();

  const waypointActions = Object.values(store.getters['waypoint/getWaypointByID'](
    waypointID)?.waypointActions ?? {},
  ).map((waypointActionID) => store.getters['waypoint/getWaypointActionByID'](
    waypointActionID.id,
  ))
    .filter(nullishFilter);
  const procedures = waypointActions
    .map((waypointAction) => Object.keys(waypointAction.procedures ?? {}))
    .filter(nullishFilter)
    .flat()
    .map((procedureID) => store.state.procedures.procedures[procedureID])
    .filter(nullishFilter);
  const orderIDs = procedures.map((procedure) => procedure.orderID);
  return deduplicateArray(orderIDs).filter(nullishFilter);
};

export const getProcedureIDsByWaypointID = (
  waypointID: string,
): string[] => {
  const waypointActions = getStore().getters['waypoint/getWaypointActionsByWaypointID'](waypointID);
  const procedureIDs = waypointActions.map(
    (waypointAction) => Object.values(waypointAction.procedures ?? {}),
  )
    .flat()
    .map((procedureObj) => procedureObj.id).filter(nullishFilter);
  return procedureIDs;
};
