import { HeadingValues, getStore } from '@caresend/ui-components';
import { computed } from 'vue';
import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';
import { routesCentrifugationProcessing } from '@/router/routes/itineraryFlow/centrifugation';
import { routesPackedMailInWaypoint } from '@/router/routes/itineraryFlow/packedMailInWaypoint';
import { routesPatientWaypoint } from '@/router/routes/itineraryFlow/patientWaypoint';
import { routesItineraryFlowRoot } from '@/router/routes/itineraryFlow/root';
import { routesUnpackedMailInAction } from '@/router/routes/itineraryFlow/unpackedMailInAction';

const routesItinerary: RouteConfig[] = [
  {
    path: '/:partnerName?/itinerary-legacy/:itineraryID/action-blocked',
    name: routeNames.ACTION_BLOCKED,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/shared/ItineraryFlowActionBlocked.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: () => computed<HeadingValues>(() => ({
        title: 'Action blocked',
      })),
    },
  },
  {
    path: '/:partnerName?/itinerary-legacy/:itineraryID/done',
    name: routeNames.DONE,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@/components/itinerary/Done/Done.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
    },
  },
  {
    path: '/:partnerName?/itinerary-legacy/:itineraryID',
    props: true,
    children: [
      ...routesPatientWaypoint,
      ...routesCentrifugationProcessing,
      ...routesUnpackedMailInAction,
      ...routesPackedMailInWaypoint,
      ...routesItineraryFlowRoot,
    ],
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/ItineraryFlow.vue'
    ),
    meta: {
      authRequired: true,
      heading: () => computed<HeadingValues>(() => ({
        title: 'Itinerary details',
        backLocation: {
          name: routeNames.SHIFT,
        },
      })),
      loadUntilTrue: (route) => computed<boolean>(() => {
        const store = getStore();
        const { itineraryID } = route.params;
        if (!itineraryID) return true;
        const loadingError = store.state.itineraryFlow.localState.flowLoadingError;
        const loadingItinerary
          = !store.state.itineraryFlow.localState.flowDataIsReady[itineraryID];
        const doneLoading = !!loadingError || !loadingItinerary;
        return doneLoading;
      }),
      profileCompletedRequired: true,
    },
  },
  {
    path: '/:partnerName?/offer/:itineraryID',
    name: routeNames.OFFER,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@/views/nurse/Offer.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
    },
  },
  {
    path: '/:partnerName?/offer/:itineraryID/confirm',
    name: routeNames.OFFER_CONFIRMATION,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@/components/itinerary/OfferAlert/OfferAlert.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
    },
  },
  {
    path: '/:partnerName?/offer/:itineraryID/confirmed',
    name: routeNames.OFFER_CONFIRMED,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@/components/itinerary/OfferConfirmed.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
    },
  }, {
    path: '/:partnerName?/itinerary/:itineraryID?',
    name: routeNames.ITINERARY,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@/views/nurse/itinerary/ItineraryWrapper.vue'
    ),
  },
];

export { routesItinerary };
