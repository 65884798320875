import { TrackingEvent } from '@caresend/types';
import { getStore, reportException } from '@caresend/ui-components';

import { getBookingByWaypointActionID } from '@/functions/itinerary/waypoints';
import { trackEvent } from '@/functions/tracking/tracking';

/** Only tracked upon result submission */
export const trackChartingDone = (
  waypointActionID: string,
  procedureID: string,
  productID: string,
  resultText?: string,
) => {
  const store = getStore();
  const waypointAction = store.state.waypoint.waypointActions[waypointActionID];

  if (!waypointAction) {
    reportException(`Charting Done tracking failed on procedure ${procedureID}`);
    return;
  }

  trackEvent(TrackingEvent.CHARTING_DONE, {
    waypointActionID,
    itineraryID: waypointAction.itineraryID,
    procedureID,
    result: resultText,
    productID,
  });
};

export const trackCheckDob = (
  waypointActionID: string,
) => {
  trackEvent(TrackingEvent.CHECK_DOB, {
    waypointActionID,
  }, true);
};

export const trackStartOrFinishOfProcedureBagPacking = async (
  waypointID: string,
  procedureID: string,
  start?: boolean,
) => {
  const event = start
    ? TrackingEvent.START_PROCEDURE_BAG_PACKING
    : TrackingEvent.FINISH_PROCEDURE_BAG_PACKING;

  await trackEvent(event, {
    waypointID,
    procedureID,
  }, true);
};

export const trackStartOrFinishOfProcedureKitPacking = async (
  supplyTransferID: string,
  procedureID: string,
  start?: boolean,
) => {
  const event = start
    ? TrackingEvent.START_PROCEDURE_KIT_PACKING
    : TrackingEvent.FINISH_PROCEDURE_KIT_PACKING;

  await trackEvent(event, {
    supplyTransferID,
    procedureID,
  }, true);
};

export const trackStartOrFinishOfProcedure = (waypointID: string, procedureID: string, startProcedure?: boolean) => {
  const store = getStore();
  trackEvent(
    startProcedure ? TrackingEvent.START_PROCEDURE : TrackingEvent.FINISH_PROCEDURE,
    {
      nurseID: store.state.auth.user?.id,
      waypointID,
      procedureID,
    },
    true,
  );
};

export const trackStartOrFinishOfWaypointAction = (
  waypointActionID: string,
  startWaypointAction?: boolean,
) => {
  const store = getStore();
  const waypointAction = store.state.waypoint.waypointActions[waypointActionID];
  const booking = getBookingByWaypointActionID(waypointActionID);

  if (!waypointAction || !booking) {
    reportException(`Finish Waypoint Action ${waypointActionID} tracking failed`);
    return;
  }

  trackEvent(
    startWaypointAction ? TrackingEvent.START_WAYPOINT_ACTION : TrackingEvent.FINISH_WAYPOINT_ACTION,
    {
      nurseID: store.state.auth.user?.id,
      waypointActionID: waypointActionID,
      itineraryID: waypointAction.itineraryID,
      bookingID: booking.id,
    },
    true,
  );
};
