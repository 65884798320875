import { Calendar, SettingsActions, SettingsState } from '@/store/modules/settings/model';

type S = SettingsState;

const settingsState: S = {
  calendar: {
    // TODO: move
    displayPastItems: false,
  },
  useCurrentLocationAsItineraryStartLocation: false,
  procedureDetailsPagePrevRoute: null,
};

const settingsMutations = {
  'settings/SET_CALENDAR_SETTINGS': (state: S, newCalendar: Partial<Calendar>) => {
    state.calendar = {
      ...state.calendar,
      ...newCalendar,
    };
  },
  'settings/TOGGLE_USE_CURRENT_LOCATION': (state: S) => {
    state.useCurrentLocationAsItineraryStartLocation = !state.useCurrentLocationAsItineraryStartLocation;
  },
  'settings/SET_PROCEDURE_DETAILS_PAGE_PREV_ROUTE': (state: S, prevRoute: string | null) => {
    state.procedureDetailsPagePrevRoute = prevRoute;
  },
};

const settingsActions: SettingsActions = {};

const settingsGetters = {};

export type SettingsGetters = typeof settingsGetters;

export const settingsModule = {
  state: settingsState,
  mutations: settingsMutations,
  actions: settingsActions,
  getters: settingsGetters,
};

export type SettingsModule = typeof settingsModule;
