import {
  ByID,
  SupplyInstructionOptions,
  WaypointActionStatusName,
  WaypointActionType,
  WaypointType,
} from '@caresend/types';
import {
  RouteLike,
  getStore,
  initFlowHelpers,
  toastError,
  toastErrorAndReport,
} from '@caresend/ui-components';
import { flattenArray, isMailInAction, isSampleProcessingAction } from '@caresend/utils';

import { RouteName, routeNames } from '@/router/model';
import { itineraryFlowCompletionActions } from '@/store/modules/itineraryFlow/completionActions/root';
import {
  itineraryFlowDataFetchers,
  itineraryFlowGetDataFetched,
  itineraryFlowGetFlowLoading,
  itineraryFlowSetDataFetched,
  itineraryFlowSetFlowLoading,
  itineraryFlowSetFlowLoadingError,
} from '@/store/modules/itineraryFlow/dataFetchers';
import {
  ItineraryFlowConfig,
  ItineraryFlowParam,
  ItineraryStep,
  ItineraryStepMap,
} from '@/store/modules/itineraryFlow/model';
import { getCentrifugationSteps } from '@/store/modules/itineraryFlow/steps/centrifugation';
import { getPackedMailInWaypointSteps } from '@/store/modules/itineraryFlow/steps/mailIn';
import { getUnpackedMailInActionSteps } from '@/store/modules/itineraryFlow/steps/packing';
import { getPatientWaypointSteps } from '@/store/modules/itineraryFlow/steps/patientWaypoint';
import { buildItineraryStep } from '@/store/modules/itineraryFlow/steps/stepBuildingHelpers';
import { itineraryFlowStepValidators } from '@/store/modules/itineraryFlow/stepValidation/root';

// FLOW CONFIG

/** All data fetched/initialized. */
const getFlowDataIsReady = (itineraryID?: string): boolean => {
  if (!itineraryID) return false;

  const store = getStore();
  return !!store.state.itineraryFlow.localState.flowDataIsReady[itineraryID];
};

const setNextLoading = (isLoading: boolean): void => {
  const store = getStore();
  store.commit('itineraryFlow/SET_NEXT_BUTTON_LOADING', isLoading);
};

/**
 * Current state of Itinerary Flow:
 *
 * - Patient visit (Waypoint type: patient)
 *   - Completion actions are not currently used for patient visit steps.
 *   - Step validators are not currently used for patient visit steps.
 *   - Each component in these steps currently must render their own footer,
 *     and the global footer is hidden.
 * - Centrifugation
 *   - Processing Action type: sample
 *     - supplyInstruction: centrifuge
 *   - Coming soon
 * - Packing for mail-in
 *   - Waypoint Action type: mailIn
 *     - status not packed
 *   - Coming soon
 * - Mail-in
 *    - Waypoint type: pickDrop
 *     - Contains one or more waypoint action of type mailIn
 *   - Waypoint Action type: mailIn
 *     - status of all is packed
 *   - Coming soon
 *
 * - Drop-off (Waypoint type: pickDrop, Waypoint action type: dropOff)
 *   - Outside of itinerary flow
 */
export const flowConfig: ItineraryFlowConfig = {
  completionActions: itineraryFlowCompletionActions,
  dataFetchers: itineraryFlowDataFetchers,
  getFlowDataIsReady,
  getDataFetched: itineraryFlowGetDataFetched,
  setDataFetched: itineraryFlowSetDataFetched,
  getFlowLoading: itineraryFlowGetFlowLoading,
  setFlowLoading: itineraryFlowSetFlowLoading,
  setFlowLoadingError: itineraryFlowSetFlowLoadingError,
  paramsEnum: ItineraryFlowParam,
  rootParam: ItineraryFlowParam.ITINERARY_ID,
  setNextLoading,
  stepValidators: itineraryFlowStepValidators,
};

// ROOT STEPS
export const getItineraryStep = (
  itineraryID: string,
): ItineraryStep => {
  const store = getStore();
  const itinerary = store.state.waypoint.itineraries[itineraryID];
  if (!itinerary) throw toastError(`Missing itinerary ${itineraryID} when building steps`);

  const itineraryWaypoints
    = store.getters['waypoint/getWaypointsOnItinerary'](itineraryID, true);
  const itineraryWaypointActions
    = store.getters['waypoint/getWaypointActionsOnItinerary'](itineraryID, true);
  const itineraryProcessingActions
    = store.getters['waypoint/getSampleProcessingActionsOnItinerary'](itineraryID, true);

  // Waypoints as substeps: Currently patient waypoints, and waypoints
  // containing mail-in waypoints that are all packed are supported. Other
  // types of waypoints (drop-off) will be handled outside the flow until
  // refactor.
  const waypointSubSteps = itineraryWaypoints.reduce<ByID<ItineraryStepMap>>((
    currentSubSteps,
    waypoint,
  ) => {
    const waypointID = waypoint.id;

    switch (waypoint.type) {
      case WaypointType.PATIENT: {
        const patientWaypointStepMap = getPatientWaypointSteps(waypointID, itineraryID);
        return {
          ...currentSubSteps,
          [waypointID]: patientWaypointStepMap,
        };
      }
      case WaypointType.PICK_DROP: {
        const waypointHasMailInActions = store.getters[
          'waypoint/waypointHasMailInWaypointActions'
        ](waypointID);
        if (!waypointHasMailInActions) {
          return currentSubSteps;
        }

        const stepMap = getPackedMailInWaypointSteps(waypointID, itineraryID);

        return {
          ...currentSubSteps,
          [waypointID]: stepMap,
        };
      }
    }
  }, {});

  // Waypoint actions as substeps: Currently unpacked mail-in waypoint actions
  // are supported.
  const waypointActionSubSteps = itineraryWaypointActions.reduce<ByID<ItineraryStepMap>>((
    currentSubSteps,
    waypointAction,
  ) => {
    const waypointActionID = waypointAction.id;

    switch (waypointAction.type) {
      case WaypointActionType.MAIL_IN: {
        if (!isMailInAction(waypointAction)) {
          toastErrorAndReport(`Mail-in action ${waypointActionID} is invalid`);
          return currentSubSteps;
        }

        const { status } = waypointAction.status;
        const isUnpacked = (
          status === WaypointActionStatusName.INCOMPLETE
          || status === WaypointActionStatusName.IN_PROGRESS
        );

        if (!isUnpacked) return currentSubSteps;

        // TODO: This logic may cause issues at the end of the packing steps
        // if we update the `status` locally while inside the packing steps.
        // Test the case where local status update succeeds and db update fails.
        // Does the user get stuck on the last packing step?
        const stepMap = getUnpackedMailInActionSteps(waypointActionID, itineraryID);

        return {
          ...currentSubSteps,
          [waypointActionID]: stepMap,
        };
      }
      // Unused
      case WaypointActionType.SAMPLE:
      case WaypointActionType.PICKUP:
      case WaypointActionType.PATIENT_ACTION:
      case WaypointActionType.DROPOFF: {
        return currentSubSteps;
      }
    }
  }, {});

  // Processing actions as substeps: Currently centrifuge sample processing
  // actions are supported.
  const processingActionSubSteps = itineraryProcessingActions.reduce<ByID<ItineraryStepMap>>((
    currentSubSteps,
    processingAction,
  ) => {
    const processingActionID = processingAction.id;

    switch (processingAction.type) {
      case WaypointActionType.SAMPLE: {
        if (!isSampleProcessingAction(processingAction)) {
          toastErrorAndReport(`Sample processing action ${processingActionID} is invalid`);
          return currentSubSteps;
        }

        if (processingAction.supplyInstruction !== SupplyInstructionOptions.CENTRIFUGE) {
          return currentSubSteps;
        }

        const stepMap = getCentrifugationSteps(processingActionID, itineraryID);

        return {
          ...currentSubSteps,
          [processingActionID]: stepMap,
        };
      }
      case WaypointActionType.MAIL_IN: {
        const stepMap = getUnpackedMailInActionSteps(processingActionID, itineraryID);

        return {
          ...currentSubSteps,
          [processingActionID]: stepMap,
        };
      }
    }

    return currentSubSteps;
  }, {});

  const subSteps = {
    ...waypointSubSteps,
    ...waypointActionSubSteps,
    ...processingActionSubSteps,
  };

  return buildItineraryStep(
    routeNames.ITINERARY_FLOW_ITINERARY,
    itineraryID,
    subSteps,
  );
};

// ROOT STEP MAP

export const getItineraryStepMap = (
  itineraryID: string | undefined,
): ItineraryStepMap => {
  // The step building logic may throw errors in case of missing data. Return
  // empty steps when data is not ready.
  if (!flowConfig.getFlowDataIsReady?.(itineraryID)) { return new Map(); }
  if (!itineraryID) throw toastError('Missing itinerary ID when generating steps.');

  const itineraryStep = getItineraryStep(itineraryID);

  const stepMap = new Map<RouteName, ItineraryStep>([
    [itineraryStep.routeName, itineraryStep],
  ]);

  return stepMap;
};

// HELPERS

export const getItineraryFlowHelpers = (route: RouteLike) => {
  const stepMap = getItineraryStepMap(route.params?.itineraryID ?? undefined);
  return initFlowHelpers(flowConfig, stepMap);
};

/**
 * Given a waypoint ID, return a flattened list of all the possible steps.
 * This can be used to easily find a specific step in the hierarchy, without
 * needing to work with nested data.
 */
export const getFlattenedItinerarySteps = (itineraryID: string) => {
  const stepsArray = [
    ...getItineraryStepMap(itineraryID).values(),
  ];

  const flatSteps: ItineraryStep[] = [];

  const recursiveFlattenSteps = (steps: ItineraryStep[]) => {
    steps.forEach((step) => {
      if (step.subSteps) {
        const subStepsMapArray = Object.values(step.subSteps);
        const subStepsArray = flattenArray(subStepsMapArray.map((subStepMap) =>
          [...subStepMap.values()],
        ));
        flatSteps.concat(subStepsArray);
        recursiveFlattenSteps(subStepsArray);
      }
      flatSteps.push(step);
    });
  };

  recursiveFlattenSteps(stepsArray);
  return flatSteps;
};
