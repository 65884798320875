import { LocationObject } from '@caresend/types';
import { getStore, toastError } from '@caresend/ui-components';

// This function is used in `trackEvent` so has been moved to its own
// file to prevent dependency cycles when tracking events from `location.ts`

export const getCurrentLocation = (): Promise<LocationObject> =>
  new Promise<LocationObject>((resolve, reject) => {
    const store = getStore();

    const currentLocation = store.state.auth.user?.location?.current;
    if (currentLocation) {
      resolve(currentLocation);
      return;
    }
    navigator.geolocation.getCurrentPosition(
      (location: any) => resolve({ lat: location.coords.latitude, lng: location.coords.longitude }),
      (error) => {
        if (error.code === 1) {
          toastError('Error getting your location. Please check your settings', { duration: 2400 });
        }
        reject(error);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
    );
  });
