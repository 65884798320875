
import Vue from 'vue';

export default Vue.extend({
  name: 'DateView',
  props: {
    date: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    month() {
      return new Date(this.date)
        .toLocaleString('default', { month: 'short' });
    },
    day() {
      return new Date(this.date)
        .getDay();
    },
    time() {
      const dateTime = new Date(this.date);
      return `${dateTime.getHours() % 12}:${dateTime.getMinutes()}`;
    },
    pm() {
      return Math.floor(new Date(this.date)
        .getHours() / 12) === 1;
    },
  },
});
