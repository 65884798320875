/**
 * @deprecated migrated to UI-c
 * This queuing system is designed to significantly reduce the number
 * of unique cloud requests, while interfacing uniquely between each
 * request. Upon each execution, it will wait for any requests to come
 * in, parse and resolve data, and manage the queue in an isolated environment.
 */

import { firebaseCallable, tryAllBrowser } from '@caresend/ui-components';

import { BatchQueue, RequestQueue } from '@/database/firebase/API/model';

const WAIT_TIME = 0;
const CHUNK_SIZE = 50;

const queues: Record<string, RequestQueue<any, any>> = {};

const executeRequestQueue = async <O>(functionName: string, backupLength: number) => {
  // Verify all tasks have been added to this batch session
  const requestQueue = queues[functionName];
  if (!requestQueue) throw new Error('Missing request queue.');
  const { queue, request } = requestQueue;
  const currentBatchLength = queue.length;
  if (backupLength !== currentBatchLength) return;

  const splitQueues: BatchQueue<any, any>[] = [];
  const splitLength = Math.ceil(currentBatchLength / CHUNK_SIZE);

  Array(splitLength).fill(0).forEach((_, i) => {
    const prevSplitIndex = CHUNK_SIZE * i;
    let splitIndex: number | undefined = CHUNK_SIZE * (i + 1) - 1;

    const isLastChunk = i === (splitLength - 1);
    if (isLastChunk) splitIndex = undefined;

    splitQueues.push(queue.slice(prevSplitIndex, splitIndex));
  });

  const suffix = splitLength === 1 ? '' : 's';
  console.info(`executing ${currentBatchLength} ${functionName} tasks in ${splitLength} chunk${suffix}...`);

  // Begin execution
  requestQueue.queue = [];
  const promises = splitQueues.map((subQueue) => async () => {
    const executionQueue = [...subQueue];
    const data = executionQueue.map((val) => val.data);
    try {
      const allResponseData = await request({ data });

      // Resolve promises of each unique request
      allResponseData.forEach((result: O, index: number) => {
        const queueTask = executionQueue[index];
        if (!queueTask) throw new Error('Missing queue task.');
        queueTask.resolve(result);
      });
    } catch (error) {
      // Reject promises of each unique request
      executionQueue.forEach((queueTask) => {
        queueTask.reject(error);
      });
    }
  });

  // TODO: tryAll may be redundant here due to individual try/catch
  await tryAllBrowser(promises);
};

const addToBatch = <I, O>(functionName: string, data?: I): Promise<O> =>
  new Promise<O>((resolve, reject) => {
    const requestQueue = queues[functionName];
    if (!requestQueue) throw new Error('Missing request queue.');
    requestQueue.queue.push({ data, resolve, reject });
    const previousLength = requestQueue.queue.length.valueOf();
    setTimeout(() => executeRequestQueue<O>(functionName, previousLength), WAIT_TIME);
  });

export const batchedCallable = <I, O>(functionName: string) => {
  const requestFunction = firebaseCallable<I, O>(functionName);
  queues[functionName] = {
    request: requestFunction,
    queue: [] as BatchQueue<I, O>,
  };
  return (data: I | undefined) => addToBatch<I, O>(functionName, data);
};
