import { ExtendedCustomModule, OfficesModule, initOfficesModule } from '@caresend/ui-components';

import { ExtendedUsersMutations } from '@/store/modules/users';

const officesModuleExtension = {
};

export const officesModule: ExtendedCustomModule<
  OfficesModule<ExtendedUsersMutations>,
  typeof officesModuleExtension
> = initOfficesModule(officesModuleExtension);

export type ExtendedOfficesModule = typeof officesModule;

export type ExtendedOfficesMutations = ExtendedOfficesModule['mutations'];
export type ExtendedOfficesActions = ExtendedOfficesModule['actions'];
export type ExtendedOfficesGetters = ExtendedOfficesModule['getters'];
