
import { ButtonComponent, getStore } from '@caresend/ui-components';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'ShiftFilters',
  components: {
    ButtonComponent,
  },
  setup() {
    const store = getStore();

    const displayPastItems = computed(() => store.state.settings.calendar.displayPastItems);

    const styleUpcomingButton = computed(() => {
      if (displayPastItems.value) return 'outline-white';
      return 'primary';
    });
    const stylePastButton = computed(() => {
      if (!displayPastItems.value) return 'outline-white';
      return 'primary';
    });

    const setPast = (newValue: boolean) => {
      store.commit('settings/SET_CALENDAR_SETTINGS', { displayPastItems: newValue });
    };
    return {
      setPast,
      displayPastItems,
      styleUpcomingButton,
      stylePastButton,
    };
  },
});
