
import { Tabs, getRoute, getRouter } from '@caresend/ui-components';
import { isInEnum } from '@caresend/utils';
import { defineComponent, ref } from 'vue';

import { NurseHomeTabID } from '@/views/nurse/model';
import ShiftOffers from '@/views/nurse/shifts/ShiftOffers.vue';
import Shifts from '@/views/nurse/shifts/Shifts.vue';

const nurseHomeTabs = [
  {
    component: Shifts,
    title: 'Your schedule',
    id: NurseHomeTabID.SCHEDULE,
  },
  {
    component: ShiftOffers,
    title: 'Marketplace',
    id: NurseHomeTabID.MARKETPLACE,
  },
] as const;

export default defineComponent({
  name: 'NurseHome',
  components: {
    Tabs,
  },
  setup() {
    const router = getRouter();
    const route = getRoute();

    const initialViewQuery = route.value.query.view;
    let initialTabID: NurseHomeTabID = nurseHomeTabs[0].id;
    if (
      typeof initialViewQuery === 'string'
      && isInEnum<NurseHomeTabID>(initialViewQuery, NurseHomeTabID)
    ) initialTabID = initialViewQuery;

    const activeTabID = ref<NurseHomeTabID>(initialTabID);

    const updateActiveTabID = (tabID: NurseHomeTabID) => {
      activeTabID.value = tabID;
      router.push({ query: { view: tabID } });
    };

    return {
      activeTabID,
      nurseHomeTabs,
      updateActiveTabID,
    };
  },
});
