import { Barcode, BarcodeType, TrackingEvent } from '@caresend/types';
import { getStore } from '@caresend/ui-components';

import { trackEvent } from '@/functions/tracking/tracking';

export const trackStartOrFinishOfAddSupplies = async (
  shopifyFulfillmentID: number,
  start?: boolean,
) => {
  const event = start
    ? TrackingEvent.START_ADDING_SUPPLIES
    : TrackingEvent.FINISH_ADDING_SUPPLIES;

  await trackEvent(event, {
    shopifyFulfillmentID,
  }, true);
};

/** Tracked when the nurse hits "Drive" to any waypoint (patient visit, pickDrop) */
export const trackDriveToWaypoint = async (waypointID: string) => {
  await trackEvent(TrackingEvent.DRIVE_TO_WAYPOINT, {
    waypointID,
  }, true);
};

export const trackFinishGeoCheck = async (shiftID: string) => {
  await trackEvent(TrackingEvent.FINISH_GEO_CHECK, {
    shiftID,
  }, true);
};

/**
 * Tracked when the nurse completes the last step in any waypoint (patient visit,
 * pickDrop)
 */
export const trackFinishWaypoint = async (
  waypointID: string,
  additionalData?: Record<string, unknown>,
) => {
  await trackEvent(TrackingEvent.FINISH_WAYPOINT, {
    ...additionalData,
    waypointID,
  }, true);
};

/**
 * Reports data on every scan. If a batchItem or supplyItem is scanned, its ID
 * is included.
 */
export const trackScanItem = async (
  barcode: Partial<Barcode>,
  errorMessage?: string,
) => {
  let supplyItemID: string | undefined;
  let batchItemID: string | undefined;
  let unknownBarcode: string | undefined;
  let barcodeValue: string | undefined;
  const barcodeType = barcode?.type;

  switch (barcode?.type) {
    case BarcodeType.BATCH_ITEM:
      batchItemID = barcode.id;
      break;
    case BarcodeType.SUPPLY_ITEM:
      supplyItemID = barcode.id;
      break;
    case BarcodeType.SHIPPING_LABEL:
      barcodeValue = barcode.id;
      break;
    default:
      unknownBarcode = barcode.id;
  }

  await trackEvent(TrackingEvent.SCAN_ITEM, {
    barcodeType,
    barcodeValue,
    batchItemID,
    errorMessage,
    supplyItemID,
    unknownBarcode,
  }, true);
};

export const trackStartOrFinishOfShiftCheckin = async (
  shiftID: string,
  start?: boolean,
) => {
  const event = start
    ? TrackingEvent.START_SHIFT_CHECKIN
    : TrackingEvent.COMPLETE_SHIFT_CHECKIN;

  const shift = getStore().state.shifts.shifts[shiftID];

  const {
    leaveFrom,
    lateCheckInReason,
    requiresOfflineSupplies,
  } = shift ?? {};
  const additionalDataForStart = {
    leaveFrom,
    lateCheckInReason,
    ...(shift?.tools?.centrifuge ? { requiresCentrifuge: true } : {}),
  };

  await trackEvent(event, {
    requiresOfflineSupplies,
    ...(start && additionalDataForStart),
    shiftID,
  }, true);
};
