import {
  Barcode,
  ByID,
  GetAlgoliaSuppliesAPIKeyResponse,
  GetSupplyItemsDataResponse,
  ShipmentScanning,
  ShopifyFulfillment,
  ShopifyFulfillmentStatus,
  SupplyItem,
  SupplyShipment,
  SupplyTransfer,
} from '@caresend/types';
import {
  AnyGetters,
  ExtendedCustomModule,
  SuppliesModule,
  SuppliesState,
  initSuppliesModule,
  needsNewKey,
  searchSupplyShipments,
  searchSupplyTransfers,
  toastErrorAndReport,
} from '@caresend/ui-components';
import {
  arrayToObj,
  errorSuffix,
  getUnknownErrorMessage,
  nullishFilter,
  objectMap,
} from '@caresend/utils';
import update from 'immutability-helper';
import { Route } from 'vue-router';

import { getAlgoliaSuppliesAPIKeyRequest, getSupplyItemsDataRequest } from '@/database/firebase/API';
import type { ScannableItemWithKey } from '@/functions/supplies/scanning';
import type { CustomActionContext, RootState } from '@/store/model';
import { ExtendedProceduresMutations } from '@/store/modules/procedures';
import type { SupplyShipmentStep, SupplyTransferStep } from '@/store/modules/supplies/model';
import { getSupplyShipmentFlowHelpers } from '@/store/modules/supplies/supplyShipmentSteps/steps';
import { getIsCurrentSupplyShipmentStepValid } from '@/store/modules/supplies/supplyShipmentSteps/stepValidation';
import { getSupplyTransferFlowHelpers } from '@/store/modules/supplies/supplyTransferSteps/steps';
import { getIsCurrentSupplyTransferStepValid } from '@/store/modules/supplies/supplyTransferSteps/stepValidation';

interface PackingFlowProcedureState {
  /**
   * Whether the packer has scanned the instruction sheet in the supplyTransfer procedure flow.
   * Used on SupplyTransferScanInstructionSheet for step validation.
   */
  supplyTransferProcedureInstructionSheetScanned?: boolean;

  /**
   * Whether the packer has scanned the requisition form in the supplyTransfer procedure flow.
   * Used on SupplyTransferScanRequisitionForm for step validation.
   */
  supplyTransferProcedureRequisitionFormScanned?: boolean;
}
interface ExtraSuppliesState {
  keyData: GetAlgoliaSuppliesAPIKeyResponse | null;
  /**
    * List of supply transfer IDs that have been packed.
    * Used to determine whether to show the supply transfer or not in the packing list
    */
  completedPacks: string[];
  /**
    * List of supplyShipmentIDs that have been completed.
    * Used to determine whether to show the supply shipment or not in the packer flow.
    */
  completedShipments: string[];
  shippingFlow: {
    supplyShipmentFlowLoading: boolean;
    /**
     * Whether the next button should show loading - triggered in steps with
     * completion actions.
     */
    supplyShipmentFlowNextButtonLoading: boolean;
    /**
     * Local state of SupplyShipmentSupplyBox single scanner.
     * Allows for next button validation and saving of data when the user clicks
     * next.
     */
    scanSupplyBoxScanState: Barcode | null;
  };
  packingFlow: {
    /**
     * Local state of SupplyTransferScanKitsToSupplyBox multi-scanner.
     * Allows for next button validation and saving of data when the user clicks
     * next.
     */
    scanKitsToSupplyBoxScanState: ScannableItemWithKey[];
    /**
     * Local state of SupplyTransferScanProcedureSupplies multi-scanner.
     * Allows for next button validation and saving of data when the user clicks
     * next.
     */
    scanProcedureSuppliesScanState: ScannableItemWithKey[];
    /**
     * By supply transfer ID. True if all data has been fetched for the supply
     * transfer flow, or if there was an error during loading.
     *
     * Getters for supplyTransferSteps throw errors in case of missing data
     * unless this value is true for a given supply transfer ID. This is a
     * blocking loader for the entire supply transfer flow.
     */
    supplyTransferFlowLoadingCompleted: ByID<boolean>;
    /**
     * Whether the next button should show loading - triggered in steps with
     * completion actions.
     */
    supplyTransferFlowNextButtonLoading: boolean;
    /**
     * All the supplyTransfer flow state related to a procedure.
     * Stored by procedureID
     */
    procedures: ByID<PackingFlowProcedureState>;
  };
}

/**
 * Type for a mutation to update boolean state related to a procedure in the
 * supplyTransfer (packing) flow
 * eg: Whether procedure instruction sheet has been scanned
 */
interface SetSupplyTransferPackingFlowProcedureState {
  procedureID: string;
  /** The new boolean value to set */
  value: boolean;
}

const extraSuppliesState: ExtraSuppliesState = {
  keyData: null,
  completedShipments: [],
  completedPacks: [],
  shippingFlow: {
    supplyShipmentFlowLoading: true,
    supplyShipmentFlowNextButtonLoading: false,
    scanSupplyBoxScanState: null,
  },
  packingFlow: {
    scanKitsToSupplyBoxScanState: [],
    scanProcedureSuppliesScanState: [],
    supplyTransferFlowLoadingCompleted: {},
    supplyTransferFlowNextButtonLoading: false,
    procedures: {},
  },
};

type S = SuppliesState & ExtraSuppliesState;

const extraSuppliesMutations = {
  'supplies/SET_ALGOLIA_KEY': (state: S, keyData: GetAlgoliaSuppliesAPIKeyResponse) => {
    state.keyData = keyData;
  },

  'supplies/ADD_COMPLETED_PACKS': (
    state: S,
    supplyTransferID: string,
  ) => {
    state.completedPacks.push(supplyTransferID);
  },

  'supplies/ADD_COMPLETED_SUPPLY_SHIPMENT': (
    state: S,
    supplyShipmentID: string,
  ) => {
    state.completedShipments.push(supplyShipmentID);
  },

  'supplies/SET_SCAN_SUPPLY_BOX_SCAN_STATE': (
    state: S,
    scanState: Barcode | null,
  ) => {
    state.shippingFlow.scanSupplyBoxScanState = scanState;
  },

  'supplies/SET_SCAN_KITS_TO_SUPPLY_BOX_SCAN_STATE': (
    state: S,
    scanState: ScannableItemWithKey[],
  ) => {
    state.packingFlow.scanKitsToSupplyBoxScanState = scanState;
  },

  'supplies/SET_SCAN_PROCEDURE_SUPPLIES_SCAN_STATE': (
    state: S,
    scanState: ScannableItemWithKey[],
  ) => {
    state.packingFlow.scanProcedureSuppliesScanState = scanState;
  },

  'supplies/SET_SUPPLY_TRANSFER_FLOW_LOADING_COMPLETED': (
    state: S,
    { supplyTransferID, completed }: { supplyTransferID: string; completed: boolean },
  ) => {
    state.packingFlow.supplyTransferFlowLoadingCompleted = update(
      state.packingFlow.supplyTransferFlowLoadingCompleted,
      { [supplyTransferID]: { $set: completed } },
    );
  },

  'supplies/SET_SUPPLY_SHIPMENT_FLOW_LOADING': (state: S, loading: boolean) => {
    state.shippingFlow.supplyShipmentFlowLoading = loading;
  },

  'supplies/SET_SUPPLY_SHIPMENT_FLOW_NEXT_BUTTON_LOADING': (state: S, loading: boolean) => {
    state.shippingFlow.supplyShipmentFlowNextButtonLoading = loading;
  },

  'supplies/SET_SUPPLY_TRANSFER_FLOW_NEXT_BUTTON_LOADING': (state: S, loading: boolean) => {
    state.packingFlow.supplyTransferFlowNextButtonLoading = loading;
  },

  'supplies/SET_SUPPLY_TRANSFER_PROCEDURE_INSTRUCTION_SHEET_SCANNED': (
    state: S, { value, procedureID }: SetSupplyTransferPackingFlowProcedureState,
  ) => {
    state.packingFlow.procedures = {
      ...state.packingFlow.procedures,
      [procedureID]: {
        ...state.packingFlow.procedures[procedureID],
        supplyTransferProcedureInstructionSheetScanned: value,
      },
    };
  },

  'supplies/SET_SUPPLY_TRANSFER_PROCEDURE_REQUISITION_FORM_SCANNED': (
    state: S, { value, procedureID }: SetSupplyTransferPackingFlowProcedureState,
  ) => {
    state.packingFlow.procedures = {
      ...state.packingFlow.procedures,
      [procedureID]: {
        ...state.packingFlow.procedures[procedureID],
        supplyTransferProcedureRequisitionFormScanned: value,
      },
    };
  },

  'supplies/UPDATE_SHIPMENT_BOX_NURSE_SCANS': (
    state: S,
    valuesByShipmentID: ByID<Partial<ShipmentScanning['shipmentBoxNurseScans']>>,
  ) => {
    const updatedShipments = objectMap(valuesByShipmentID, (values, id) => ({
      ...state.shipments[id],
      scanning: {
        ...state.shipments[id]?.scanning,
        shipmentBoxNurseScans: {
          ...state.shipments[id]?.scanning?.shipmentBoxNurseScans,
          ...values,
        },
      },
    }));

    state.shipments = update(state.shipments, { $merge: updatedShipments });
  },

  'supplies/SET_SCANNED_SHIPPING_LABEL_BARCODE': (
    state: S,
    { shipmentID, barcode }: { shipmentID: string; barcode: string },
  ) => {
    state.shipments = update(state.shipments, {
      [shipmentID]: { scannedShippingLabelBarcode: { $set: barcode } },
    });
  },

  'supplies/SET_SHIPPING_LABEL_BARCODE': (
    state: S,
    { shipmentID, barcode }: { shipmentID: string; barcode: string },
  ) => {
    state.shipments = update(state.shipments, {
      [shipmentID]: { shippingLabelBarcode: { $set: barcode } },
    });
  },
};

type ExtraSuppliesActionContext = CustomActionContext<'supplies', S>

export type ExtraSuppliesActions = {
  'supplies/fetchNurseSupplyItemsData': (
    context: ExtraSuppliesActionContext,
  ) => void;

  'supplies/resetSupplyShipmentFlowState': (
    context: ExtraSuppliesActionContext,
  ) => void;

  'supplies/searchPackerSupplyShipments': (
    context: ExtraSuppliesActionContext,
  ) => Promise<SupplyShipment[]>;

  'supplies/searchPackerSupplyTransfers': (
    context: ExtraSuppliesActionContext,
  ) => Promise<SupplyTransfer[]>;

  'supplies/updateSupplyTransfersAlgoliaKey': (
    context: ExtraSuppliesActionContext,
  ) => void;
}

const extraSuppliesActions: ExtraSuppliesActions = {
  'supplies/fetchNurseSupplyItemsData': async ({ commit }) => {
    try {
      const supplyItemsData: GetSupplyItemsDataResponse = await getSupplyItemsDataRequest({
        includeSkuDataInSupplyItem: true,
      });
      if (!supplyItemsData) throw Error('Supply Items Data not found');
      const skus = supplyItemsData.map(
        (item) => item.skuData,
      ).filter(nullishFilter);
      const skusByID = arrayToObj(skus, 'id');
      commit('supplies/SET_SKUS', skusByID);
      const supplyItemsWithoutSkuData: SupplyItem[] = supplyItemsData.map((item) => {
        const { skuData, ...itemWithoutSkuData } = item;
        return itemWithoutSkuData;
      });
      const supplyItemsWithoutSkuDataByID: ByID<SupplyItem> = arrayToObj(
        supplyItemsWithoutSkuData,
        'id',
      );
      commit('supplies/SET_SUPPLY_ITEMS', supplyItemsWithoutSkuDataByID);
    } catch (error) {
      const message = getUnknownErrorMessage(error);
      toastErrorAndReport(`There was a problem loading your supplies: ${message} ${errorSuffix}`);
    }
  },

  'supplies/resetSupplyShipmentFlowState': ({ commit }) => {
    commit('supplies/SET_SCAN_SUPPLY_BOX_SCAN_STATE', null);
  },

  'supplies/searchPackerSupplyShipments': async ({ state, dispatch }) => {
    if (needsNewKey(state.keyData)) {
      await dispatch('supplies/updateSupplyTransfersAlgoliaKey');
    }
    const results = await searchSupplyShipments(state.keyData?.supplyShipmentsKey ?? '');
    return results.filter((shipment) => !state.completedShipments.includes(shipment.id));
  },

  'supplies/searchPackerSupplyTransfers': async ({ state, dispatch }) => {
    if (needsNewKey(state.keyData)) {
      await dispatch('supplies/updateSupplyTransfersAlgoliaKey');
    }
    const results = await searchSupplyTransfers(state.keyData?.supplyTransfersKey ?? '');
    return results.filter((transfer) => !state.completedPacks.includes(transfer.id));
  },

  'supplies/updateSupplyTransfersAlgoliaKey': async ({ rootState, commit }) => {
    const keyData = await getAlgoliaSuppliesAPIKeyRequest({
      placeID: rootState.places.userAssociatedPlaceID || null,
    });
    commit('supplies/SET_ALGOLIA_KEY', keyData);
  },
};

const extraSuppliesGetters = {
  'supplies/getBackSupplyShipmentStepInOrder': (
    _state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (route: Route): SupplyShipmentStep | null =>
    getSupplyShipmentFlowHelpers(route, rootState).getBackOrNextStep(route, 'back'),

  'supplies/getBackSupplyTransferStepInOrder': (
    _state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (route: Route): SupplyTransferStep | null =>
    getSupplyTransferFlowHelpers(route, rootState).getBackOrNextStep(route, 'back'),

  'supplies/getCurrentSupplyShipmentStep': (
    _state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (route: Route): SupplyShipmentStep | null =>
    getSupplyShipmentFlowHelpers(route, rootState).getStepFromRoute(route),

  'supplies/getCurrentSupplyTransferStep': (
    _state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (route: Route): SupplyTransferStep | null =>
    getSupplyTransferFlowHelpers(route, rootState).getStepFromRoute(route),

  'supplies/getIsCurrentSupplyShipmentStepValid': (
    _state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (route: Route): boolean =>
    getIsCurrentSupplyShipmentStepValid(route, rootState),

  'supplies/getIsCurrentSupplyTransferStepValid': (
    _state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (route: Route): boolean =>
    getIsCurrentSupplyTransferStepValid(route, rootState),

  'supplies/getNextSupplyShipmentStepInOrder': (
    _state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (route: Route): SupplyShipmentStep | null =>
    getSupplyShipmentFlowHelpers(route, rootState).getBackOrNextStep(route, 'next'),

  'supplies/getNextSupplyTransferStepInOrder': (
    _state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (route: Route): SupplyTransferStep | null =>
    getSupplyTransferFlowHelpers(route, rootState).getBackOrNextStep(route, 'next'),

  'supplies/getUserShopifyFulfillments': (
    _state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ): ShopifyFulfillment[] => {
    const shopifyFulfillments = rootState.auth.user?.supplies?.shopifyFulfillments;
    return Object.values(shopifyFulfillments ?? {})
      .filter((fulfillment) => fulfillment.status !== ShopifyFulfillmentStatus.ACTIVATED);
  },
};

const suppliesModuleExtension = {
  actions: extraSuppliesActions,
  getters: extraSuppliesGetters,
  mutations: extraSuppliesMutations,
  state: extraSuppliesState,
};

export const suppliesModule: ExtendedCustomModule<
  SuppliesModule<RootState, ExtendedProceduresMutations>,
  typeof suppliesModuleExtension
> = initSuppliesModule(suppliesModuleExtension);

export type ExtendedSuppliesModule = typeof suppliesModule;

export type ExtendedSuppliesGetters = ExtendedSuppliesModule['getters'];
