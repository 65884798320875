import { isProd } from '@caresend/ui-components';

export const isLocal = process.env.NODE_ENV === 'development';

const rootPath = window.location.host;

export const webProURL = isLocal ? 'https://localhost:8080/' : `https://${rootPath}/`;

const patientEnvURL = isProd ? 'https://patient.caresend.com/' : ' https://caresend-patient-dev.web.app/';
export const patientURL = isLocal ? 'https://localhost:8081/' : patientEnvURL;
