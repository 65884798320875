import { HeadingValues } from '@caresend/ui-components';
import { ComputedRef, computed } from 'vue';
import { RouteConfig } from 'vue-router';

import { getProcedureKitName } from '@/functions/supplies/get';
import { HeadingParams, routeNames } from '@/router/model';

/**
 * Returns back step in order. If one is not found (first step), returns
 * Packing & shipping page.
 */
const getSupplyTransferBackLocation = (
  { store, route }: HeadingParams,
): HeadingValues['backLocation'] => {
  const backStep = store.getters[
    'supplies/getBackSupplyTransferStepInOrder'
  ](route) ?? undefined;
  const backLocation = backStep
    ? { name: backStep.routeName }
    : { name: routeNames.PICK_AND_PACK };
  return backLocation;
};

const headingWithSupplyTransferBackNav = (title: string) => (
  headingParams: HeadingParams,
): ComputedRef<HeadingValues> => computed(() => ({
  backLocation: getSupplyTransferBackLocation(headingParams),
  title,
}));

// Paths below are not full paths - they are based on the parent path:
// /:partnerName?/pick-and-pack/:supplyTransferID
export const routesSupplyTransfer: RouteConfig[] = [
  {
    path: '',
    name: routeNames.SUPPLY_TRANSFER_PROCEDURES,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-packing-shipping' */
      '@/views/packer/supplyTransferFlow/steps/root/SupplyTransferProcedures/SupplyTransferProcedures.vue'
    ),
    meta: {
      authRequired: true,
      heading: headingWithSupplyTransferBackNav('Prepare the transfer'),
      whiteBackground: true,
    },
  },
  {
    path: 'procedure/:procedureID/scan-procedure-kit',
    name: routeNames.SUPPLY_TRANSFER_SCAN_PROCEDURE_KIT,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-packing-shipping' */
      '@/views/packer/supplyTransferFlow/steps/procedure/SupplyTransferScanProcedureKit.vue'
    ),
    meta: {
      authRequired: true,
      // TODO: Correct text in dynamic heading. User name is just for example.
      heading: (params: HeadingParams) => computed<HeadingValues>(() => {
        const { procedureID } = params.route.params;
        const supplyKitName = getProcedureKitName(procedureID);
        return headingWithSupplyTransferBackNav(`Scan a ${supplyKitName.toLowerCase()}`)(params).value;
      }),
      whiteBackground: true,
    },
  },
  {
    path: 'procedure/:procedureID/scan-procedure-supplies',
    name: routeNames.SUPPLY_TRANSFER_SCAN_PROCEDURE_SUPPLIES,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-packing-shipping' */
      '@/views/packer/supplyTransferFlow/steps/procedure/SupplyTransferScanProcedureSupplies.vue'
    ),
    meta: {
      authRequired: true,
      // TODO: Correct text in dynamic heading. User name is just for example.
      heading: (params: HeadingParams) => computed<HeadingValues>(() =>
        headingWithSupplyTransferBackNav('Procedure box')(params).value,
      ),
      whiteBackground: true,
    },
  },
  {
    path: 'procedure/:procedureID/print-instruction-sheet',
    name: routeNames.SUPPLY_TRANSFER_PRINT_INSTRUCTION_SHEET,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-packing-shipping' */
      '@/views/packer/supplyTransferFlow/steps/procedure/SupplyTransferPrintInstructionSheet.vue'
    ),
    meta: {
      authRequired: true,
      heading: headingWithSupplyTransferBackNav('Print instruction sheet'),
      whiteBackground: true,
    },
  },
  {
    path: 'procedure/:procedureID/print-requisition-form',
    name: routeNames.SUPPLY_TRANSFER_PRINT_REQUISITION_FORM,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-packing-shipping' */
      '@/views/packer/supplyTransferFlow/steps/procedure/SupplyTransferPrintRequisitionForm.vue'
    ),
    meta: {
      authRequired: true,
      heading: headingWithSupplyTransferBackNav('Print requisition form'),
      whiteBackground: true,
    },
  },
  {
    path: 'procedure/:procedureID/scan-instruction-sheet',
    name: routeNames.SUPPLY_TRANSFER_SCAN_INSTRUCTION_SHEET,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-packing-shipping' */
      '@/views/packer/supplyTransferFlow/steps/procedure/SupplyTransferScanInstructionSheet.vue'
    ),
    meta: {
      authRequired: true,
      heading: headingWithSupplyTransferBackNav('Scan the barcode on the sheet'),
      whiteBackground: true,
    },
  },
  {
    path: 'procedure/:procedureID/scan-requisition-form',
    name: routeNames.SUPPLY_TRANSFER_SCAN_REQUISITION_FORM,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-packing-shipping' */
      '@/views/packer/supplyTransferFlow/steps/procedure/SupplyTransferScanRequisitionForm.vue'
    ),
    meta: {
      authRequired: true,
      heading: headingWithSupplyTransferBackNav('Scan the barcode on the requisition form'),
      whiteBackground: true,
    },
  },
  {
    path: 'procedure/:procedureID/close-procedure-kit',
    name: routeNames.SUPPLY_TRANSFER_CLOSE_PROCEDURE_KIT,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-packing-shipping' */
      '@/views/packer/supplyTransferFlow/steps/procedure/SupplyTransferCloseProcedureKit.vue'
    ),
    meta: {
      authRequired: true,
      // TODO: Correct text in dynamic heading. User name is just for example.
      heading: (params: HeadingParams) => computed<HeadingValues>(() => {
        const { procedureID } = params.route.params;
        const supplyKitName = getProcedureKitName(procedureID);
        return headingWithSupplyTransferBackNav(`Close ${supplyKitName.toLowerCase()}`)(params).value;
      }),
      whiteBackground: true,
    },
  },
  {
    path: 'shipment/:supplyShipmentID/scan-supply-box',
    name: routeNames.SUPPLY_TRANSFER_SCAN_SUPPLY_BOX,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-packing-shipping' */
      '@/views/packer/supplyTransferFlow/steps/supplyShipment/SupplyTransferScanSupplyBox.vue'
    ),
    meta: {
      authRequired: true,
      heading: headingWithSupplyTransferBackNav('Scan supply box'),
      whiteBackground: true,
    },
  },
  {
    path: 'shipment/:supplyShipmentID/scan-kits-to-supply-box',
    name: routeNames.SUPPLY_TRANSFER_SCAN_KITS_TO_SUPPLY_BOX,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-packing-shipping' */
      '@/views/packer/supplyTransferFlow/steps/supplyShipment/SupplyTransferScanKitsToSupplyBox.vue'
    ),
    meta: {
      authRequired: true,
      heading: headingWithSupplyTransferBackNav('Pack supply box'),
      whiteBackground: true,
    },
  },
  {
    path: 'shipment/:supplyShipmentID/print-shipping-label',
    name: routeNames.SUPPLY_TRANSFER_PRINT_SHIPPING_LABEL,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-packing-shipping' */
      '@/views/packer/supplyTransferFlow/steps/supplyShipment/SupplyTransferPrintShippingLabel.vue'
    ),
    meta: {
      authRequired: true,
      heading: headingWithSupplyTransferBackNav('Print label'),
      whiteBackground: true,
    },
  },
  {
    path: 'shipment/:supplyShipmentID/scan-shipping-label',
    name: routeNames.SUPPLY_TRANSFER_SCAN_SHIPPING_LABEL,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-packing-shipping' */
      '@/views/packer/supplyTransferFlow/steps/supplyShipment/SupplyTransferScanShippingLabel.vue'
    ),
    meta: {
      authRequired: true,
      heading: headingWithSupplyTransferBackNav('Scan label'),
      whiteBackground: true,
    },
  },
  {
    path: 'shipment/:supplyShipmentID/supply-box-photo',
    name: routeNames.SUPPLY_TRANSFER_SUPPLY_BOX_PHOTO,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-packing-shipping' */
      '@/views/packer/supplyTransferFlow/steps/supplyShipment/SupplyTransferSupplyBoxPhoto.vue'
    ),
    meta: {
      authRequired: true,
      heading: headingWithSupplyTransferBackNav('Take a photo'),
      whiteBackground: true,
    },
  },
  {
    path: 'shipment/:supplyShipmentID/done-packing',
    name: routeNames.SUPPLY_TRANSFER_DONE_PACKING,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-packing-shipping' */
      '@/views/packer/supplyTransferFlow/steps/supplyShipment/SupplyTransferDonePacking.vue'
    ),
    meta: {
      authRequired: true,
      heading: headingWithSupplyTransferBackNav('Done packing'),
      whiteBackground: true,
    },
  },
];
