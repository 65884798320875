import {
  ByID,
  ByPath,
  EncryptedFile,
  FeatureFlag,
  SampleStatus,
  Shipment,
  ShipmentBoxNurseScans,
  Sku,
  SupplyItemWithSupplyID,
  SupplyShipmentStatusName,
  WaypointActionStatusName,
  WaypointStatusName,
} from '@caresend/types';
import {
  FlowCompletionAction,
  FlowCompletionActions,
  dbGroupSet,
  dbSet,
  dbUpdate,
  flagOn,
  getStore,
  getValueOnce,
  toastErrorAndReport,
} from '@caresend/ui-components';
import { getShipmentPath, initStatus } from '@caresend/utils';

import { getWaypointTransitStatus, shouldWaypointTransitAlertBeDisplayed } from '@/functions/shifts';
import { setOrUnsetShipmentBox } from '@/functions/supplies/set';
import { routeNames } from '@/router/model';
import {
  setWaypointStatusToAtWaypoint,
  setWaypointStatusToTransitWaypoint,
} from '@/store/modules/itineraryFlow/helpers';
import { WaypointDeviationName } from '@/store/modules/itineraryFlow/model';
import { trackFinishWaypoint } from '@/views/nurse/helpers/tracking';

const mailInStopDetailsCompletionAction: FlowCompletionAction = async (route) => {
  const store = getStore();

  const { itineraryID, waypointID } = route.params;
  if (!itineraryID) throw Error('Missing itinerary ID');
  if (!waypointID) throw Error('Missing waypoint ID');

  const { status, drivingEstimate } = await getWaypointTransitStatus(itineraryID);
  store.commit('itineraryFlow/UPDATE_MODAL_VALUES', {
    waypointTransitAlert: { drivingEstimate, status },
  });

  if (shouldWaypointTransitAlertBeDisplayed(itineraryID, waypointID, status)) {
    store.commit('itineraryFlow/UPDATE_MODAL_VALUES', {
      waypointTransitAlert: { isOpened: true },
    });
    return false;
  }

  await setWaypointStatusToTransitWaypoint(waypointID);
};

const mailInDriveToLocationCompletionAction: FlowCompletionAction = async (route) => {
  const store = getStore();

  const { itineraryID, waypointID } = route.params;
  if (!itineraryID) throw Error('Missing itinerary ID');
  if (!waypointID) throw Error('Missing waypoint ID');

  const failedCheck = await store.dispatch(
    'itineraryFlow/initFailedDeviationChecks',
    { waypointID, checkSequence: [WaypointDeviationName.LOCATION] },
  );
  if (failedCheck) return false;

  await setWaypointStatusToAtWaypoint(itineraryID, waypointID);
};

const mailInScanItemsCompletionAction: FlowCompletionAction = async (route) => {
  const store = getStore();

  const { itineraryID, waypointID } = route.params;
  if (!itineraryID) throw Error('Missing itinerary ID');
  if (!waypointID) throw Error('Missing waypoint ID');

  const shipments = store.getters[
    'waypoint/getWaypointMailInShipments'
  ](waypointID);

  const dbUpdates = shipments.reduce<ByPath<boolean>>((updatesByPath, { id }) => {
    const path = getShipmentPath(
      id,
      'scanning/shipmentBoxNurseScans/waypointArrival',
    );
    return {
      ...updatesByPath,
      [path]: true,
    };
  }, {});

  const storeUpdates = shipments.reduce<ByID<Partial<ShipmentBoxNurseScans>>>(
    (updates, { id }) => ({
      ...updates,
      [id]: { waypointArrival: true },
    }),
    {},
  );

  const disableScanning = flagOn(FeatureFlag.DISABLE_PATIENT_VISIT_SUPPLY_SCANNING);

  /**
   * - Set supply item to used.
   * - Set `Shipment.shipmentBoxSupplyItem` for easier access to supply item ID
   * and supply ID in the rest of the flow.
   */
  const shipmentBoxPromises = shipments.map(async (shipment) => {
    const mailInActionID = shipment.waypointActionID;
    if (!mailInActionID) {
      toastErrorAndReport(`Waypoint action ID missing on shipment ${shipment.id}`);
      return;
    }
    const mailInAction = store.getters[
      'waypoint/getMailInActionByID'
    ](mailInActionID);
    if (!mailInAction) {
      toastErrorAndReport(`Missing or invalid mail-in action ${mailInActionID}`);
      return;
    }
    const supplyItemID = mailInAction.mailInBoxBarcode;

    const supplyItem = store.state.supplies.supplyItems?.[supplyItemID];

    let supplyID: string | undefined;

    if (!disableScanning && !supplyItem) {
      toastErrorAndReport(`No supply item found for barcode ${supplyItemID}`);
    } else {
      const { skuID } = supplyItem ?? {};
      const sku = await getValueOnce<Sku>(`supplies/skus/${skuID}`);
      if (!disableScanning && !sku) {
        toastErrorAndReport(`No sku found for skuID ${skuID}`);
      }
      supplyID = sku?.supplyID;
    }

    if (!supplyID) {
      toastErrorAndReport(`No supply ID found for barcode ${supplyItemID}`);
      return;
    }

    const supplyItemWithSupplyID: SupplyItemWithSupplyID = {
      id: supplyItemID,
      supplyID,
    };
    await setOrUnsetShipmentBox(shipment, supplyItemWithSupplyID);
  });

  await Promise.all(shipmentBoxPromises);
  await dbGroupSet<boolean>(dbUpdates);
  store.commit('supplies/UPDATE_SHIPMENT_BOX_NURSE_SCANS', storeUpdates);
};

const mailInScanBoxCompletionAction: FlowCompletionAction = async (route) => {
  const store = getStore();

  const { shipmentID } = route.params;
  if (!shipmentID) throw Error('Missing shipment ID');

  const path = getShipmentPath(
    shipmentID,
    'scanning/shipmentBoxNurseScans/preparingToLabel',
  );
  await dbSet<boolean>(path, true);

  store.commit('supplies/UPDATE_SHIPMENT_BOX_NURSE_SCANS', {
    [shipmentID]: { preparingToLabel: true },
  });
};

const mailInScanLabelCompletionAction: FlowCompletionAction = async (route) => {
  const store = getStore();

  const { shipmentID } = route.params;
  if (!shipmentID) throw Error('Missing shipment ID');

  const { mailIn } = store.state.itineraryFlow.localState;
  const barcodeValue = mailIn.scanLabel?.id;
  if (!barcodeValue) throw Error('Missing barcode value');

  const shipmentUpdate: Partial<Shipment> = {
    scannedShippingLabelBarcode: barcodeValue,
    status: initStatus(SupplyShipmentStatusName.READY_TO_SHIP),
  };

  const path = getShipmentPath(shipmentID);
  await dbUpdate<Partial<Shipment>>(path, shipmentUpdate);

  store.commit('supplies/UPDATE_SHIPMENT', {
    id: shipmentID,
    ...shipmentUpdate,
  });
};

const mailInTakePhotoCompletionAction: FlowCompletionAction = async (route) => {
  const store = getStore();

  const { shipmentID } = route.params;
  if (!shipmentID) throw Error('Missing shipment ID');

  const { mailIn } = store.state.itineraryFlow.localState;
  const encryptedFile = mailIn.takePhoto;
  if (!encryptedFile) throw Error('Missing file');

  const path = getShipmentPath(
    shipmentID,
    'shipmentBoxImage',
  );
  await dbSet<EncryptedFile>(path, encryptedFile);

  store.commit('supplies/UPDATE_SHIPMENT', {
    id: shipmentID,
    shipmentBoxImage: encryptedFile,
  });
};

const mailInDropOffCompletionAction: FlowCompletionAction = async (route) => {
  const store = getStore();

  const { shipmentID } = route.params;
  if (!shipmentID) throw Error('Missing shipment ID');

  const shipmentUpdate: Partial<Shipment> = {
    status: initStatus(SupplyShipmentStatusName.IN_TRANSIT),
  };

  const path = getShipmentPath(shipmentID);
  await dbUpdate<Partial<Shipment>>(path, shipmentUpdate);

  store.commit('supplies/UPDATE_SHIPMENT', {
    id: shipmentID,
    ...shipmentUpdate,
  });
};

/** Finalization step of mail-in waypoint */
const mailInShipmentsCompletionAction: FlowCompletionAction = async (route) => {
  const store = getStore();

  const { itineraryID, waypointID } = route.params;
  if (!itineraryID) throw Error('Missing itinerary ID');
  if (!waypointID) throw Error('Missing waypoint ID');

  const failedChecks = await store.dispatch(
    'itineraryFlow/initFailedDeviationChecks',
    { waypointID },
  );
  // Modal will appear in sequence for each failed check
  if (failedChecks) return false;

  await store.dispatch('waypoint/setProceduresSamplesStatus', {
    waypointID,
    status: SampleStatus.COMPLETED,
  });

  await store.dispatch('waypoint/updateWaypointAndActionStatus', {
    waypointID: waypointID,
    waypointStatus: WaypointStatusName.COMPLETE,
    waypointActionStatus: WaypointActionStatusName.COMPLETE,
  });
  await trackFinishWaypoint(waypointID);
};

/**
 * If a completion action is defined, the next button will trigger that action
 * rather than being a `<router-link>` to the next step. While the async action
 * is in progress, a loader will show on the next button. If the promise
 * resolves, the user will be navigated to the next step.
 *
 * **To cancel navigation, an error must be thrown, or false must be returned.**
 * Errors thrown within these actions will be shown to the user and prevent
 * navigation to the next step.
 */
export const itineraryFlowMailInCompletionActions: FlowCompletionActions = {
  [routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_STOP_DETAILS]:
    mailInStopDetailsCompletionAction,
  [routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_DRIVE_TO_LOCATION]:
    mailInDriveToLocationCompletionAction,
  [routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SCAN_ITEMS]:
    mailInScanItemsCompletionAction,
  [routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_SCAN_BOX]:
    mailInScanBoxCompletionAction,
  [routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_SCAN_LABEL]:
    mailInScanLabelCompletionAction,
  [routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_TAKE_PHOTO]:
    mailInTakePhotoCompletionAction,
  [routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_DROP_OFF]:
    mailInDropOffCompletionAction,
  [routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENTS]:
    mailInShipmentsCompletionAction,
};
