import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';

const routesUserSettings: RouteConfig[] = [
  {
    path: '/:partnerName?/settings/:stepRouteSlug?/:childStepRouteSlug?',
    name: routeNames.USER_SETTINGS,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-user-settings' */
      '@/views/userSettings/UserSettings.vue'
    ),
    meta: {
      authRequired: true,
    },
  },
];

export { routesUserSettings };
