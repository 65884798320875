import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';

const routesLogin: RouteConfig[] = [
  {
    path: '/:partnerName?/forgotpassword',
    name: routeNames.FORGOT_PASSWORD,
    component: () => import(
      /* webpackChunkName: 'chunk-forgot-password' */
      '@/views/signUp/ForgotPassword.vue'
    ),
  },
  {
    path: '/:partnerName?/login',
    name: routeNames.LOGIN,
    component: () => import(
      /* webpackChunkName: 'chunk-main' */
      /* webpackMode: 'eager' */
      '@/views/login/LoginPage.vue'
    ),
    meta: {
      hideSignInButton: true,
    },
  },
];

export { routesLogin };
