import { ExtendedCustomModule, OrdersModule, initOrdersModule } from '@caresend/ui-components';

import type { RootState } from '@/store/model';
import { ExtendedOfficeMutations } from '@/store/modules/office';
import { ExtendedOfficesActions } from '@/store/modules/offices';
import { ExtendedUsersActions, ExtendedUsersMutations } from '@/store/modules/users';
import { ExtendedVariablesGetters } from '@/store/modules/variables';

interface ExtraOrdersState {}

const extraOrdersState: ExtraOrdersState = {};
const extraOrdersMutations = {};
const extraOrdersActions = {};
const extraOrdersGetters = {};

export const ordersModuleExtension = {
  state: extraOrdersState,
  mutations: extraOrdersMutations,
  actions: extraOrdersActions,
  getters: extraOrdersGetters,
};

export const ordersModule: ExtendedCustomModule<
OrdersModule<
  RootState,
  ExtendedUsersMutations & ExtendedOfficeMutations,
  ExtendedOfficesActions & ExtendedUsersActions,
  ExtendedVariablesGetters
>,
  typeof ordersModuleExtension
> = initOrdersModule(ordersModuleExtension);

export type ExtendedOrdersModule = typeof ordersModule;

export type ExtendedOrdersMutations = ExtendedOrdersModule['mutations'];
export type ExtendedOrdersActions = ExtendedOrdersModule['actions'];
export type ExtendedOrdersGetters = ExtendedOrdersModule['getters'];
