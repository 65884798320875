import { isProd } from '@caresend/ui-components';

export const SEGMENT_TOKEN = isProd
  ? '18Nu7wWG6ujx5Hsc5T53qJv9mjyJ4LLa'
  : 'DrsCrYU8fvh2Uz7Ne7Mku0TN0Mnic7pS';

export const REFINER_PROJECT_TOKEN = '5efbb780-8583-11ec-a72d-cf19a7d6ccea';

export const PLACES_TOKEN = 'AIzaSyCLcCBd6iK37JUMngt6Z7ZM6zUdh7Gq9oY';

export const ALGOLIA_APP_ID = '734Q94UIDR';

export const MAPBOX_TOKEN
  = 'pk.eyJ1IjoiYmlyb2xsZWF1ZiIsImEiOiJjampuMHByenoxNmRoM2ttcHVqNmd0bzFvIn0.Fml-ls_j4kW_OJViww4D_w';
export const MAPBOX_STYLE = 'mapbox://styles/birolleauf/cklsxs9oa0nu217qaf1hp08m8?optimize=true';

export const SHOPIFY_TOKEN = '4fab2a2349ad612c828bddd6fa36530b';
export const SHOPIFY_DOMAIN = 'supplies.caresend.co';

export const FROM_CARESEND_EMAIL = 'team@caresend.co';
export const BOOKING_CARESEND_EMAIL = 'book@caresend.co';

export const SENTRY_DSN = 'https://55c7d5b024324e1697e9b38b708e3062@o878771.ingest.sentry.io/5830895';

export const STRIPE_PUBLISHABLE_KEY = isProd
  ? 'pk_live_51IHzrfEoBSNVKZohcGCi8eeWmXrKU9w911iP5Ns4B7JDTih4qYBIlTa9OMm6jfWuzkDzkKhzNk0rTXvG7OI8jV7700MHA8X254'
  : 'pk_test_51IHzrfEoBSNVKZohqdYZ9tisHlc68rSCeboDgqhHlsw2z7hN7IslNRFrYjdRNZGzT7LyxjCrtzlNnNlTNXzvLwaS00y2zLIyEy';

export const SUPPORT_PHONE_NUMBER = 'tel:+18662561839';
