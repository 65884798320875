
import { EncryptedUser, UserStatus } from '@caresend/types';
import Vue from 'vue';

import HoldingPage from '@/components/status/HoldingPage.vue';
import InterviewScheduled from '@/components/status/InterviewScheduled.vue';
import PendingScreen from '@/components/status/PendingScreen.vue';
import ProfileDeclined from '@/components/status/ProfileDeclined.vue';
import RequireApproval from '@/components/status/RequireApproval.vue';
import ScheduleInterview from '@/components/status/ScheduleInterview.vue';
import WaitlistScreen from '@/components/status/WaitlistScreen.vue';
import { userStatusToInterviewScheduled } from '@/database/firebase/users';

export default Vue.extend({
  components: {
    ProfileDeclined,
    ScheduleInterview,
    RequireApproval,
    HoldingPage,
    InterviewScheduled,
    WaitlistScreen,
    PendingScreen,
  },
  data() {
    return { UserStatus };
  },
  computed: {
    user(): EncryptedUser | undefined {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    scheduleInterview(interviewUri: string) {
      const { id } = this.user as EncryptedUser;
      userStatusToInterviewScheduled(id, interviewUri);
    },
  },
});
