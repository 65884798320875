import {
  Barcode,
  ByID,
  ChartingChecklistItem,
  ChartingItem,
  EncryptedFile,
  NonEmptyArray,
  NonProcessingSampleCheckItem,
  Sample,
  SampleCollectionFailure,
  SampleDropOffFailure,
  SupplyInstructionOptions,
  SupplyItemWithSupplyID,
  WaypointTransitStatus,
  YesNoOptionID,
  YesNoOptionLabel,
} from '@caresend/types';
import {
  DataFetchedByParam,
  FlowConfig,
  FlowStep,
  FlowStepMap,
  TextBoxPickerItem,
} from '@caresend/ui-components';

import { DurationDeviationReason, LocationDeviationReason } from '@/components/shared/WaypointCompliance/model';
import {
  getIsClinicianTooFarFromWaypoint,
  waypointDurationWasLongerThanExpected,
} from '@/functions/itinerary/waypoints';
import { ScannableItemWithKey } from '@/functions/supplies/scanning';
import type { CustomActionContext, RootState } from '@/store/model';

export enum ItineraryFlowParam {
  /** Root */
  ITINERARY_ID = 'itineraryID',

  /**
   * Child of itinerary
   *
   * Potential issue: is also used as child of self. See
   * `buildScanWaypointSupplyStep`
   */
  WAYPOINT_ID = 'waypointID',

  /** Child of waypoint */
  WAYPOINT_ACTION_ID = 'waypointActionID',

  /** Child of waypoint */
  PROCEDURE_ID = 'procedureID',

  /** Child of waypoint */
  PROCESSING_ACTION_ID = 'processingActionID',

  /** Child of procedure */
  SAMPLE_ID = 'sampleID',

  /** Child of waypoint */
  SHIPMENT_ID = 'shipmentID',
}

export type ItineraryFlowConfig = FlowConfig<
  ItineraryFlowParam.ITINERARY_ID,
  ItineraryFlowParam,
  RootState
>

export type ItineraryStep = FlowStep<ItineraryFlowConfig>;

export type ItineraryStepMap = FlowStepMap<ItineraryFlowConfig>;

export interface ChartingCompleteParams {
  chartingItems: ChartingItem[];
  procedureID: string;
  groupIndex?: number[];
  checklistItemIndex?: number;
}

export interface SetChartingItemMutationParams {
  procedureID: string;
  newPartialChartingItem?: Partial<ChartingItem>;
  chartingItemIndexArray: number[];
  newChartingChecklistItem?: Partial<ChartingChecklistItem>;
  checklistItemIndex?: number;
}

// export type PatientWaypointStepMap = Map<RouteName, ItineraryStep>

export const yesNoOptionLabelReplacement = '{{ sampleContainerName }}';

export const yesNoOptions = [
  {
    id: YesNoOptionID.YES,
    label: 'I completely filled this {{ sampleContainerName }}',
  },
  {
    id: YesNoOptionID.PARTIAL,
    label: 'I partially filled this {{ sampleContainerName }}',
  },
  {
    id: YesNoOptionID.NO,
    label: 'I was unable to fill the {{ sampleContainerName }}',
  },
] as const;

export type YesNoOptions = typeof yesNoOptions;

export const sampleCollectionLabelReplacement = '{{ sampleContainerName }}' as const;

export const sampleCollectionOptions = [
  {
    id: YesNoOptionID.YES,
    label: 'I completely filled this {{ sampleContainerName }}',
  },
  {
    id: YesNoOptionID.PARTIAL,
    label: 'I partially filled this {{ sampleContainerName }}',
  },
  {
    id: YesNoOptionID.NO,
    label: 'I was unable to collect any sample in this {{ sampleContainerName }}',
  },
] as const;

export type SampleCollectionOptions = typeof sampleCollectionOptions;

export const simpleYesNoOptions = [
  {
    id: YesNoOptionID.YES,
    label: YesNoOptionLabel.YES,
  },
  {
    id: YesNoOptionID.NO,
    label: YesNoOptionLabel.NO,
  },
] as const;

export const simpleYesNoOptionsTextBoxPicker: TextBoxPickerItem[] = [
  {
    value: YesNoOptionID.YES,
    label: YesNoOptionLabel.YES,
  },
  {
    value: YesNoOptionID.NO,
    label: YesNoOptionLabel.NO,
  },
];

export type SimpleYesNoOptions = typeof simpleYesNoOptions;

export const droppedOffOptions = [
  {
    id: YesNoOptionID.YES,
    label: 'Dropped off',
  },
  {
    id: YesNoOptionID.NO,
    label: 'Not dropped off',
  },
] as const;

export const bubbleRadioYesNoOptions = [YesNoOptionLabel.YES, YesNoOptionLabel.NO];

export type DroppedOffOptions = typeof droppedOffOptions;

export interface SampleCheckQuestion {
  /**
   * A question may be disabled based on a prerequisite question. i.e., if
   * a tube is not filled, the processing questions are disabled.
   */
  disabled?: boolean;
  /** Key used to map the data to our structure */
  key: SupplyInstructionOptions | NonProcessingSampleCheckItem;
  /** Label displayed above radio options */
  label?: string;
  /** Radio Options */
  options: YesNoOptions | DroppedOffOptions;
  /** State of radio option */
  selected: YesNoOptionID | null;
  /** Sample collection failure object */
  collectionFailure?: SampleCollectionFailure;
  /** Sample drop-off failure object */
  dropOffFailure?: SampleDropOffFailure;
}

export interface ExtendedSample extends Sample {
  color?: string;
  imageColor?: string;
  name: string;
  noScanAfterCollection: boolean;
  orderWeight: number;
  // questions: SampleCheckQuestion[];
}

/** ExtendedSample, but with supplyItemID and serial required. */
export interface ExtendedCollectedSample extends ExtendedSample {
  serial: string;
  supplyItemID: string;
}

export interface ProcedureInput {
  /** Procedure ID */
  id?: string;
  chartingItems?: ChartingItem[];
  reqCheck?: [boolean, boolean, boolean];
}

export interface WaypointActionInput {
  /**
   * Whether the checkbox on the pick-up/drop off step associated with this
   * waypoint action is checked. Note: Waypoint Actions with sample drop-off
   * charting and no custom waypoint action instruction do not show a checkbox.
   */
  checked: boolean;
}

export interface CentrifugationState {
  /**
   * By Procedure ID
   *
   * Value represents if the procedure has the centrifugated samples sealed
   * in a new specimen bag.
   */
  centrifugedProcedures: ByID<boolean>;
  /** By Sample Processing Action ID */
  initialSpecimenBagsScanned: ByID<boolean>;
  itineraryID: string | undefined;
  /**
   * By Procedure ID
   *
   * Value represents the new specimen bag has been scanned for the
   * procedure.
   */
  newSpecimenBagsScanned: ByID<SupplyItemWithSupplyID>;
  /**
   * Photo used for confirmation of centrifugation.
   *
   * By Processing action ID
   */
  photo: ByID<EncryptedFile | null>;
  /**
   * Whether all samples have been scanned for centrifugation.
   *
   * By Processing action ID
   */
  samplesRequiringCentrifugationScanned: ByID<boolean>;
  /**
   * By Procedure ID
   *
   * Value represents the list of sampleIDs that have been scanned for the
   * new specimen bag.
   */
  samplesScannedForNewSpecimenBag: ByID<string[]>;
}

/**
 * State used to track file uploads and scanning for mail-in (packed mail-in
 * waypoint) steps. Data is initialized when a step loads and is only used
 * while viewing a given step, therefore there is no storage by ID.
 */
export interface MailInState {
  scanBox: SupplyItemWithSupplyID | null;
  scanItems: ScannableItemWithKey[];
  scanLabel: Barcode | null;
  takePhoto: EncryptedFile | null;
}

/** May consider supporting additional modals in the future. */
export enum WaypointDeviationName {
  DURATION = 'duration',
  LOCATION = 'location',
}

export type DeviationReasonsByName = {
  [WaypointDeviationName.DURATION]: DurationDeviationReason;
  [WaypointDeviationName.LOCATION]: LocationDeviationReason;
}

export type FailedDeviationCheck<
K extends WaypointDeviationName = WaypointDeviationName,
T extends DeviationReasonsByName[K] = DeviationReasonsByName[K]
> = {
  name: WaypointDeviationName;
  reason: T | null;
}

export type DeviationChecks = {
  activeModal: WaypointDeviationName;
  failedChecks: FailedDeviationCheck[];
}

export type DeviationChecker = (waypointID: string) => boolean;

export const deviationFailureGetters: Record<WaypointDeviationName, DeviationChecker> = {
  [WaypointDeviationName.DURATION]: waypointDurationWasLongerThanExpected,
  [WaypointDeviationName.LOCATION]: getIsClinicianTooFarFromWaypoint,
};

export enum PackingFlowInstruction {
  REMOVE_TAPE_STRIPS = 'removeTapeStrips',
  REMOVE_PRESSURE_BAG = 'removePressureBag',
  PLACE_ICE_PACKS = 'placeIcePacks',
  PLACE_LID = 'placeLid',
  SEAL_BOX = 'sealBox',
}

export interface ItineraryFlowLocalState {
  /**
   * Used to track the state of Centrifugation flow.
   */
  centrifugation: CentrifugationState;
  dataFetched: DataFetchedByParam<ItineraryFlowParam>;
  /**
   * When this object is present, modal(s) are being displayed and reasons
   * are being collected for deviation(s). Once all reasons are collected
   * and the tracking event is submitted, the object is removed.
   */
  deviationChecks: DeviationChecks | null;
  /**
   * Required data to build steps is available. For the itinerary flow, this
   * also means flow loading is completed.
   */
  flowDataIsReady: ByID<boolean>;
  /**
   * Loading state for initial flow loading or subsequent data fetches. During
   * loading, contains a loading message string displayed to the user.
   */
  flowLoading: string | false;
  flowLoadingError: string | null;
  mailIn: MailInState;
  /**
   * Duration and location deviation modals are controlled by
   * `deviationChecks`.
   */
  modals: {
    waypointTransitAlert: {
      drivingEstimate: number;
      isOpened: boolean;
      status: WaypointTransitStatus | null;
    };
  };
  /** Set automatically while completion actions resolve. */
  nextButtonLoading: boolean;
  mailInBoxScanned?: boolean;
  specimenBagsScanned?: boolean;
  /** By PackingFlowInstruction key */
  packingFlowInstructions?: ByID<boolean>;
  /** By Procedure ID */
  scannedShippingLabelBarcode?: ByID<Barcode>;
}

export interface ItineraryFlowState {
  localState: ItineraryFlowLocalState;
  proceduresInput: ByID<ProcedureInput>;
  /** Used to track drop-off and pick-up waypoint actions */
  waypointActionsInput: ByID<WaypointActionInput>;
}

export type ItineraryFlowActionContext = CustomActionContext<
  'itineraryFlow',
  ItineraryFlowState
>

export type ItineraryFlowActions = {
  'itineraryFlow/initFailedDeviationChecks': (
    context: ItineraryFlowActionContext,
    payload: {
      waypointID: string;
      checkSequence?: WaypointDeviationName[];
    }
  ) => Promise<NonEmptyArray<FailedDeviationCheck> | null>;

  'itineraryFlow/initItineraryFlow': (
    context: ItineraryFlowActionContext,
    itineraryID: string
  ) => Promise<void>;

  'itineraryFlow/performCompletionAction': (
    context: ItineraryFlowActionContext,
  ) => Promise<void>;

  'itineraryFlow/setDeviationReasonAndContinue': (
    context: ItineraryFlowActionContext,
    payload: FailedDeviationCheck
  ) => Promise<void>;
}
