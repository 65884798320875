import {
  ByID,
  DbRef,
  ElementTimestamp,
  PlaceAccount,
  PlaceGroupType,
} from '@caresend/types';
import {
  AnyGetters,
  ExtendedCustomModule,
  PlacesModule,
  PlacesState,
  firebaseBind,
  firebaseUnbind,
  initPlacesModule,
} from '@caresend/ui-components';
import { nullishFilter } from '@caresend/utils';

import type { CustomActionContext, RootState } from '@/store/model';

interface ExtraPlacesState {
  bindCount: number;
}

const extraPlacesState: ExtraPlacesState = {
  bindCount: 0,
};

type S = PlacesState & ExtraPlacesState;

const extraPlacesMutations = {
  'places/UPDATE_BIND_COUNT': (state: S, iteration: -1 | 1) => {
    state.bindCount += iteration;
  },
};

type ExtraPlacesActionContext = CustomActionContext<'places', S>

export type ExtraPlacesActions = {
  'places/bindAllPlaceData': (
    context: ExtraPlacesActionContext,
  ) => void;

  'places/unbindAllPlaceData': (
    context: ExtraPlacesActionContext,
  ) => void;
}

const extraPlacesActions: ExtraPlacesActions = {
  /**
   * @deprecated
   *
   * We should migrate to fetching needed data rather than binding all data in
   * pro.
   */
  'places/bindAllPlaceData': ({ commit, dispatch, state }) => {
    commit('places/UPDATE_BIND_COUNT', 1);
    if (state.bindCount > 1) return;

    const placeGroupTypesPath = `${DbRef.PLACE_GROUP_TYPES}`;
    firebaseBind<ByID<PlaceGroupType>>(placeGroupTypesPath, (placeGroupTypes) => {
      if (!placeGroupTypes) return;
      commit('places/SET_PLACE_GROUP_TYPES', placeGroupTypes);
      Object.values(placeGroupTypes).forEach((placeGroupType) => {
        const placeGroupIDs = Object.keys(placeGroupType.placeGroups ?? {});
        if (!placeGroupIDs.length) return;
        dispatch('places/fetchPlaceGroups', { placeGroupIDs });
      });
    });
  },

  /**
   * @deprecated
   *
   * We should migrate to fetching needed data rather than binding all data in
   * pro.
   */
  'places/unbindAllPlaceData': ({ commit, state }) => {
    commit('places/UPDATE_BIND_COUNT', -1);
    if (state.bindCount > 0) return;

    const placeGroupTypesPath = `${DbRef.PLACE_GROUP_TYPES}`;
    firebaseUnbind(placeGroupTypesPath);
  },
};

const extraPlacesGetters = {
  'places/getUserAndOfficePlaceAccounts': (
    state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => {
    const accountMap = (element: ElementTimestamp): PlaceAccount | undefined =>
      state.placeAccounts?.[element.id];
    const accountSort = (labA?: PlaceAccount, labB?: PlaceAccount): number => {
      if (!labA && labB) return 1;
      if (labA && !labB) return -1;
      if (!labA || !labB) return 0;
      return (labA?.placeGroupID ?? '') < (labB?.placeGroupID ?? '') ? -1 : 1;
    };

    const accounts = {
      ...rootState.auth?.user?.placeAccounts,
      ...rootState.office?.office?.sharedPlaceAccounts,
    };

    return Object.values(accounts)
      .map(accountMap)
      .filter(nullishFilter)
      .sort(accountSort);
  },
};

export const placesModuleExtension = {
  state: extraPlacesState,
  mutations: extraPlacesMutations,
  actions: extraPlacesActions,
  getters: extraPlacesGetters,
};

export const placesModule: ExtendedCustomModule<
  PlacesModule,
  typeof placesModuleExtension
> = initPlacesModule(placesModuleExtension);

export type ExtendedPlacesModule = typeof placesModule;

export type ExtendedPlacesMutations = ExtendedPlacesModule['mutations'];
export type ExtendedPlacesActions = ExtendedPlacesModule['actions'];
export type ExtendedPlacesGetters = ExtendedPlacesModule['getters'];
