import { Booking, ByID } from '@caresend/types';
import {
  BookingsModule,
  ExtendedCustomModule,
  OrdersActions,
  UsersActions,
  UsersGetters,
  UsersMutations,
  initBookingsModule,
} from '@caresend/ui-components';

import { BookingsActions, BookingsState } from '@/store/modules/bookings/model';

type S = BookingsState;

const bookingsMutations = {
  'bookings/SET_BOOKINGS': (state: S, newBookings: ByID<Booking>) => {
    state.bookings = {
      ...state.bookings,
      ...newBookings,
    };
  },

  'bookings/RESET_BOOKINGS': (state: S) => {
    state.bookings = {};
  },
};

const bookingsActions: BookingsActions = {
};

const bookingsGetters = {

};

// export type BookingsGetters = typeof bookingsGetters;

export const bookingsModuleExtension = {
  mutations: bookingsMutations,
  actions: bookingsActions,
  getters: bookingsGetters,
};

export const bookingsModule: ExtendedCustomModule<
  BookingsModule<
    UsersMutations,
    UsersActions & OrdersActions,
    UsersGetters
  >,
  typeof bookingsModuleExtension
> = initBookingsModule(bookingsModuleExtension);

export type ExtendedBookingsModule = typeof bookingsModule;
export type ExtendedBookingsMutations = ExtendedBookingsModule['mutations'];
export type ExtendedBookingsActions = ExtendedBookingsModule['actions'];
export type ExtendedBookingsGetters = ExtendedBookingsModule['getters'];
