import { ByID, DraftWaypointSupplyStrategy, SampleStatus, SupplyInstructionOptions } from '@caresend/types';
import { getStore } from '@caresend/ui-components';
import { arrayToObj, byIdArrayToObj, hasTypeOfSupplyInstructionSingleCheck, objectMap } from '@caresend/utils';

import { allWaypointProceduresHaveSupplyTransfers, getProceduresByWaypointID } from '@/functions/itinerary/itinerary';
import { RouteName, routeNames } from '@/router/model';
import type { RootState } from '@/store/model';
import {
  getExtendedSampleForSample,
  getSortedExtendedSamples,
  procedureFlowTypeIsLabDraw,
  procedureHasFastingRequired,
  procedureHasFillByPatientInstruction,
  procedureHasNurseInstruction,
  procedureHasTransferFromContainerInstruction,
} from '@/store/modules/itineraryFlow/helpers';
import { ItineraryStep, ItineraryStepMap } from '@/store/modules/itineraryFlow/model';
import {
  buildProcedureSampleStep,
  buildScanProcedureSupplyStep,
  buildScanWaypointSupplyStep,
  buildWaypointPatientStep,
  buildWaypointProcedureStep,
  buildWaypointStep,
} from '@/store/modules/itineraryFlow/steps/stepBuildingHelpers';

// PATIENT WAYPOINT > PROCEDURE > SAMPLE STEPS

export const getChartingScanSampleStep = (
  procedureID: string,
  sampleID: string,
): ItineraryStep =>
  buildProcedureSampleStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_CHARTING_SCAN_SAMPLE,
    sampleID,
    procedureID,
  );

export const getCompleteChartingStep = (
  procedureID: string,
  sampleID: string,
): ItineraryStep =>
  buildProcedureSampleStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_COMPLETE_CHARTING,
    sampleID,
    procedureID,
  );

export const getChartingPutAmberBagStep = (
  procedureID: string,
  sampleID: string,
): ItineraryStep =>
  buildProcedureSampleStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_CHARTING_PUT_AMBER_BAG,
    sampleID,
    procedureID,
  );

export const getChartingScanAmberBagStep = (
  procedureID: string,
  sampleID: string,
): ItineraryStep =>
  buildProcedureSampleStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_CHARTING_SCAN_AMBER_BAG,
    sampleID,
    procedureID,
  );

export const getChartingPutInsulatedBagStep = (
  procedureID: string,
  sampleID: string,
): ItineraryStep =>
  buildProcedureSampleStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_CHARTING_PUT_INSULATED_BAG,
    sampleID,
    procedureID,
  );

export const getChartingScanInsulatedBagStep = (
  procedureID: string,
  sampleID: string,
): ItineraryStep =>
  buildProcedureSampleStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_CHARTING_SCAN_INSULATED_BAG,
    sampleID,
    procedureID,
  );

export const getChartingPutSpecimenBagStep = (
  procedureID: string,
  sampleID: string,
): ItineraryStep =>
  buildProcedureSampleStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_CHARTING_PUT_SPECIMEN_BAG,
    sampleID,
    procedureID,
  );

// PATIENT WAYPOINT > PROCEDURE STEPS

export const getScanProcedureSupplyStep = (
  procedureID: string,
  waypointID: string,
): ItineraryStep =>
  buildScanProcedureSupplyStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SCAN_PROCEDURE_SUPPLY,
    procedureID,
    waypointID,
  );

export const getScanProcedureSuppliesStep = (
  procedureID: string,
  waypointID: string,
): ItineraryStep =>
  buildScanProcedureSupplyStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SCAN_PROCEDURE_SUPPLIES,
    procedureID,
    waypointID,
  );

export const getClickProcedureSuppliesStep = (
  procedureID: string,
  waypointID: string,
): ItineraryStep =>
  buildScanProcedureSupplyStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_CLICK_PROCEDURE_SUPPLIES,
    procedureID,
    waypointID,
  );

export const getPatientDiagnosisStep = (
  procedureID: string,
  waypointID: string,
): ItineraryStep =>
  buildWaypointProcedureStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_PATIENT_DIAGNOSIS,
    procedureID,
    waypointID,
  );

export const getPatientConsentStep = (
  procedureID: string,
  waypointID: string,
): ItineraryStep =>
  buildWaypointProcedureStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_PATIENT_CONSENT,
    procedureID,
    waypointID,
  );

export const getFastingComplianceStep = (
  procedureID: string,
  waypointID: string,
): ItineraryStep =>
  buildWaypointProcedureStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_FASTING_COMPLIANCE,
    procedureID,
    waypointID,
  );

export const getSampleLabelChecklistStep = (
  procedureID: string,
  waypointID: string,
): ItineraryStep =>
  buildWaypointProcedureStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_LABEL_CHECKLIST,
    procedureID,
    waypointID,
  );

export const getCollectionInstructionsStep = (
  procedureID: string,
  waypointID: string,
): ItineraryStep =>
  buildWaypointProcedureStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_COLLECTION_INSTRUCTIONS,
    procedureID,
    waypointID,
  );

export const getRequiredPatientActionStep = (
  procedureID: string,
  waypointID: string,
): ItineraryStep =>
  buildWaypointProcedureStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_REQUIRED_PATIENT_ACTION,
    procedureID,
    waypointID,
  );

export const getTransferInstructionsStep = (
  procedureID: string,
  waypointID: string,
): ItineraryStep =>
  buildWaypointProcedureStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_TRANSFER_INSTRUCTIONS,
    procedureID,
    waypointID,
  );

export const getChartingStep = (
  procedureID: string,
  waypointID: string,
): ItineraryStep =>
  buildWaypointProcedureStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_CHARTING,
    procedureID,
    waypointID,
  );

export const getChartingSealSpecimenBagStep = (
  procedureID: string,
  waypointID: string,
): ItineraryStep =>
  buildWaypointProcedureStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_CHARTING_SEAL_SPECIMEN_BAG,
    procedureID,
    waypointID,
  );

export const getChartingScanSpecimenBagStep = (
  procedureID: string,
  waypointID: string,
): ItineraryStep =>
  buildWaypointProcedureStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_CHARTING_SCAN_SPECIMEN_BAG,
    procedureID,
    waypointID,
  );

export const getProcedurePackingInstructionsStep = (
  procedureID: string,
  waypointID: string,
): ItineraryStep =>
  buildWaypointProcedureStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_PACKING_INSTRUCTIONS,
    procedureID,
    waypointID,
  );

export const getProcedurePackingScanShippingLabelStep = (
  procedureID: string,
  waypointID: string,
): ItineraryStep =>
  buildWaypointProcedureStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SCAN_SHIPPING_LABEL,
    procedureID,
    waypointID,
  );

export const getChartingSamplesStep = (
  waypointActionID: string,
  procedureID: string,
  rootState: RootState,
): ItineraryStep => {
  const procedure = rootState.procedures.procedures[procedureID];
  const samples = getSortedExtendedSamples(procedureID, rootState);

  const subStepsArray = samples.map((sample, index) => {
    const extendedSample = getExtendedSampleForSample(sample, rootState);
    const { id: sampleID, noScanAfterCollection } = extendedSample;
    const isSampleLast: boolean = index === samples.length - 1;
    const stepMap = new Map<RouteName, ItineraryStep>();

    if (!noScanAfterCollection && !procedure?.disableSampleScanning) {
      const scanChartingSupplyStep = getChartingScanSampleStep(procedureID, sampleID);
      stepMap.set(scanChartingSupplyStep.routeName, scanChartingSupplyStep);
    }

    const completeChartingStep = getCompleteChartingStep(procedureID, sampleID);
    stepMap.set(completeChartingStep.routeName, completeChartingStep);

    if (hasTypeOfSupplyInstructionSingleCheck(
      sample, SupplyInstructionOptions.LIGHT_SENSITIVE,
    )) {
      const chartingPutAmberBagStep = getChartingPutAmberBagStep(procedureID, sampleID);
      stepMap.set(chartingPutAmberBagStep.routeName, chartingPutAmberBagStep);

      const chartingScanAmberBagStep = getChartingScanAmberBagStep(procedureID, sampleID);
      stepMap.set(chartingScanAmberBagStep.routeName, chartingScanAmberBagStep);
    }

    let addPutSpecimenBagStep = true;
    if (hasTypeOfSupplyInstructionSingleCheck(
      sample, SupplyInstructionOptions.REFRIGERATED,
    )) {
      addPutSpecimenBagStep = false;
      const chartingPutInsulatedBagStep = getChartingPutInsulatedBagStep(procedureID, sampleID);
      stepMap.set(chartingPutInsulatedBagStep.routeName, chartingPutInsulatedBagStep);

      const chartingScanInsulatedBagStep = getChartingScanInsulatedBagStep(procedureID, sampleID);
      stepMap.set(chartingScanInsulatedBagStep.routeName, chartingScanInsulatedBagStep);
      if (isSampleLast) {
        addPutSpecimenBagStep = true;
      }
    }

    if (addPutSpecimenBagStep) {
      const chartingPutSpecimenBagStep = getChartingPutSpecimenBagStep(procedureID, sampleID);
      stepMap.set(chartingPutSpecimenBagStep.routeName, chartingPutSpecimenBagStep);
    }

    return { [sampleID]: stepMap };
  });

  const subSteps = byIdArrayToObj(subStepsArray);

  return buildWaypointProcedureStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_CHARTING_SAMPLES,
    procedureID,
    waypointActionID,
    subSteps,
  );
};

// PATIENT WAYPOINT > WAYPOINT ACTION STEPS

export const getUnlockSuppliesStep = (
  waypointActionID: string,
  waypointID: string,
): ItineraryStep =>
  buildWaypointPatientStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PATIENT_UNLOCK_SUPPLIES,
    waypointActionID,
    waypointID,
  );

export const getPatientIdStep = (
  waypointActionID: string,
  waypointID: string,
): ItineraryStep =>
  buildWaypointPatientStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PATIENT_PATIENT_ID,
    waypointActionID,
    waypointID,
  );

// PATIENT WAYPOINT STEPS

const getPatientWaypointDetailsStep = (
  waypointID: string,
  itineraryID: string,
): ItineraryStep =>
  buildWaypointStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_DETAILS,
    waypointID,
    itineraryID,
  );

const getGoToPatientStep = (
  waypointID: string,
  itineraryID: string,
): ItineraryStep =>
  buildWaypointStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_GO_TO_PATIENT,
    waypointID,
    itineraryID,
  );

const getPatientWaypointActionBlockedStep = (
  waypointID: string,
  itineraryID: string,
): ItineraryStep =>
  buildWaypointStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_ACTION_BLOCKED,
    waypointID,
    itineraryID,
  );

const getScanWaypointSupplyStep = (
  waypointID: string,
): ItineraryStep =>
  buildScanWaypointSupplyStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_SCAN_WAYPOINT_SUPPLY,
    waypointID,
  );

const getNurseIdentityStep = (
  waypointID: string,
  itineraryID: string,
): ItineraryStep =>
  buildWaypointStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_NURSE_IDENTITY,
    waypointID,
    itineraryID,
  );

export const getPatientListStep = (
  waypointID: string,
  itineraryID: string,
  rootState: RootState,
): ItineraryStep => {
  const waypoint = rootState.waypoint.waypoints[waypointID];
  const waypointActionIdObjs = arrayToObj(waypoint?.waypointActions ?? [], 'id');
  /**
   * Map the steps to follow for each waypoint action
   *
   * Currently the steps are to check the patient's ID and unlock supplies
   */
  const waypointActionSubSteps = objectMap(
    waypointActionIdObjs,
    (idObj) => {
      const waypointActionID = idObj.id;
      const patientIdStep = getPatientIdStep(waypointActionID, waypointID);
      const waypointAction = rootState.waypoint.waypointActions[waypointActionID];
      const proceduresOnWaypointAction = Object.keys(waypointAction?.procedures ?? {}) || [];

      const someProcedureHasAKitSupplyItem = proceduresOnWaypointAction.some((procedureID) => {
        const procedure = rootState.procedures.procedures[procedureID];
        return !!procedure?.kitSupplyItem;
      });

      const steps = new Map<RouteName, ItineraryStep>([
        [patientIdStep.routeName, patientIdStep],
      ]);

      if (someProcedureHasAKitSupplyItem) {
        const unlockSuppliesStep = getUnlockSuppliesStep(waypointActionID, waypointID);
        steps.set(unlockSuppliesStep.routeName, unlockSuppliesStep);
      }

      return steps;
    },
  );
  /**
   * Get all id objects for all of the procedures at this waypoint
   *
   * We need these to map the steps to follow for each procedure
   */
  const proceduresArray = getProceduresByWaypointID(waypointID);
  const procedureIdObjs = arrayToObj(proceduresArray, 'id');
  /**
   * Map the steps to follow for each procedure
   */
  const procedureSubSteps = objectMap(
    procedureIdObjs,
    (idObj) => {
      const procedureID = idObj.id;
      const procedure = rootState.procedures.procedures[procedureID];
      const taskID = procedure?.taskID;
      const stepMap = new Map<RouteName, ItineraryStep>();
      if (!taskID) return stepMap;
      const task = rootState.variables.variables?.tasks[taskID];
      if (!task) return stepMap;

      /** PCR, Blood draw and Urine collection are of type `labDraw`. */
      const isLabFlow = procedureFlowTypeIsLabDraw(procedureID, rootState);

      // Need to determine which steps for non-labdraw
      if (isLabFlow) {
        // The patient consent step is in place of the patient signing a
        // requisition form, and only occurs on lab flows.
        const patientConsentStep
          = getPatientConsentStep(procedureID, waypointID);
        stepMap.set(patientConsentStep.routeName, patientConsentStep);
      }

      if (procedureHasNurseInstruction(procedureID, rootState)) {
        // Shows Diagnosis medical instructions from physician
        const patientDiagnosisStep
        = getPatientDiagnosisStep(procedureID, waypointID);
        stepMap.set(patientDiagnosisStep.routeName, patientDiagnosisStep);
      }

      if (procedureHasFastingRequired(procedureID, rootState)) {
        const fastingComplianceStep
          = getFastingComplianceStep(procedureID, waypointID);
        stepMap.set(fastingComplianceStep.routeName, fastingComplianceStep);
      }

      if (isLabFlow) {
        // The clinician is instructed to write patient name and DOB on each
        // sample label.
        const sampleLabelChecklistStep
          = getSampleLabelChecklistStep(procedureID, waypointID);
        stepMap.set(sampleLabelChecklistStep.routeName, sampleLabelChecklistStep);

        if (procedureHasFillByPatientInstruction(procedureID, rootState)) {
          const requiredPatientActionStep
            = getRequiredPatientActionStep(procedureID, waypointID);
          stepMap.set(requiredPatientActionStep.routeName, requiredPatientActionStep);
        }

        if (procedureHasTransferFromContainerInstruction(procedureID, rootState)) {
          const transferInstructionsStep
            = getTransferInstructionsStep(procedureID, waypointID);
          stepMap.set(transferInstructionsStep.routeName, transferInstructionsStep);
        }

        if (
          !procedureHasFillByPatientInstruction(procedureID, rootState)
          && !procedureHasTransferFromContainerInstruction(procedureID, rootState)
        ) {
          const collectionInstructionStep
            = getCollectionInstructionsStep(procedureID, waypointID);
          stepMap.set(collectionInstructionStep.routeName, collectionInstructionStep);
        }

        const chartingSamplesStep = getChartingSamplesStep(
          waypointID,
          procedureID,
          rootState,
        );
        stepMap.set(chartingSamplesStep.routeName, chartingSamplesStep);

        const chartingSealSpecimenBagStep
          = getChartingSealSpecimenBagStep(procedureID, waypointID);
        stepMap.set(chartingSealSpecimenBagStep.routeName, chartingSealSpecimenBagStep);

        const chartingScanSpecimenBagStep
          = getChartingScanSpecimenBagStep(procedureID, waypointID);
        stepMap.set(chartingScanSpecimenBagStep.routeName, chartingScanSpecimenBagStep);
      }

      const chartingStep = getChartingStep(procedureID, waypointID);
      stepMap.set(chartingStep.routeName, chartingStep);

      const usingKitShipmentStrategy = getStore().getters[
        'procedures/getSupplyStrategy'
      ](procedureID) === DraftWaypointSupplyStrategy.KIT_SHIPMENT;
      const allSamplesFailed = Object.values(
        procedure.samples ?? {},
      ).every((sample) =>
        sample.status === SampleStatus.FAILED,
      );

      if (usingKitShipmentStrategy && !allSamplesFailed) {
        const procedurePackingInstructionsStep = getProcedurePackingInstructionsStep(
          procedureID,
          waypointID,
        );
        stepMap.set(procedurePackingInstructionsStep.routeName, procedurePackingInstructionsStep);
        const procedurePackingScanShippingLabel = getProcedurePackingScanShippingLabelStep(
          procedureID,
          waypointID,
        );
        stepMap.set(procedurePackingScanShippingLabel.routeName, procedurePackingScanShippingLabel);
      }

      return stepMap;
    },
  );

  const subSteps = {
    ...waypointActionSubSteps,
    ...procedureSubSteps,
  };

  return buildWaypointStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PATIENT_LIST,
    waypointID,
    itineraryID,
    subSteps,
  );
};

const getLocateMailInBoxSteop = (
  waypointID: string,
  itineraryID: string,
): ItineraryStep =>
  buildWaypointStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_LOCATE_MAIL_IN_BOX,
    waypointID,
    itineraryID,
  );

const getScanInMailInBoxStep = (
  waypointID: string,
  itineraryID: string,
): ItineraryStep =>
  buildWaypointStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_SCAN_IN_MAIL_IN_BOX,
    waypointID,
    itineraryID,
  );

const getCentrifugationReminderStep = (
  waypointID: string,
  itineraryID: string,
): ItineraryStep =>
  buildWaypointStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_CENTRIFUGATION_REMINDER,
    waypointID,
    itineraryID,
  );

/**
 * The step during which the clinician packs supplies into procedure bags.
 */
export const getProceduresSupplyStep = (
  waypointID: string,
  itineraryID: string,
): ItineraryStep => {
  const procedures = getProceduresByWaypointID(waypointID);

  const subSteps = procedures
    .reduce<ByID<ItineraryStepMap>>((currentSubSteps, procedure) => {
      const stepMap = new Map<RouteName, ItineraryStep>();
      const procedureID = procedure.id;

      const scanProcedureSupplyStep = getScanProcedureSupplyStep(procedureID, waypointID);
      stepMap.set(scanProcedureSupplyStep.routeName, scanProcedureSupplyStep);
      const scanProcedureSuppliesStep = getScanProcedureSuppliesStep(procedureID, waypointID);
      stepMap.set(scanProcedureSuppliesStep.routeName, scanProcedureSuppliesStep);

      if (procedure.disableSampleScanning) {
        // Sample scanning enabled:
        // - All supplies scanned during scan procedure supplies step
        // Sample scanning disabled:
        // - Sample bags scanned during scan procedure supplies step
        // - Other supplies clicked during click procedure supplies step
        const clickProcedureSuppliesStep = getClickProcedureSuppliesStep(procedureID, waypointID);
        stepMap.set(clickProcedureSuppliesStep.routeName, clickProcedureSuppliesStep);
      }

      return {
        ...currentSubSteps,
        [procedureID]: stepMap,
      };
    }, {});

  return buildWaypointStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURES_SUPPLY,
    waypointID,
    itineraryID,
    subSteps,
  );
};

/**
 * The step during which the clinician packs procedure bags into CareSend bag(s)
 */
export const getWaypointSupplyStep = (
  waypointID: string,
  itineraryID: string,
): ItineraryStep => {
  const scanWaypointSupplyStep = getScanWaypointSupplyStep(waypointID);

  const subSteps = {
    [waypointID]: new Map<RouteName, ItineraryStep>([
      [scanWaypointSupplyStep.routeName, scanWaypointSupplyStep],
    ]),
  };

  return buildWaypointStep(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_WAYPOINT_SUPPLY,
    waypointID,
    itineraryID,
    subSteps,
  );
};

export const getPatientWaypointSteps = (
  waypointID: string,
  itineraryID: string,
): ItineraryStepMap => {
  const store = getStore();

  const patientWaypointStep = getPatientWaypointDetailsStep(waypointID, itineraryID);
  const goToPatientStep = getGoToPatientStep(waypointID, itineraryID);
  /**
   * Skip the procedure and waypoint supply steps if the waypoint procedures
   * all have a supply transfer associated with each of them
   */
  let proceduresSupplyStep: ItineraryStep | undefined;
  let waypointSupplyStep: ItineraryStep | undefined;
  const usingKitShipmentStrategy = store.getters[
    'waypoint/getSupplyStrategy'
  ](waypointID) === DraftWaypointSupplyStrategy.KIT_SHIPMENT;
  if (
    !allWaypointProceduresHaveSupplyTransfers(waypointID)
    /**
     * The supplies will be provided by a different company so we don't need
     * the nurse to prepare them
     */
    && !usingKitShipmentStrategy
  ) {
    proceduresSupplyStep = getProceduresSupplyStep(waypointID, itineraryID);
    waypointSupplyStep = getWaypointSupplyStep(waypointID, itineraryID);
  }

  const stepMap = new Map<RouteName, ItineraryStep>([
    [patientWaypointStep.routeName, patientWaypointStep],
    [goToPatientStep.routeName, goToPatientStep],
  ]);

  const blocked = !!getStore().getters[
    'waypoint/getRouteToReadyActionInItinerary'
  ](itineraryID);

  if (blocked) {
    const actionBlockedStep
      = getPatientWaypointActionBlockedStep(waypointID, itineraryID);
    stepMap.set(actionBlockedStep.routeName, actionBlockedStep);
  }

  if (proceduresSupplyStep) {
    stepMap.set(proceduresSupplyStep.routeName, proceduresSupplyStep);
  }

  if (waypointSupplyStep) {
    stepMap.set(waypointSupplyStep.routeName, waypointSupplyStep);
  }

  const nurseIdentityStep = getNurseIdentityStep(waypointID, itineraryID);
  const patientListStep = getPatientListStep(waypointID, itineraryID, store.state);

  stepMap.set(nurseIdentityStep.routeName, nurseIdentityStep);
  stepMap.set(patientListStep.routeName, patientListStep);

  const hasMailInBox = store.getters['waypoint/getWaypointHasMailInBox'](waypointID);
  const mailInBoxScanned = store.getters[
    'waypoint/getIsPatientWaypointMailInBoxScanned'
  ](waypointID);
  if (hasMailInBox && !mailInBoxScanned) {
    const locateMailInBoxStep = getLocateMailInBoxSteop(waypointID, itineraryID);
    const scanInMailInBoxStep = getScanInMailInBoxStep(waypointID, itineraryID);
    stepMap.set(locateMailInBoxStep.routeName, locateMailInBoxStep);
    stepMap.set(scanInMailInBoxStep.routeName, scanInMailInBoxStep);
  }

  const requiresCentrifugation = store.getters['waypoint/getWaypointNeedsCentrifuge'](waypointID);
  if (requiresCentrifugation) {
    const centrifugationReminderStep = getCentrifugationReminderStep(
      waypointID,
      itineraryID,
    );
    stepMap.set(centrifugationReminderStep.routeName, centrifugationReminderStep);
  }

  return stepMap;
};
