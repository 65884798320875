import { Instruction, InstructionType } from '@caresend/types';
import { ProceduresState, toastErrorAndReport } from '@caresend/ui-components';
import { buildBulletSeparatedString } from '@caresend/utils';

export const formatInstructions = (instructions: Instruction[]) => {
  const formatted = instructions.map((instruction) => {
    const noneFound = `No ${instruction.type} instructions provided`;
    if (instruction.type === InstructionType.FASTING && instruction.fields) {
      if (!instruction.fields[0]?.answer) return noneFound;
      return instruction.fields[0].answer;
    }
    if (!instruction.instruction) return noneFound;
    return instruction.instruction;
  });
  return buildBulletSeparatedString(formatted);
};

/**
 * Checks a sample path in the store. Use before using the `update` helper to
 * update a sample, to ensure data to update exists.
 */
export const sampleExists = (
  state: ProceduresState,
  procedureID: string,
  sampleID: string,
): boolean => {
  const sample = state.procedures[procedureID]?.samples?.[sampleID];
  if (!sample) {
    toastErrorAndReport(`Missing sample ${sampleID} on procedure ${procedureID}`);
    return false;
  }

  return true;
};
