
import { PropType, computed, defineComponent } from 'vue';

const colors = ['green', 'blue', 'red'];

export default defineComponent({
  name: 'Notes',
  props: {
    title: {
      type: String as PropType<string | null>,
      default: null,
    },
    icon: {
      type: String as PropType<string | null>,
      default: null,
    },
    color: {
      type: String as PropType<'green' | 'blue' | 'red'>,
      default: 'green',
      validator: (value: string) => colors.includes(value),
    },
  },
  setup(props, { slots }) {
    const hasTitleAndContent = computed(() =>
      props.title && slots.default,
    );

    const classNames = computed(() => {
      const names = [];

      switch (props.color) {
        case 'green':
          names.push('Notes--green');
          break;
        case 'blue':
          names.push('Notes--blue');
          break;
        case 'red':
          names.push('Notes--red');
          break;
      }

      if (hasTitleAndContent.value) {
        names.push('has-title-and-content');
      }

      if (props.icon) {
        names.push('has-icon');
      }

      return names;
    });

    return {
      classNames,
      hasTitleAndContent,
    };
  },
});
