import { render, staticRenderFns } from "./PendingScreen.vue?vue&type=template&id=0d98e6b8&scoped=true&"
import script from "./PendingScreen.vue?vue&type=script&lang=ts&"
export * from "./PendingScreen.vue?vue&type=script&lang=ts&"
import style0 from "./PendingScreen.vue?vue&type=style&index=0&id=0d98e6b8&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d98e6b8",
  null
  
)

export default component.exports