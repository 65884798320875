import { ByID } from '@caresend/types';
import { addAssociatedIDs } from '@caresend/ui-components';

import { RouteName, routeNames } from '@/router/model';
import { ItineraryFlowParam, ItineraryStep, ItineraryStepMap } from '@/store/modules/itineraryFlow/model';

// STEP BUILDING HELPERS

/**
 * Root steps
 *
 * /itinerary/:itineraryID
 */
export const buildItineraryStep = (
  routeName: RouteName,
  itineraryID: string,
  subSteps?: ByID<ItineraryStepMap>,
): ItineraryStep => addAssociatedIDs(
  {
    associatedParam: ItineraryFlowParam.ITINERARY_ID,
    routeName,
    subSteps,
  },
  itineraryID,
);

/**
 * Waypoint steps
 *
 * /itinerary/:itineraryID/waypoint/:waypointID
 * /itinerary/:itineraryID/mail-in/:waypointID
 */
export const buildWaypointStep = (
  routeName: RouteName,
  waypointID: string,
  itineraryID: string,
  subSteps?: ByID<ItineraryStepMap>,
): ItineraryStep => addAssociatedIDs(
  {
    associatedParam: ItineraryFlowParam.WAYPOINT_ID,
    parentAssociatedParam: ItineraryFlowParam.ITINERARY_ID,
    parentRouteName: routeNames.ITINERARY_FLOW_ITINERARY,
    routeName,
    subSteps,
  },
  waypointID,
  itineraryID,
);

/**
 * Waypoint > Shipment steps
 *
 * /itinerary/:itineraryID/mail-in/:waypointID/shipment/:shipmentID
 */
export const buildWaypointShipmentStep = (
  routeName: RouteName,
  shipmentID: string,
  waypointID: string,
  subSteps?: ByID<ItineraryStepMap>,
): ItineraryStep => addAssociatedIDs(
  {
    associatedParam: ItineraryFlowParam.SHIPMENT_ID,
    parentAssociatedParam: ItineraryFlowParam.WAYPOINT_ID,
    parentRouteName: routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENTS,
    routeName,
    subSteps,
  },
  shipmentID,
  waypointID,
);

/**
 * Waypoint action steps that are not child of waypoint
 *
 * (unpacked mail-in action)
 *
 * /itinerary/:itineraryID/waypoint-action/:waypointActionID
 */
export const buildRootWaypointActionStep = (
  routeName: RouteName,
  waypointActionID: string,
  itineraryID: string,
  subSteps?: ByID<ItineraryStepMap>,
): ItineraryStep => addAssociatedIDs(
  {
    associatedParam: ItineraryFlowParam.WAYPOINT_ACTION_ID,
    parentAssociatedParam: ItineraryFlowParam.ITINERARY_ID,
    parentRouteName: routeNames.ITINERARY_FLOW_ITINERARY,
    routeName,
    subSteps,
  },
  waypointActionID,
  itineraryID,
);

/**
 * Waypoint > Patient (Waypoint Action) steps
 *
 * /itinerary/:itineraryID/waypoint/:waypointID/waypoint-action/:waypointActionID
 */
export const buildWaypointPatientStep = (
  routeName: RouteName,
  waypointActionID: string,
  waypointID: string,
  subSteps?: ByID<ItineraryStepMap>,
): ItineraryStep => addAssociatedIDs(
  {
    associatedParam: ItineraryFlowParam.WAYPOINT_ACTION_ID,
    parentAssociatedParam: ItineraryFlowParam.WAYPOINT_ID,
    parentRouteName: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PATIENT_LIST,
    routeName,
    subSteps,
  },
  waypointActionID,
  waypointID,
);

/**
 * Waypoint > Procedure steps
 *
 * /itinerary/:itineraryID/waypoint/:waypointID/procedure/:procedureID
 */
export const buildWaypointProcedureStep = (
  routeName: RouteName,
  procedureID: string,
  waypointID: string,
  subSteps?: ByID<ItineraryStepMap>,
): ItineraryStep => addAssociatedIDs(
  {
    associatedParam: ItineraryFlowParam.PROCEDURE_ID,
    parentAssociatedParam: ItineraryFlowParam.WAYPOINT_ID,
    parentRouteName: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PATIENT_LIST,
    routeName,
    subSteps,
  },
  procedureID,
  waypointID,
);

/**
 * Procedure > Sample steps
 *
 * /itinerary/:itineraryID/waypoint/:waypointID/procedure/:procedureID/sample/:sampleID
 */
export const buildProcedureSampleStep = (
  routeName: RouteName,
  sampleID: string,
  procedureID: string,
  subSteps?: ByID<ItineraryStepMap>,
): ItineraryStep => addAssociatedIDs(
  {
    associatedParam: ItineraryFlowParam.SAMPLE_ID,
    parentAssociatedParam: ItineraryFlowParam.PROCEDURE_ID,
    parentRouteName: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_CHARTING_SAMPLES,
    routeName,
    subSteps,
  },
  sampleID,
  procedureID,
);

// TODO: Is Waypoint > Waypoint step intentional?
export const buildScanWaypointSupplyStep = (
  routeName: RouteName,
  waypointID: string,
  subSteps?: ByID<ItineraryStepMap>,
): ItineraryStep => addAssociatedIDs(
  {
    associatedParam: ItineraryFlowParam.WAYPOINT_ID,
    parentAssociatedParam: ItineraryFlowParam.WAYPOINT_ID,
    parentRouteName: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_WAYPOINT_SUPPLY,
    routeName,
    subSteps,
  },
  waypointID,
  waypointID,
);

export const buildScanProcedureSupplyStep = (
  routeName: RouteName,
  procedureID: string,
  waypointID: string,
  subSteps?: ByID<ItineraryStepMap>,
): ItineraryStep => addAssociatedIDs(
  {
    associatedParam: ItineraryFlowParam.PROCEDURE_ID,
    parentAssociatedParam: ItineraryFlowParam.WAYPOINT_ID,
    parentRouteName: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURES_SUPPLY,
    routeName,
    subSteps,
  },
  procedureID,
  waypointID,
);
