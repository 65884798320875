import { LocationObject, TrackingEvent } from '@caresend/types';

import { trackEvent } from '@/functions/tracking/tracking';

/**
 * Submit the result of trying to find which location a packer is at, regardless
 * of whether a match was found.
 */
export const trackSetUserAssociatedPlaceID = async (
  location: LocationObject,
  placeID: string | false,
) => {
  await trackEvent(
    TrackingEvent.SET_USER_ASSOCIATED_PLACE_ID,
    {
      lat: location.lat,
      lng: location.lng,
      placeID,
    },
  );
};
