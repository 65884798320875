import { computed } from 'vue';
import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';

const routesWallet: RouteConfig[] = [{
  path: '/:partnerName?/my-wallet',
  name: routeNames.MY_WALLET,
  component: () => import(
    /* webpackChunkName: 'chunk-profile' */
    '@/components/Wallet/WalletBalance.vue'
  ),
  meta: {
    authRequired: true,
    profileCompletedRequired: true,
    heading: () => computed(() => ({
      title: 'My wallet',
    })),
  },

},
{
  path: '/:partnerName?/my-wallet/add-bank-account',
  name: routeNames.CONNECT_BANK_ACCOUNT,
  component: () => import(
    /* webpackChunkName: 'chunk-profile' */
    '@/components/Wallet/ConnectBankAccount/ConnectBankAccount.vue'
  ),
  meta: {
    authRequired: true,
    profileCompletedRequired: true,
    heading: () => computed(() => ({
      title: 'Connect bank account',
      backLocation: {
        name: routeNames.MY_WALLET,
      },
    })),
  },
},
{
  path: '/:partnerName?/my-wallet/withdraw',
  name: routeNames.WITHDRAW_WALLET_BALANCE,
  component: () => import(
    /* webpackChunkName: 'chunk-profile' */
    '@/components/Wallet/WithdrawBalance/WithdrawBalance.vue'
  ),
  meta: {
    authRequired: true,
    profileCompletedRequired: true,
    heading: () => computed(() => ({
      title: 'Transfer to your bank',
      backLocation: {
        name: routeNames.MY_WALLET,
      },
    })),
  },
},
];

export { routesWallet };
