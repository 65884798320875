import { RouteName, routeNames } from '@/router/model';
import { ItineraryStep, ItineraryStepMap } from '@/store/modules/itineraryFlow/model';
import { buildRootWaypointActionStep } from '@/store/modules/itineraryFlow/steps/stepBuildingHelpers';

// UNPACKED MAIL-IN ACTION STEPS

const getUnpackedMailInActionScanMailInBoxStep = (
  waypointActionID: string,
  itineraryID: string,
) => buildRootWaypointActionStep(
  routeNames.ITINERARY_FLOW_UNPACKED_MAIL_IN_ACTION_SCAN_MAIL_IN_BOX,
  waypointActionID,
  itineraryID,
);

const getFollowInstructionsStep = (
  waypointActionID: string,
  itineraryID: string,
) => buildRootWaypointActionStep(
  routeNames.ITINERARY_FLOW_UNPACKED_MAIL_IN_ACTION_FOLLOW_INSTRUCTIONS,
  waypointActionID,
  itineraryID,
);

const getPackSpecimenBagsStep = (
  waypointActionID: string,
  itineraryID: string,
) => buildRootWaypointActionStep(
  routeNames.ITINERARY_FLOW_UNPACKED_MAIL_IN_ACTION_PACK_SPECIMEN_BAGS,
  waypointActionID,
  itineraryID,
);

/** AKA Packing steps */
export const getUnpackedMailInActionSteps = (
  waypointActionID: string,
  itineraryID: string,
): ItineraryStepMap => {
  const scanMailInBoxStep = getUnpackedMailInActionScanMailInBoxStep(
    waypointActionID,
    itineraryID,
  );

  const followInstructionsStep = getFollowInstructionsStep(
    waypointActionID,
    itineraryID,
  );

  const packSpecimenBagsStep = getPackSpecimenBagsStep(
    waypointActionID,
    itineraryID,
  );

  const stepMap = new Map<RouteName, ItineraryStep>([
    [scanMailInBoxStep.routeName, scanMailInBoxStep],
    [followInstructionsStep.routeName, followInstructionsStep],
    [packSpecimenBagsStep.routeName, packSpecimenBagsStep],
  ]);

  return stepMap;
};
