import { ByID } from '@caresend/types';
import { getStore } from '@caresend/ui-components';

import { RouteName, routeNames } from '@/router/model';
import { ItineraryStep, ItineraryStepMap } from '@/store/modules/itineraryFlow/model';
import { buildWaypointShipmentStep, buildWaypointStep } from '@/store/modules/itineraryFlow/steps/stepBuildingHelpers';

// PACKED MAIL-IN WAYPOINT STEPS

const getPackedMailInWaypointStopDetailsStep = (
  waypointID: string,
  itineraryID: string,
) => buildWaypointStep(
  routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_STOP_DETAILS,
  waypointID,
  itineraryID,
);

const getPackedMailInWaypointDriveToLocationStep = (
  waypointID: string,
  itineraryID: string,
) => buildWaypointStep(
  routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_DRIVE_TO_LOCATION,
  waypointID,
  itineraryID,
);

const getPackedMailInWaypointActionBlockedStep = (
  waypointID: string,
  itineraryID: string,
) => buildWaypointStep(
  routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_ACTION_BLOCKED,
  waypointID,
  itineraryID,
);

const getPackedMailInWaypointScanItemsStep = (
  waypointID: string,
  itineraryID: string,
) => buildWaypointStep(
  routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SCAN_ITEMS,
  waypointID,
  itineraryID,
);

const getPackedMailInWaypointShipmentScanBoxStep = (
  shipmentID: string,
  waypointID: string,
) => buildWaypointShipmentStep(
  routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_SCAN_BOX,
  shipmentID,
  waypointID,
);

const getPackedMailInWaypointShipmentPrintLabelStep = (
  shipmentID: string,
  waypointID: string,
) => buildWaypointShipmentStep(
  routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_PRINT_LABEL,
  shipmentID,
  waypointID,
);

const getPackedMailInWaypointShipmentScanLabelStep = (
  shipmentID: string,
  waypointID: string,
) => buildWaypointShipmentStep(
  routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_SCAN_LABEL,
  shipmentID,
  waypointID,
);

const getPackedMailInWaypointShipmentTakePhotoStep = (
  shipmentID: string,
  waypointID: string,
) => buildWaypointShipmentStep(
  routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_TAKE_PHOTO,
  shipmentID,
  waypointID,
);

const getPackedMailInWaypointShipmentDropOffStep = (
  shipmentID: string,
  waypointID: string,
) => buildWaypointShipmentStep(
  routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_DROP_OFF,
  shipmentID,
  waypointID,
);

const getPackedMailInWaypointShipmentSteps = (
  shipmentID: string,
  waypointID: string,
): ItineraryStepMap => {
  const scanBoxStep
   = getPackedMailInWaypointShipmentScanBoxStep(shipmentID, waypointID);
  const printLabelStep
   = getPackedMailInWaypointShipmentPrintLabelStep(shipmentID, waypointID);
  const scanLabelStep
   = getPackedMailInWaypointShipmentScanLabelStep(shipmentID, waypointID);
  const takePhotoStep
   = getPackedMailInWaypointShipmentTakePhotoStep(shipmentID, waypointID);
  const dropOffStep
   = getPackedMailInWaypointShipmentDropOffStep(shipmentID, waypointID);

  const stepMap = new Map<RouteName, ItineraryStep>([
    [scanBoxStep.routeName, scanBoxStep],
    [printLabelStep.routeName, printLabelStep],
    [scanLabelStep.routeName, scanLabelStep],
    [takePhotoStep.routeName, takePhotoStep],
    [dropOffStep.routeName, dropOffStep],
  ]);

  return stepMap;
};

const getPackedMailInWaypointShipmentsStep = (
  /** waypoint containing MailInAction(s) */
  waypointID: string,
  itineraryID: string,
) => {
  const shipments = getStore()
    .getters['waypoint/getWaypointMailInShipments'](waypointID);

  const shipmentSubSteps = shipments.reduce<ByID<ItineraryStepMap>>((
    currentSubSteps,
    { id },
  ) => {
    const stepMap = getPackedMailInWaypointShipmentSteps(id, waypointID);

    return {
      ...currentSubSteps,
      [id]: stepMap,
    };
  }, {});

  return buildWaypointStep(
    routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENTS,
    waypointID,
    itineraryID,
    shipmentSubSteps,
  );
};

/** AKA Mail-in steps */
export const getPackedMailInWaypointSteps = (
  /** waypoint containing MailInAction(s) */
  waypointID: string,
  itineraryID: string,
): ItineraryStepMap => {
  const stopDetails
    = getPackedMailInWaypointStopDetailsStep(waypointID, itineraryID);
  const driveToLocation
    = getPackedMailInWaypointDriveToLocationStep(waypointID, itineraryID);

  const stepMap = new Map<RouteName, ItineraryStep>();

  stepMap.set(stopDetails.routeName, stopDetails);
  stepMap.set(driveToLocation.routeName, driveToLocation);

  const blocked = !!getStore().getters['waypoint/getRouteToReadyActionInItinerary'](itineraryID);

  if (blocked) {
    const actionBlockedStep = getPackedMailInWaypointActionBlockedStep(waypointID, itineraryID);
    stepMap.set(actionBlockedStep.routeName, actionBlockedStep);
  }

  const scanItems = getPackedMailInWaypointScanItemsStep(waypointID, itineraryID);
  const shipments = getPackedMailInWaypointShipmentsStep(waypointID, itineraryID);

  stepMap.set(scanItems.routeName, scanItems);
  stepMap.set(shipments.routeName, shipments);

  return stepMap;
};
