import { ByID } from '@caresend/types';
import { addAssociatedIDs, initFlowHelpers, toastError } from '@caresend/ui-components';
import { Location, Route } from 'vue-router';

import { RouteName, routeNames } from '@/router/model';
import type { RootState } from '@/store/model';
import {
  SupplyShipmentFlowConfig,
  SupplyShipmentFlowParam,
  SupplyShipmentStep,
  SupplyShipmentStepMap,
} from '@/store/modules/supplies/model';

// STEP BUILDING HELPERS

const buildSupplyShipmentStep = (
  routeName: RouteName,
  supplyShipmentID: string,
  subSteps?: ByID<SupplyShipmentStepMap>,
): SupplyShipmentStep => addAssociatedIDs(
  {
    associatedParam: SupplyShipmentFlowParam.SUPPLY_SHIPMENT_ID,
    parentRouteName: routeNames.SUPPLY_SHIPMENT_SCAN_SUPPLY_BOX,
    routeName,
    subSteps,
  },
  supplyShipmentID,
);

// SUPPLY SHIPMENT STEPS

const buildScanSupplyBoxStep = (
  supplyShipmentID: string,
  subSteps?: ByID<SupplyShipmentStepMap>,
): SupplyShipmentStep => buildSupplyShipmentStep(
  routeNames.SUPPLY_SHIPMENT_SCAN_SUPPLY_BOX, supplyShipmentID, subSteps,
);

// ROOT STEP MAP

export const getSupplyShipmentStepMap = (
  supplyShipmentID: string | undefined,
  _rootState: RootState,
): SupplyShipmentStepMap => {
  if (!supplyShipmentID) {
    throw toastError('Missing supply shipment ID when generating steps.');
  }

  const stepMap = new Map<RouteName, SupplyShipmentStep>();

  const scanSupplyBoxStep = buildScanSupplyBoxStep(supplyShipmentID);
  stepMap.set(scanSupplyBoxStep.routeName, scanSupplyBoxStep);
  return stepMap;
};

// FLOW CONFIG

const flowConfig: SupplyShipmentFlowConfig = {
  rootParam: SupplyShipmentFlowParam.SUPPLY_SHIPMENT_ID,
};

// HELPERS

export const getSupplyShipmentFlowHelpers = (
  route: Route,
  rootState: RootState,
) => {
  const stepMap = getSupplyShipmentStepMap(route.params.supplyShipmentID, rootState);

  return initFlowHelpers(flowConfig, stepMap);
};

// ROUTER HELPERS

/**
 * Gets the route to navigate the user into the first step of the shipment flow
 * This ensures the user will always be directed to the first step.
 */
export const getSupplyShipmentRoute = (
  supplyShipmentID: string,
  rootState: RootState,
): Location | null => {
  const stepMap = getSupplyShipmentStepMap(supplyShipmentID, rootState);
  const { getFlattenedSteps } = initFlowHelpers(flowConfig, stepMap);
  const flatSteps = getFlattenedSteps();

  const [firstRouteName] = flatSteps ?? [];
  if (!firstRouteName) return null;
  return {
    name: firstRouteName.routeName,
    params: {
      supplyShipmentID,
    },
  };
};
