
import { Role } from '@caresend/types';
import Vue from 'vue';

import StatusPage from '@/components/status/StatusPage.vue';
import NurseHome from '@/views/nurse/NurseHome.vue';
import OfficePage from '@/views/office/OfficePage.vue';
import PackerHome from '@/views/packer/PackerHome/PackerHome.vue';

export default Vue.extend({
  name: 'Home',
  components: {
    NurseHome,
    OfficePage,
    PackerHome,
    StatusPage,
  },
  computed: {
    isNotApproved(): boolean {
      return this.status !== 'approved';
    },
    isNurse(): boolean {
      return this.role === Role.NURSE;
    },
    isOfficeStaff(): boolean {
      return this.role === Role.PRESCRIBER || this.role === Role.ASSISTANT;
    },
    isPacker(): boolean {
      return this.role === Role.PACKER;
    },
    role(): string | undefined {
      return this.$store.getters['auth/getUserRole'];
    },
    status(): string | undefined {
      return this.$store.getters['auth/getUserStatus'];
    },
  },
});
