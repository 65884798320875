import {
  Charting,
  ChartingItem,
  ChartingItemType,
  Procedure,
  SupplyItemStatus,
  WaypointActionStatusName,
} from '@caresend/types';
import {
  FlowCompletionAction,
  FlowCompletionActions,
  dbGroupSet,
  dbSet,
  getStore,
} from '@caresend/ui-components';
import { getProcedurePath, nullishFilter } from '@caresend/utils';
import update from 'immutability-helper';

import { updateSupplyItemStatus } from '@/functions/supplies/set';
import { routeNames } from '@/router/model';
import { CentrifugationState } from '@/store/modules/itineraryFlow/model';

const photoConfirmationCompletionAction: FlowCompletionAction = async (route) => {
  const store = getStore();
  const photo = store.state.itineraryFlow.localState.centrifugation.photo[
    route.params.processingActionID ?? ''
  ];
  if (!photo) throw Error('Missing photo confirmation');

  const { itineraryID, processingActionID } = route.params;
  if (!itineraryID) throw Error('Missing itinerary ID');
  if (!processingActionID) throw Error('Missing processing action ID');

  const procedures = store.getters[
    'waypoint/getProceduresOnWaypointAction'
  ](processingActionID, true);

  const newPartialItem: Partial<ChartingItem> = {
    files: [photo],
    title: 'Centrifuge Photo',
    type: ChartingItemType.FILES,
  };
  const updates: Record<string, Partial<Charting>> = {};
  procedures.forEach(({ charting, id: procedureID }) => {
    const currentChartingItems = charting?.items || [];
    const newChartingItems = update(currentChartingItems, {
      $push: [newPartialItem],
    });
    updates[`${getProcedurePath(procedureID, 'charting')}`] = {
      items: newChartingItems,
    };
  });

  await dbGroupSet<Partial<Charting>>(updates);
};

const packNewSpecimenBagsCompletionAction: FlowCompletionAction = async (route) => {
  const store = getStore();
  const { processingActionID } = route.params;
  if (!processingActionID) return null;

  const procedures = store.getters['waypoint/getProceduresOnWaypointAction'](
    processingActionID,
    true,
  );
  if (!procedures.length) return null;

  const centrifugationState: CentrifugationState
    = store.state.itineraryFlow.localState.centrifugation;
  const someProcedureNeedsNewSpecimenBag = procedures.some(
    (procedure) => !centrifugationState?.centrifugedProcedures[procedure.id],
  );

  if (someProcedureNeedsNewSpecimenBag) return null;

  const updateSpecimenBagsStatusToUsed = async () => {
    const specimenBagIDs = Object.values(
      store.state.itineraryFlow.localState.centrifugation.newSpecimenBagsScanned,
    ).filter(nullishFilter).map((newSpecimenBag) => newSpecimenBag.id);
    const promises = specimenBagIDs.map(async (specimenBagID) => {
      updateSupplyItemStatus(specimenBagID, SupplyItemStatus.USED);
    });
    await Promise.all(promises);
  };

  await updateSpecimenBagsStatusToUsed();

  await store.dispatch('waypoint/setProcessingActionStatus', {
    processingActionID,
    status: WaypointActionStatusName.COMPLETE,
  });
};

const procedureScanSamplesCompletionAction: FlowCompletionAction = async (route) => {
  const store = getStore();
  const { procedureID } = route.params;
  if (!procedureID) throw Error('Missing procedure ID');

  const centrifugationState = store.state.itineraryFlow.localState.centrifugation;
  const newSpecimenBag = centrifugationState.newSpecimenBagsScanned[procedureID];
  if (!newSpecimenBag) throw Error('Missing new specimen bag');

  await dbSet<Procedure['specimenBag']>(
    getProcedurePath(procedureID, 'specimenBag'),
    newSpecimenBag,
  );

  store.commit('procedures/SET_PROCEDURE_SPECIMEN_BAG', {
    procedureID,
    newSpecimenBag,
  });
  store.commit('itineraryFlow/SET_CENTRIFUGED_PROCEDURE', {
    procedureID,
    centrifuged: true,
  });
};

/**
 * If a completion action is defined, the next button will trigger that action
 * rather than being a `<router-link>` to the next step. While the async action
 * is in progress, a loader will show on the next button. If the promise
 * resolves, the user will be navigated to the next step.
 *
 * **To cancel navigation, an error must be thrown, or false must be returned.**
 * Errors thrown within these actions will be shown to the user and prevent
 * navigation to the next step.
 */
export const itineraryFlowCentrifugationCompletionActions: FlowCompletionActions = {
  [routeNames.ITINERARY_FLOW_CENTRIFUGATION_PHOTO_CONFIRMATION]: photoConfirmationCompletionAction,
  [routeNames.ITINERARY_FLOW_CENTRIFUGATION_PACK_SPECIMEN_BAGS]: packNewSpecimenBagsCompletionAction,
  [routeNames.ITINERARY_FLOW_CENTRIFUGATION_PROCEDURE_SCAN_SAMPLES]: procedureScanSamplesCompletionAction,
};
