import {
  DbRef,
  ElementTimestamp,
  Office,
  OfficeStatus,
  PlaceAccount,
} from '@caresend/types';
import { dbGroupSet, dbUpdate } from '@caresend/ui-components';

export const officeStatusToInterviewScheduled = (officeID: string, interviewUri: string) => {
  // TODO: Avoid typecast. Add interview to office type or remove this
  // functionality.
  dbUpdate<Partial<Office>>(`${DbRef.OFFICES}/${officeID}`, {
    status: OfficeStatus.INTERVIEW_SCHEDULED,
    interview: { uri: interviewUri },
  } as Partial<Office>);
};

export const addPlaceAccountToOffice = (officeID: string, account: PlaceAccount) => {
  if (!officeID || !account) return;

  const placeAccount: PlaceAccount = {
    ...account,
    isOfficeLevel: true,
  };

  const { id } = placeAccount;
  const officePlaceAccountPath = `${DbRef.OFFICES}/${officeID}/sharedPlaceAccounts/${id}`;
  const placeAccountPath = `${DbRef.PLACE_ACCOUNTS}/${id}`;

  const updates: Record<string, ElementTimestamp | PlaceAccount> = {
    [officePlaceAccountPath]: { id, timestamp: Date.now() },
    [placeAccountPath]: placeAccount,
  };

  return dbGroupSet<ElementTimestamp | PlaceAccount>(updates);
};

export const deleteOfficePlaceAccount = (officeID: string, placeAccount: PlaceAccount) => {
  if (!officeID || !placeAccount) return;

  const { id } = placeAccount;
  const officePlaceAccountPath = `${DbRef.OFFICES}/${officeID}/sharedPlaceAccounts/${id}`;

  const updates: Record<string, null> = {
    [officePlaceAccountPath]: null,
  };

  return dbGroupSet<null>(updates);
};
