import { FeatureFlag, Shift } from '@caresend/types';
import { HeadingValues } from '@caresend/ui-components';
import { formatTimeZoneDateSimple } from '@caresend/utils';
import { computed } from 'vue';
import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';

const routesShift: RouteConfig[] = [
  {
    path: '/:partnerName?/shift/:shiftID',
    name: routeNames.SHIFT,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-shift' */
      '@/components/shifts/Shift/Shift.vue'
    ),
    meta: {
      authRequired: true,
      heading: ({ store, route }) => {
        const shift = computed<Shift | undefined>(() =>
          store?.getters['shifts/getShiftByID'](route?.params.shiftID),
        );
        const date = computed<string | undefined>(() =>
          shift.value?.startTime.timestamp
            ? formatTimeZoneDateSimple(shift.value?.startTime, 'MMM D')
            : undefined,
        );
        return computed(() => ({
          title: date.value ? `Shift for ${date.value}` : 'Shift',
          backLocation: {
            path: '/',
          },
        }));
      },
    },
  }, {
    path: '/:partnerName?/shift/:shiftID/area-check',
    name: routeNames.SHIFT_AREA_CHECK,
    props: true,
    component: () => import(
    /* webpackChunkName: 'chunk-shift' */
      '@/components/shifts/ShiftAreaCheck/ShiftAreaCheck.vue'
    ),
    meta: {
      authRequired: true,
      heading: () => computed(() => ({
        title: 'Area check-in',
      })),
    },
  },
  {
    path: '/:partnerName?/shift/:shiftID/check-in',
    name: routeNames.CHECK_IN,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-shift' */
      '@/components/shifts/CheckIn/CheckIn.vue'
    ),
    meta: {
      authRequired: true,
      heading: () => computed(() => ({
        title: 'Shift check in',
        backLocation: {
          name: routeNames.SHIFT,
        },
      })),
    },
  },
  {
    path: '/:partnerName?/shift/:shiftID/leave-from',
    name: routeNames.LEAVE_FROM,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-shift' */
      '@/components/shifts/LeaveFrom/LeaveFrom.vue'
    ),
    meta: {
      authRequired: true,
      heading: ({ store }) => computed<HeadingValues>(() => {
        /**
         * We want to skip the scanning stage during check in if supply
         * transfers are not disabled
         */
        const name = store.getters['variables/getFeatureFlagByName'](
          FeatureFlag.SUPPLY_TRANSFERS_DISABLED,
        ) ? routeNames.CHECK_IN : routeNames.SHIFT;
        return {
          title: 'Shift check in',
          backLocation: {
            name,
          },
        };
      }),
    },
  },
  {
    path: '/:partnerName?/shift/:shiftID/scan-shift-bag',
    name: routeNames.SCAN_SHIFT_BAG,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-shift' */
      '@/components/shifts/ScanShiftBag/ScanShiftBag.vue'
    ),
    meta: {
      authRequired: true,
      heading: () => computed(() => ({
        title: 'Scan the CareSend bag',
        backLocation: {
          name: routeNames.CHECK_IN,
        },
      })),
      whiteBackground: true,
    },
  },
  {
    path: '/:partnerName?/shift-offer/:shiftID',
    name: routeNames.SHIFT_OFFER,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-shift' */
      '@/views/nurse/shifts/ShiftOfferPage/ShiftOfferPage.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: ({ store, prevRoute, route }) => {
        const { shiftID } = route.params;
        const title = shiftID
          ? store.getters['shifts/getShiftOfferTitleByID'](shiftID)
          : '';
        return computed(() => ({
          title,
          backLocation: prevRoute?.fullPath,
        }));
      },
    },
  },
  {
    path: '/:partnerName?/shift/:shiftID/scan-shift-supplies',
    name: routeNames.SCAN_SHIFT_SUPPLIES,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-shift' */
      '@/components/shifts/ScanShiftSupplies/ScanShiftSupplies.vue'
    ),
    meta: {
      authRequired: true,
      heading: () => computed(() => ({
        title: 'Prepare supplies',
        backLocation: {
          name: routeNames.SCAN_SHIFT_BAG,
        },
      })),
      whiteBackground: true,
    },
  },
];

export { routesShift };
