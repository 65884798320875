
import Vue from 'vue';

import PageWithButton from '@/components/layout/pages/PageWithButton.vue';

export default Vue.extend({
  components: {
    'page-with-button': PageWithButton,
  },
});
