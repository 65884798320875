
import Vue from 'vue';

import PageWithButton from '@/components/layout/pages/PageWithButton.vue';

export default Vue.extend({
  name: 'RequireApproval',
  components: {
    'page-with-button': PageWithButton,
  },
  props: {},
  data() {
    return {};
  },
});
