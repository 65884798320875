import { ByID } from '@caresend/types';
import { addAssociatedIDs, initFlowHelpers, toastError } from '@caresend/ui-components';
import { arrayToObj, objectMap } from '@caresend/utils';
import { Location, Route } from 'vue-router';

import { getSupplyTransferProcedureIDs } from '@/functions/supplies/get';
import { RouteName, routeNames } from '@/router/model';
import type { RootState } from '@/store/model';
import { procedureFlowTypeIsLabDraw } from '@/store/modules/itineraryFlow/helpers';
import {
  SupplyTransferFlowConfig,
  SupplyTransferFlowParam,
  SupplyTransferStep,
  SupplyTransferStepMap,
} from '@/store/modules/supplies/model';

// STEP BUILDING HELPERS

const buildSupplyTransferStep = (
  routeName: RouteName,
  supplyTransferID: string,
  subSteps?: ByID<SupplyTransferStepMap>,
): SupplyTransferStep => addAssociatedIDs(
  {
    associatedParam: SupplyTransferFlowParam.SUPPLY_TRANSFER_ID,
    routeName,
    subSteps,
  },
  supplyTransferID,
);

const buildProcedureStep = (
  routeName: RouteName,
  procedureID: string,
  supplyTransferID: string,
  subSteps?: ByID<SupplyTransferStepMap>,
): SupplyTransferStep => addAssociatedIDs(
  {
    associatedParam: SupplyTransferFlowParam.PROCEDURE_ID,
    parentAssociatedParam: SupplyTransferFlowParam.SUPPLY_TRANSFER_ID,
    parentRouteName: routeNames.SUPPLY_TRANSFER_PROCEDURES,
    routeName,
    subSteps,
  },
  procedureID,
  supplyTransferID,
);

const buildSupplyShipmentStep = (
  routeName: RouteName,
  supplyShipmentID: string,
  supplyTransferID: string,
  subSteps?: ByID<SupplyTransferStepMap>,
): SupplyTransferStep => addAssociatedIDs(
  {
    associatedParam: SupplyTransferFlowParam.SUPPLY_SHIPMENT_ID,
    routeName,
    subSteps,
  },
  supplyShipmentID,
  supplyTransferID,
);

// PROCEDURE STEPS

const getScanProcedureKitStep = (
  procedureID: string,
  supplyTransferID: string,
): SupplyTransferStep =>
  buildProcedureStep(
    routeNames.SUPPLY_TRANSFER_SCAN_PROCEDURE_KIT,
    procedureID,
    supplyTransferID,
  );

const getScanProcedureSuppliesStep = (
  procedureID: string,
  supplyTransferID: string,
): SupplyTransferStep =>
  buildProcedureStep(
    routeNames.SUPPLY_TRANSFER_SCAN_PROCEDURE_SUPPLIES,
    procedureID,
    supplyTransferID,
  );

const getPrintRequisitionFormStep = (
  procedureID: string,
  supplyTransferID: string,
): SupplyTransferStep =>
  buildProcedureStep(
    routeNames.SUPPLY_TRANSFER_PRINT_REQUISITION_FORM,
    procedureID,
    supplyTransferID,
  );

const getPrintInstructionSheetStep = (
  procedureID: string,
  supplyTransferID: string,
): SupplyTransferStep =>
  buildProcedureStep(
    routeNames.SUPPLY_TRANSFER_PRINT_INSTRUCTION_SHEET,
    procedureID,
    supplyTransferID,
  );

const getScanInstructionSheetStep = (
  procedureID: string,
  supplyTransferID: string,
): SupplyTransferStep =>
  buildProcedureStep(
    routeNames.SUPPLY_TRANSFER_SCAN_INSTRUCTION_SHEET,
    procedureID,
    supplyTransferID,
  );

const getScanRequisitionFormStep = (
  procedureID: string,
  supplyTransferID: string,
): SupplyTransferStep =>
  buildProcedureStep(
    routeNames.SUPPLY_TRANSFER_SCAN_REQUISITION_FORM,
    procedureID,
    supplyTransferID,
  );

const getCloseProcedureKitStep = (
  procedureID: string,
  supplyTransferID: string,
): SupplyTransferStep =>
  buildProcedureStep(
    routeNames.SUPPLY_TRANSFER_CLOSE_PROCEDURE_KIT,
    procedureID,
    supplyTransferID,
  );

// SUPPLY SHIPMENT STEPS

const getScanSupplyBoxStep = (
  supplyShipmentID: string,
  supplyTransferID: string,
): SupplyTransferStep =>
  buildSupplyShipmentStep(
    routeNames.SUPPLY_TRANSFER_SCAN_SUPPLY_BOX,
    supplyShipmentID,
    supplyTransferID,
  );

const getScanKitsToSupplyBoxStep = (
  supplyShipmentID: string,
  supplyTransferID: string,
): SupplyTransferStep =>
  buildSupplyShipmentStep(
    routeNames.SUPPLY_TRANSFER_SCAN_KITS_TO_SUPPLY_BOX,
    supplyShipmentID,
    supplyTransferID,
  );

const getPrintShippingLabelStep = (
  supplyShipmentID: string,
  supplyTransferID: string,
): SupplyTransferStep =>
  buildSupplyShipmentStep(
    routeNames.SUPPLY_TRANSFER_PRINT_SHIPPING_LABEL,
    supplyShipmentID,
    supplyTransferID,
  );

const getScanShippingLabelStep = (
  supplyShipmentID: string,
  supplyTransferID: string,
): SupplyTransferStep =>
  buildSupplyShipmentStep(
    routeNames.SUPPLY_TRANSFER_SCAN_SHIPPING_LABEL,
    supplyShipmentID,
    supplyTransferID,
  );

const getSupplyBoxPhotoStep = (
  supplyShipmentID: string,
  supplyTransferID: string,
): SupplyTransferStep =>
  buildSupplyShipmentStep(
    routeNames.SUPPLY_TRANSFER_SUPPLY_BOX_PHOTO,
    supplyShipmentID,
    supplyTransferID,
  );

const getDonePackingStep = (
  supplyShipmentID: string,
  supplyTransferID: string,
): SupplyTransferStep =>
  buildSupplyShipmentStep(
    routeNames.SUPPLY_TRANSFER_DONE_PACKING,
    supplyShipmentID,
    supplyTransferID,
  );

// SUPPLY TRANSFER STEPS

export const getProceduresStep = (
  supplyTransferID: string,
  rootState: RootState,
): SupplyTransferStep => {
  const procedureIDsForFlow = getSupplyTransferProcedureIDs(supplyTransferID);
  /** Example: { [procedureID]: procedureID } */
  const procedureIdObj = arrayToObj(procedureIDsForFlow, (procedureID) => procedureID);

  const subSteps = objectMap(
    procedureIdObj,
    (_bool, procedureID) => {
      const stepMap = new Map<RouteName, SupplyTransferStep>();

      /** PCR, Blood draw and Urine collection are of type `labDraw`. */
      const isLabFlow = procedureFlowTypeIsLabDraw(procedureID, rootState);

      if (!isLabFlow) {
        toastError(`Procedure ${procedureID} is not a lab flow. It will be ignored.`);
        return stepMap;
      }

      const scanProcedureKitStep = getScanProcedureKitStep(procedureID, supplyTransferID);
      const scanProcedureSuppliesStep = getScanProcedureSuppliesStep(procedureID, supplyTransferID);
      const printRequisitionFormStep = getPrintRequisitionFormStep(procedureID, supplyTransferID);
      const scanRequisitionFormStep = getScanRequisitionFormStep(procedureID, supplyTransferID);
      const printInstructionSheetStep = getPrintInstructionSheetStep(procedureID, supplyTransferID);
      const scanInstructionSheetStep = getScanInstructionSheetStep(procedureID, supplyTransferID);
      const closeProcedureKitStep = getCloseProcedureKitStep(procedureID, supplyTransferID);

      stepMap.set(scanProcedureKitStep.routeName, scanProcedureKitStep);
      stepMap.set(scanProcedureSuppliesStep.routeName, scanProcedureSuppliesStep);
      stepMap.set(printRequisitionFormStep.routeName, printRequisitionFormStep);
      stepMap.set(scanRequisitionFormStep.routeName, scanRequisitionFormStep);
      stepMap.set(printInstructionSheetStep.routeName, printInstructionSheetStep);
      stepMap.set(scanInstructionSheetStep.routeName, scanInstructionSheetStep);
      stepMap.set(closeProcedureKitStep.routeName, closeProcedureKitStep);

      return stepMap;
    },
  );

  return buildSupplyTransferStep(
    routeNames.SUPPLY_TRANSFER_PROCEDURES,
    supplyTransferID,
    subSteps,
  );
};

// ROOT STEP MAP

export const getSupplyTransferStepMap = (
  supplyTransferID: string | undefined,
  rootState: RootState,
): SupplyTransferStepMap => {
  if (!supplyTransferID) {
    throw toastError('Missing supply transfer ID when generating steps.');
  }
  // The step building logic throws errors in case of missing data. Return
  // empty steps during loading.
  const isLoading = !rootState.supplies.packingFlow
    .supplyTransferFlowLoadingCompleted[supplyTransferID];
  if (isLoading) { return new Map(); }

  const supplyTransfer = rootState.supplies.supplyTransfers[supplyTransferID];
  const firstSupplyShipmentID = Object.keys(supplyTransfer?.shipmentIDs ?? {})[0];

  const proceduresStep = getProceduresStep(supplyTransferID, rootState);

  const stepMap = new Map<RouteName, SupplyTransferStep>([
    [proceduresStep.routeName, proceduresStep],
  ]);

  // Note: At this time we are only supporting a single supply shipment
  // per supply transfer in the packing flow. After all procedures are completed,
  // the user will be directed to the steps for the first supply shipment.
  if (firstSupplyShipmentID) {
    const scanSupplyBoxStep = getScanSupplyBoxStep(firstSupplyShipmentID, supplyTransferID);
    const scanKitsToSupplyBoxStep = getScanKitsToSupplyBoxStep(firstSupplyShipmentID, supplyTransferID);
    const printShippingLabelStep = getPrintShippingLabelStep(firstSupplyShipmentID, supplyTransferID);
    const scanShippingLabelStep = getScanShippingLabelStep(firstSupplyShipmentID, supplyTransferID);
    const supplyBoxPhotoStep = getSupplyBoxPhotoStep(firstSupplyShipmentID, supplyTransferID);
    const donePackingStep = getDonePackingStep(firstSupplyShipmentID, supplyTransferID);

    stepMap.set(scanSupplyBoxStep.routeName, scanSupplyBoxStep);
    stepMap.set(scanKitsToSupplyBoxStep.routeName, scanKitsToSupplyBoxStep);
    stepMap.set(printShippingLabelStep.routeName, printShippingLabelStep);
    stepMap.set(scanShippingLabelStep.routeName, scanShippingLabelStep);
    stepMap.set(supplyBoxPhotoStep.routeName, supplyBoxPhotoStep);
    stepMap.set(donePackingStep.routeName, donePackingStep);
  }

  return stepMap;
};

// FLOW CONFIG

const flowConfig: SupplyTransferFlowConfig = {
  rootParam: SupplyTransferFlowParam.SUPPLY_TRANSFER_ID,
};

// HELPERS

export const getSupplyTransferFlowHelpers = (
  route: Route,
  rootState: RootState,
) => {
  const stepMap = getSupplyTransferStepMap(route.params.supplyTransferID, rootState);

  return initFlowHelpers(flowConfig, stepMap);
};

// ROUTER HELPERS

/**
 * Gets the route to navigate the user into the first substep of the procedure
 * flow. This ensures the user will always be directed to the first step, even
 * if the procedure flow steps change in the future.
 */
export const getSupplyTransferProcedureRoute = (
  supplyTransferID: string,
  procedureID: string,
  rootState: RootState,
): Location | null => {
  const stepMap = getSupplyTransferStepMap(supplyTransferID, rootState);
  const { getStepByRouteNameAndAssociatedID } = initFlowHelpers(flowConfig, stepMap);
  const proceduresStep = getStepByRouteNameAndAssociatedID(
    routeNames.SUPPLY_TRANSFER_PROCEDURES,
    supplyTransferID,
  );
  const subStepRouteNames = proceduresStep?.subSteps?.[procedureID]?.keys();
  const [firstRouteName] = subStepRouteNames ?? [];
  if (!firstRouteName) return null;
  return {
    name: firstRouteName,
    params: {
      procedureID,
    },
  };
};
