
import { ByName, DynamicCopy } from '@caresend/types';
import { DynamicCopyRender, Loading, getStore } from '@caresend/ui-components';
import { computed, defineComponent, onMounted, ref } from 'vue';

import EmptyList from '@/components/shifts/EmptyList.vue';
import ShiftOffersByDate from '@/components/shifts/ShiftOffersByDate.vue';
import { ShiftOfferData } from '@/store/modules/shifts/model';

export default defineComponent({
  name: 'ShiftOffers',
  components: {
    DynamicCopyRender,
    EmptyList,
    Loading,
    ShiftOffersByDate,
  },
  setup() {
    const store = getStore();
    const isLoading = ref(true);

    onMounted(async () => {
      await store.dispatch('shifts/loadOfferedShifts');
      isLoading.value = false;
    });

    const shiftOffersByDate = computed<ByName<ShiftOfferData[]>>(() =>
      store.getters['shifts/getShiftOfferDataByDate'],
    );

    const isListEmpty = computed<boolean>(() =>
      !Object.values(shiftOffersByDate.value).length,
    );

    const dynamicCopy = computed<DynamicCopy | undefined>(() =>
      store.state.variables.variables?.dynamicCopy?.nurseApp?.shiftOffers?.emptyState,
    );

    return {
      dynamicCopy,
      isListEmpty,
      isLoading,
      shiftOffersByDate,
    };
  },
});
