import { ChartingFlowModule, ExtendedCustomModule, initChartingFlowModule } from '@caresend/ui-components';

import { officeOrdersRoute } from '@/router/locations';
import type { RootState } from '@/store/model';
import { ExtendedOfficesActions, ExtendedOfficesMutations } from '@/store/modules/offices';
import { ExtendedVariablesGetters } from '@/store/modules/variables';

const extraChartingFlowGetters = {
  'chartingFlow/getExitRoute': () => () => officeOrdersRoute(),
};

const chartingFlowModuleExtension = {
  getters: extraChartingFlowGetters,
};

export const chartingFlowModule: ExtendedCustomModule<
  ChartingFlowModule<
    RootState,
    ExtendedOfficesMutations,
    ExtendedOfficesActions,
    ExtendedVariablesGetters
  >,
  typeof chartingFlowModuleExtension
> = initChartingFlowModule(chartingFlowModuleExtension);

export type ExtendedChartingFlowModule = typeof chartingFlowModule;

export type ExtendedChartingFlowGetters = ExtendedChartingFlowModule['getters'];
