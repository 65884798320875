import {
  augmentStore,
  initAuthWatches,
  initFirebaseAuthWatch,
  initOrderFlowWatches,
  isProd,
} from '@caresend/ui-components';
import update from 'immutability-helper';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import VuexPersistence from 'vuex-persist';

import { handleAuthInitializedWithoutUser } from '@/database/firebase/auth';
import type { CustomModules, CustomStore, RootState } from '@/store/model';
import { appModule } from '@/store/modules/app';
import { authModule, handleUserBound, handleUserObjectUpdated, handleUserUnbound } from '@/store/modules/auth';
import { bookingModule } from '@/store/modules/booking';
import { bookingsModule } from '@/store/modules/bookings';
import { chartingFlowModule } from '@/store/modules/chartingFlow';
import { insuranceModule } from '@/store/modules/insurance';
import { itineraryFlowModule } from '@/store/modules/itineraryFlow';
import { officeModule } from '@/store/modules/office';
import { officesModule } from '@/store/modules/offices';
import { orderFlowModule } from '@/store/modules/orderFlow';
import { ordersModule } from '@/store/modules/orders';
import { placesModule } from '@/store/modules/places';
import { proceduresModule } from '@/store/modules/procedures';
import { schedulingFlowModule } from '@/store/modules/schedulingFlow';
import { settingsModule } from '@/store/modules/settings';
import { shiftsModule } from '@/store/modules/shifts';
import { suppliesModule } from '@/store/modules/supplies';
import { transactionsModule } from '@/store/modules/transactions';
import { usersModule } from '@/store/modules/users';
import { variablesModule } from '@/store/modules/variables';
import { waypointModule } from '@/store/modules/waypoint';

Vue.use(Vuex);

const strict = !isProd;

const vuexPersistence = new VuexPersistence<RootState>({
  reducer: (state) => ({
    auth: {
      authStatus: state.auth.authStatus,
      user: state.auth.user,
    },
    booking: {
      bookingsInput: state.booking.bookingsInput,
    },
    places: update(
      state.places,
      { $unset: ['userAssociatedPlaceID', 'bindCount'] },
    ),
    users: {
      staffUsers: state.users.staffUsers,
    },
    itineraryFlow: {
      localState: {
        centrifugation: state.itineraryFlow.localState.centrifugation,
      },
      proceduresInput: state.itineraryFlow.proceduresInput,
      waypointActionsInput: state.itineraryFlow.waypointActionsInput,
    },
    settings: {
      ...state.settings,
      calendar: undefined,
    },
    variables: {
      featureFlagOverrides: state.variables.featureFlagOverrides,
    },
  }),
  strictMode: strict,
});

const modules: CustomModules = {
  app: appModule,
  booking: bookingModule,
  auth: authModule,
  bookings: bookingsModule,
  chartingFlow: chartingFlowModule,
  insurance: insuranceModule,
  itineraryFlow: itineraryFlowModule,
  office: officeModule,
  offices: officesModule,
  orderFlow: orderFlowModule,
  orders: ordersModule,
  places: placesModule,
  procedures: proceduresModule,
  schedulingFlow: schedulingFlowModule,
  settings: settingsModule,
  shifts: shiftsModule,
  supplies: suppliesModule,
  transactions: transactionsModule,
  users: usersModule,
  variables: variablesModule,
  waypoint: waypointModule,
};

const storeOptions: StoreOptions<RootState> = {
  modules,
  mutations: {
    RESTORE_MUTATION: vuexPersistence.RESTORE_MUTATION,
  },
  plugins: [vuexPersistence.plugin],
  strict,
};

/**
 * Rather than exporting the store, use `getStore()` to access the instance.
 */
const store: CustomStore = new Vuex.Store(storeOptions);

/**
 * `augmentStore` applies shared store logic that is not app-specific. It must
 * be called prior to using `getStore`.
 */
export const initStore = () => {
  augmentStore(store);

  initAuthWatches({
    handleUserBound,
    handleUserObjectUpdated,
    handleUserUnbound,
  });
  initFirebaseAuthWatch({ handleAuthInitializedWithoutUser });
  initOrderFlowWatches(store);
};
