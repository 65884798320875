
import Vue from 'vue';
import Nl2br from 'vue-nl2br';

export default Vue.extend({
  name: 'PageWithButton',
  components: {
    Nl2br,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    img: {
      type: String,
      required: false,
      default: '',
    },
    titleDescription: {
      type: String,
      required: false,
      default: '',
    },
    textDescription: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {};
  },
});
