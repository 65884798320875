
import Vue, { PropType } from 'vue';

import Notes from '@/components/layout/rows/Notes.vue';

export default Vue.extend({
  name: 'ErrorNote',
  components: {
    Notes,
  },
  props: {
    error: {
      type: Object as PropType<Error | null>,
      default: null,
    },
    entityName: {
      type: String as PropType<string | null>,
      default: null,
    },
    messageOverride: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  computed: {
    message(): string {
      if (this.messageOverride !== null) {
        return this.messageOverride;
      }
      if (this.entityName !== null) {
        return `There was a problem loading data for this ${this.entityName}.`;
      }
      return 'There was a problem while loading data.';
    },
    errorMessage(): string | null {
      return this.error?.message ?? null;
    },
  },
});
