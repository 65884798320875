import { EncryptedUser } from '@caresend/types';
import {
  ExtendedCustomModule,
  UsersModule,
  UsersState,
  initUsersModule,
  toastErrorAndReport,
  toastSuccess,
  updateUserInfoRequest,
} from '@caresend/ui-components';
import { capitalize } from '@caresend/utils';

import type { CustomActionContext } from '@/store/model';

type S = UsersState;

type ExtraUsersActionContext = CustomActionContext<'users', S>

export type ExtraUsersActions = {
  'users/updateUserInfo': (
    context: ExtraUsersActionContext,
    payload: {
      updatedUser: EncryptedUser;
    }
  ) => Promise<void>;
}

const extraUsersActions: ExtraUsersActions = {
  'users/updateUserInfo': async ({ dispatch }, { updatedUser }) => {
    try {
      await dispatch('insurance/createDraftInsuranceProviders');
      await updateUserInfoRequest({ updatedUser });
      toastSuccess(`${capitalize(updatedUser.role)} updated`);
    } catch (error) {
      toastErrorAndReport(error);
    }
  },
};

export const usersModuleExtension = {
  actions: extraUsersActions,
};

export const usersModule: ExtendedCustomModule<
  UsersModule,
  typeof usersModuleExtension
> = initUsersModule(usersModuleExtension);

export type ExtendedUsersModule = typeof usersModule;

export type ExtendedUsersMutations = ExtendedUsersModule['mutations'];
export type ExtendedUsersActions = ExtendedUsersModule['actions'];
export type ExtendedUsersGetters = ExtendedUsersModule['getters'];
