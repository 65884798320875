import { computed } from 'vue';
import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';

const routesMySupplies: RouteConfig[] = [
  {
    path: '/:partnerName?/my-supplies',
    name: routeNames.MY_SUPPLIES,
    props: true,
    component: () => import(
    /* webpackChunkName: 'chunk-shift' */
      '@/views/nurse/supplies/MySupplies/MySupplies.vue'
    ),
    meta: {
      authRequired: true,
      heading: () => computed(() => ({
        title: 'Your supplies',
        backLocation: {
          path: '/',
        },
      })),
    },
  },
  {
    path: '/:partnerName?/my-supplies/:fulfillmentID/activate',
    name: routeNames.ADD_SUPPLIES,
    props: true,
    component: () => import(
    /* webpackChunkName: 'chunk-shift' */
      '@/views/nurse/supplies/AddSupplies/AddSupplies.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: () => computed(() => ({
        title: 'Scan barcode of supplies to add',
        backLocation: {
          name: routeNames.MY_SUPPLIES,
        },
      })),
    },
  },
];

export { routesMySupplies };
