
// TODO: bring into compliance with vue/require-default-prop
/* eslint-disable vue/require-default-prop */

import { TextInput } from '@caresend/ui-components';
import Vue from 'vue';

import { checkEmailAlreadyInUse } from '@/database/firebase/auth';
import { emailIsValid } from '@/functions/methods';

export default Vue.extend({
  name: 'EmailInput',
  components: { TextInput },
  props: {
    autocomplete: {
      type: String,
      default: 'email',
    },
    value: String,
    placeholder: String,
    disabled: { type: Boolean, required: false },
    hideMessage: { type: Boolean, default: false },
    invalidEmails: {
      type: Array,
      default: () => [],
    },
    hideIcon: { type: Boolean, default: false },
    userCanExist: { type: Boolean, default: false },
    label: { type: String, default: 'E-mail' },
  },
  data() {
    return { isValid: false, isLoading: false, message: 'This email is invalid' };
  },
  watch: {
    value: {
      immediate: true,
      handler(email) {
        if (this.disabled) {
          this.isValid = true;
          this.message = 'This email is valid';
          this.$emit('setIsEmailValid', true);
        } else if (emailIsValid(email)) {
          this.checkEmailValid();
        } else {
          this.message = 'This email is invalid';
          this.isValid = false;
          this.$emit('setIsEmailValid', false);
        }
      },
    },
  },
  methods: {
    async checkEmailValid() {
      this.isLoading = true;
      const locallyInUse = this.invalidEmails.includes(this.value);
      if (this.userCanExist) {
        this.isValid = !locallyInUse;
      } else {
        try {
          const { email, isInUse } = await checkEmailAlreadyInUse(this.value);
          if (this.value === email) this.isValid = !isInUse && !locallyInUse;
        } catch (error) {
          this.isValid = false;
        }
      }
      this.message = this.isValid ? 'This email is valid' : 'This email is already in use';
      this.$emit('setIsEmailValid', this.isValid);
      this.isLoading = false;
    },
  },
});
