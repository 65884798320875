import { FlowStepValidators } from '@caresend/ui-components';

import { itineraryFlowCentrifugationStepValidators } from '@/store/modules/itineraryFlow/stepValidation/centrifugation';
import { itineraryFlowMailInStepValidators } from '@/store/modules/itineraryFlow/stepValidation/mailIn';
import { itineraryFlowPackingStepValidators } from '@/store/modules/itineraryFlow/stepValidation/packing';

/**
 * Validators to determine when the `Next` button should be enabled on
 * itinerary flow steps.
 */
export const itineraryFlowStepValidators: FlowStepValidators = {
  ...itineraryFlowCentrifugationStepValidators,
  ...itineraryFlowMailInStepValidators,
  ...itineraryFlowPackingStepValidators,
};
