import { render, staticRenderFns } from "./ShiftFilters.vue?vue&type=template&id=7838729c&scoped=true&"
import script from "./ShiftFilters.vue?vue&type=script&lang=ts&"
export * from "./ShiftFilters.vue?vue&type=script&lang=ts&"
import style0 from "./ShiftFilters.vue?vue&type=style&index=0&id=7838729c&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7838729c",
  null
  
)

export default component.exports