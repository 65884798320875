
import {
  BasicCard,
  ButtonComponent,
  CenteredPage,
  Copy,
  Skeleton,
  getStore,
} from '@caresend/ui-components';
import { getPluralCount, getUnknownErrorMessage } from '@caresend/utils';
import {
  VueConstructor,
  computed,
  defineComponent,
  ref,
  watch,
} from 'vue';
import { Location } from 'vue-router';

import Box from '@/assets/icons/box.svg';
import Building from '@/assets/icons/building.svg';
import Truck from '@/assets/icons/truck.svg';
import { addSuppliesToPlaceRoute, packingRoute, shippingRoute } from '@/router/locations';

export interface PackerMenuItem {
  buttonLabel: string;
  heading: string;
  icon: VueConstructor<Box>;
  isLoading?: boolean;
  subHeading?: string;
  to: Location;
}

export default defineComponent({
  name: 'PackerHome',
  components: {
    BasicCard,
    ButtonComponent,
    CenteredPage,
    Copy,
    Skeleton,
  },
  setup() {
    const store = getStore();

    const placeAssociationIsLoading = computed<boolean>(() =>
      store.state.places.userAssociatedPlaceID === null,
    );
    const placeAssociationFailed = computed<boolean>(() =>
      store.state.places.userAssociatedPlaceID === false,
    );

    // Rather than displaying a loader, reserve space for the shipping items
    // count with an nbsp character to prevent layout shift upon loading.
    const packingItems = ref<string>(' ');
    const shipingItems = ref<string>(' ');
    const isLoadingSupplyTransfer = ref<boolean>(true);
    const isLoadingSupplyShipment = ref<boolean>(true);

    const fetchSupplyTransfers = async () => {
      try {
        const results = await store.dispatch('supplies/searchPackerSupplyTransfers');
        isLoadingSupplyTransfer.value = false;
        packingItems.value = `${getPluralCount(results.length, 'item')}`;
      } catch (error) {
        isLoadingSupplyTransfer.value = false;
        packingItems.value = getUnknownErrorMessage(error);
      }
    };
    const fetchSupplyShipments = async () => {
      try {
        const results = await store.dispatch('supplies/searchPackerSupplyShipments');
        isLoadingSupplyShipment.value = false;
        shipingItems.value = `${getPluralCount(results.length, 'item')}`;
      } catch (error) {
        isLoadingSupplyShipment.value = false;
        shipingItems.value = getUnknownErrorMessage(error);
      }
    };
    const fetchData = () => {
      fetchSupplyTransfers();
      fetchSupplyShipments();
    };

    watch(
      () => placeAssociationIsLoading.value,
      (placeLoading) => {
        if (!placeLoading) fetchData();
      },
      { immediate: true },
    );

    const items = computed<PackerMenuItem[]>(() => ([
      {
        buttonLabel: 'Details',
        heading: 'Ship',
        icon: Truck,
        isLoading: isLoadingSupplyShipment.value,
        subHeading: shipingItems.value,
        to: shippingRoute(),
      },
      {
        buttonLabel: 'Details',
        heading: 'Pick & pack',
        icon: Box,
        isLoading: isLoadingSupplyTransfer.value,
        subHeading: packingItems.value,
        to: packingRoute(),
      },
      {
        buttonLabel: 'Activate',
        heading: 'Location supplies',
        icon: Building,
        to: addSuppliesToPlaceRoute(),
      },
    ]));

    return {
      items,
      placeAssociationFailed,
    };
  },
});
