import {
  AppModule,
  AppState,
  ConsumingApp,
  ExtendedCustomModule,
  initAppModule,
  isProd,
} from '@caresend/ui-components';
import { isNullish } from '@caresend/utils';

import type { CustomActionContext, RootState } from '@/store/model';
import { ExtendedVariablesActions } from '@/store/modules/variables';

export enum GeolocationPermissionStatus {
  ALLOWED = 'allowed',
  ERROR = 'error',
  PERMISSION_DENIED = 'permissionDenied',
}

interface ExtraAppState {
  geolocationPermissionStatus: GeolocationPermissionStatus | undefined;
  navBarVisible: boolean;
  qaOverrides?: {
    location: {
      lat: number;
      lng: number;
    };
  };
}

const extraAppState: ExtraAppState = {
  geolocationPermissionStatus: undefined,
  navBarVisible: true,
};

type S = AppState & ExtraAppState;

const extraAppMutations = {
  'app/SET_GEOLOCATION_PERMISSION_STATUS': (
    state: S,
    geolocationPermissionStatus: GeolocationPermissionStatus,
  ) => {
    state.geolocationPermissionStatus = geolocationPermissionStatus;
  },

  'app/SET_NAV_BAR_VISIBLE': (state: S, navBarVisible: boolean) => {
    state.navBarVisible = navBarVisible;
  },

  'app/SET_QA_OVERRIDES': (
    state: S,
    qaOverrides: ExtraAppState['qaOverrides'] | null,
  ) => {
    if (isProd && !isNullish(qaOverrides)) {
      console.error('QA overrides are not allowed in production');
    } else if (isNullish(qaOverrides)) {
      state.qaOverrides = undefined;
      console.info('QA overrides disabled');
    } else {
      state.qaOverrides = qaOverrides;
      console.info('Using QA overrides', qaOverrides);
    }
  },
};

type ExtraAppActionContext = CustomActionContext<'app', S>

export type ExtraAppActions = {
  'app/resetStore': (
    context: ExtraAppActionContext,
  ) => void;
}

const extraAppActions: ExtraAppActions = {
  'app/resetStore': ({ commit }) => {
    commit('office/RESET_OFFICE');
    commit('orderFlow/RESET_MODULE');
    commit('places/RESET_PLACES');
    commit('places/RESET_PLACE_GROUPS');
    commit('places/RESET_PLACE_GROUP_TYPES');
    commit('places/RESET_PLACE_ACCOUNTS');
    commit('users/RESET_USERS');
    commit('users/RESET_STAFF_USERS');
    commit('waypoint/RESET_ITINERARIES');
    commit('waypoint/RESET_WAYPOINTS');
    commit('waypoint/RESET_WAYPOINT_ACTIONS');
    commit('shifts/RESET_SHIFTS');
    commit('waypoint/RESET_NURSE_ITINERARIES');
  },
};

const appModuleExtension = {
  actions: extraAppActions,
  mutations: extraAppMutations,
  state: extraAppState,
};

export const appModule: ExtendedCustomModule<
  AppModule<RootState, ExtendedVariablesActions>,
  typeof appModuleExtension
> = initAppModule(ConsumingApp.PRO, appModuleExtension);

export type ExtendedAppModule = typeof appModule;

export type ExtendedAppMutations = ExtendedAppModule['mutations'];
export type ExtendedAppActions = ExtendedAppModule['actions'];
export type ExtendedAppGetters = ExtendedAppModule['getters'];
