
import { PropType, defineComponent } from 'vue';

import ShiftOfferCard from '@/components/shifts/ShiftOfferCard/ShiftOfferCard.vue';
import { ShiftOfferData } from '@/store/modules/shifts/model';

export default defineComponent({
  name: 'ShiftOffersByDate',
  components: {
    ShiftOfferCard,
  },
  props: {
    formattedDate: {
      type: String,
      required: true,
    },
    shiftOfferData: {
      type: Array as PropType<ShiftOfferData[]>,
      required: true,
    },
  },
});
