import { FlowConfig, FlowStep, FlowStepMap } from '@caresend/ui-components';

import type { RootState } from '@/store/model';

export interface ProcedureAndWaypointActionID {
  procedureID: string;
  waypointActionID: string;
}

export enum SetOrUnset {
  SET = 'set',
  UNSET = 'unset'
}

// SUPPLY TRANSFER FLOW

export enum SupplyTransferFlowParam {
  SUPPLY_TRANSFER_ID = 'supplyTransferID',
  PROCEDURE_ID = 'procedureID',
  SUPPLY_SHIPMENT_ID = 'supplyShipmentID',
}

export type SupplyTransferFlowConfig = FlowConfig<
  SupplyTransferFlowParam.SUPPLY_TRANSFER_ID,
  SupplyTransferFlowParam,
  RootState
>

export type SupplyTransferStep = FlowStep<SupplyTransferFlowConfig>;

export type SupplyTransferStepMap = FlowStepMap<SupplyTransferFlowConfig>;

// SUPPLY SHIPMENT FLOW

export enum SupplyShipmentFlowParam {
  SUPPLY_SHIPMENT_ID = 'supplyShipmentID',
}

export type SupplyShipmentFlowConfig = FlowConfig<
  SupplyShipmentFlowParam.SUPPLY_SHIPMENT_ID,
  SupplyShipmentFlowParam,
  RootState
>

export type SupplyShipmentStep = FlowStep<SupplyShipmentFlowConfig>;

export type SupplyShipmentStepMap = FlowStepMap<SupplyShipmentFlowConfig>;
