import { ByID, Transaction, TransactionData } from '@caresend/types';
import update from 'immutability-helper';

import { getTransactionsRequest } from '@/database/firebase/API';
import { TransactionsActions, TransactionsState } from '@/store/modules/transactions/model';

type S = TransactionsState;

const transactionsState: S = {
  transactions: {},
};

const bookingsMutations = {
  'transactions/RESET_TRANSACTIONS': (state: S) => {
    state.transactions = {};
  },

  'transactions/SET_TRANSACTIONS': (state: S, transactions: ByID<Transaction> | undefined) => {
    state.transactions = {
      ...state.transactions,
      ...transactions,
    };
  },

  'transactions/UNSET_TRANSACTION': (state: S, transactionID: string) => {
    if (state.transactions) {
      state.transactions = update(state.transactions, {
        $unset: [transactionID],
      });
    }
  },
};

const transactionsActions: TransactionsActions = {
  'transactions/fetchTransactions': async ({ commit }, { transactionIDs }) => {
    if (!transactionIDs) return;

    const { transactions } = await getTransactionsRequest({
      transactionIDs,
      includeServiceDataInTransactionData: true,
    });
    commit('transactions/SET_TRANSACTIONS', transactions);
  },
};

const transactionsGetters = {
  'transactions/getTransactionByID': (state: S) => (id: string | undefined): TransactionData | null =>
    id ? state.transactions?.[id] ?? null : null,
};

export type TransactionsGetters = typeof transactionsGetters;

export const transactionsModule = {
  state: transactionsState,
  mutations: bookingsMutations,
  actions: transactionsActions,
  getters: transactionsGetters,
};

export type TransactionsModule = typeof transactionsModule;
