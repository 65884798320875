/* eslint sort-keys: "error" */
import {
  Barcode,
  ByID,
  ChartingChecklistItem,
  ChartingItem,
  EncryptedFile,
  NonEmptyArray,
  SupplyItemWithSupplyID,
  WaypointAction,
  WaypointActionStatusName,
} from '@caresend/types';
import {
  AnyGetters,
  DataFetchedByParam,
  FlowCompletionAction,
  RouteLike,
  getRoute,
  keepQuery,
  toastError,
} from '@caresend/ui-components';
import { isNonEmptyArray, objectMap } from '@caresend/utils';
import update from 'immutability-helper';
import { ComputedRef, computed } from 'vue';
import { Location, Route } from 'vue-router';

import { trackWaypointDelayFromDeviationChecks } from '@/functions/itinerary/tracking';
import { trackDebugEvent } from '@/functions/tracking/tracking';
import type { RootState } from '@/store/model';
import { itineraryFlowDataFetchers } from '@/store/modules/itineraryFlow/dataFetchers';
import {
  getChartingItemByIndexArray,
  getExtendedCollectedSamples,
  getExtendedSampleForSample,
  getSortedExtendedSamples,
  getUpdatedChartingItems,
  hasChartingItemsStructureChanged,
} from '@/store/modules/itineraryFlow/helpers';
import {
  CentrifugationState,
  DeviationChecks,
  ExtendedCollectedSample,
  ExtendedSample,
  FailedDeviationCheck,
  ItineraryFlowActions,
  ItineraryFlowLocalState,
  ItineraryFlowParam,
  ItineraryFlowState,
  ItineraryStep,
  PackingFlowInstruction,
  SetChartingItemMutationParams,
  WaypointDeviationName,
  deviationFailureGetters,
} from '@/store/modules/itineraryFlow/model';
import { getItineraryFlowHelpers } from '@/store/modules/itineraryFlow/steps/root';
import {
  getFirstSampleSubStepLocation,
  getPatientLocation,
  getPatientWaypointEntryLocation,
  getPickDropWaypointEntryLocation,
  getProcedureRoute,
  routeIsLastInCurrentItinerarySubSteps,
} from '@/store/modules/itineraryFlow/steps/routerHelpers';

type S = ItineraryFlowState;

const initCentrifugationState = (
  state: S | undefined,
  itineraryID: string | undefined,
): CentrifugationState => {
  const centrifugationState = state?.localState.centrifugation;

  return (
    !centrifugationState
    || !centrifugationState.itineraryID
    || centrifugationState.itineraryID !== itineraryID
  ) ? {
      centrifugedProcedures: {},
      initialSpecimenBagsScanned: {},
      itineraryID,
      newSpecimenBagsScanned: {},
      photo: {},
      samplesRequiringCentrifugationScanned: {},
      samplesScannedForNewSpecimenBag: {},
    } : centrifugationState;
};

// TODO: Create helper in ui-components that can be used to init dataFetched
// for any flow.
const initDataFetched = (): DataFetchedByParam<ItineraryFlowParam> =>
  Object.values(ItineraryFlowParam)
    .reduce<DataFetchedByParam<ItineraryFlowParam>>((fetchableParams, param) => {
      if (!itineraryFlowDataFetchers[param]) return fetchableParams;
      return {
        ...fetchableParams,
        [param]: {},
      };
    }, {});

const initLocalState = (
  state?: S,
  itineraryID?: string,
): ItineraryFlowLocalState => ({
  centrifugation: initCentrifugationState(state, itineraryID),
  dataFetched: initDataFetched(),
  deviationChecks: null,
  flowDataIsReady: {},
  flowLoading: 'Loading your itinerary…',
  flowLoadingError: null,
  mailIn: {
    scanBox: null,
    scanItems: [],
    scanLabel: null,
    takePhoto: null,
  },
  modals: {
    waypointTransitAlert: {
      drivingEstimate: 0,
      isOpened: false,
      status: null,
    },
  },
  nextButtonLoading: false,
  scannedShippingLabelBarcode: {},
});

const itineraryFlowState: S = {
  localState: initLocalState(),
  proceduresInput: {},
  waypointActionsInput: {},
};

const itineraryFlowMutations = {
  'itineraryFlow/INIT_WAYPOINT_ACTIONS_INPUT': (
    state: S, waypointActions: ByID<WaypointAction>,
  ) => {
    const waypointActionsInput = objectMap(waypointActions, (action) => {
      const checked = action.status.status === WaypointActionStatusName.COMPLETE;
      return { checked };
    });
    state.waypointActionsInput = waypointActionsInput;
  },

  'itineraryFlow/RESET_DEVIATION_MODALS': (state: S) => {
    state.localState.deviationChecks = null;
  },

  'itineraryFlow/RESET_LOCAL_STATE': (state: S, itineraryID: string) => {
    state.localState = initLocalState(state, itineraryID);
  },

  'itineraryFlow/SET_CENTRIFUGATION_INITIAL_SPECIMEN_BAGS_SCANNED': (
    state: S, { itemsScanned, processingActionID }: {
      itemsScanned: boolean;
      processingActionID: string;
    },
  ) => {
    state.localState.centrifugation.initialSpecimenBagsScanned = {
      ...state.localState.centrifugation.initialSpecimenBagsScanned,
      [processingActionID]: itemsScanned,
    };
  },

  'itineraryFlow/SET_CENTRIFUGATION_ITINERARY_ID': (
    state: S,
    itineraryID: string,
  ) => {
    state.localState.centrifugation.itineraryID = itineraryID;
  },

  'itineraryFlow/SET_CENTRIFUGATION_PHOTO': (
    state: S,
    { photo, processingActionID }: {
      photo: EncryptedFile;
      processingActionID: string;
    },
  ) => {
    state.localState.centrifugation.photo = {
      ...state.localState.centrifugation.photo,
      [processingActionID]: photo,
    };
  },

  'itineraryFlow/SET_CENTRIFUGED_PROCEDURE': (
    state: S,
    { procedureID, centrifuged }: {
      procedureID: string;
      centrifuged: boolean;
    },
  ) => {
    state.localState.centrifugation.centrifugedProcedures = update(
      state.localState.centrifugation.centrifugedProcedures,
      { [procedureID]: { $set: centrifuged } },
    );
  },

  'itineraryFlow/SET_CHARTING_ITEM': (
    state: S,
    params: SetChartingItemMutationParams,
  ) => {
    const {
      procedureID,
      newPartialChartingItem,
      chartingItemIndexArray,
      newChartingChecklistItem,
      checklistItemIndex,
    } = params;
    const chartingItems = state.proceduresInput[procedureID]?.chartingItems ?? [];
    const chartingItem = getChartingItemByIndexArray(chartingItems, chartingItemIndexArray);

    const proceduresInput = state.proceduresInput[procedureID];
    if (!proceduresInput) state.proceduresInput[procedureID] = {};

    let newPartialItem: Partial<ChartingItem> = {};

    if (
      newChartingChecklistItem
      && checklistItemIndex !== undefined
      && chartingItem
    ) {
      newPartialItem = update(chartingItem, {
        checklist: {
          [checklistItemIndex]: {
            $merge: newChartingChecklistItem,
          },
        },
      });
    } else if (newPartialChartingItem) {
      newPartialItem = newPartialChartingItem;
    }

    const newItems = getUpdatedChartingItems(
      chartingItems,
      chartingItemIndexArray,
      newPartialItem,
    );

    state.proceduresInput = update(state.proceduresInput, {
      [procedureID]: { chartingItems: { $set: newItems } },
    });
  },

  'itineraryFlow/SET_CHARTING_ITEMS': (
    state: S,
    { procedureID, chartingItems }: {
      procedureID: string;
      chartingItems: ChartingItem[] | undefined;
    },
  ) => {
    // Prevent overwriting a user's in progress charting.
    const beforeChartingItems = state.proceduresInput[procedureID]?.chartingItems;
    const replaceChartingItems = (
      beforeChartingItems === undefined
      || chartingItems === undefined
      || hasChartingItemsStructureChanged(beforeChartingItems, chartingItems)
    );
    // TODO: ReqCheck check is for pre-deploy cache-busting only, may be removed in
    // the future.
    if (replaceChartingItems || !state.proceduresInput[procedureID]?.reqCheck) {
      state.proceduresInput[procedureID] = {
        ...state.proceduresInput[procedureID],
        chartingItems,
        reqCheck: [false, false, false],
      };
    }
  },

  'itineraryFlow/SET_DATA_FETCHED': (state: S, data: DataFetchedByParam<ItineraryFlowParam>) => {
    state.localState.dataFetched = data;
  },

  'itineraryFlow/SET_DEVIATION_CHECKS': (
    state: S,
    deviationChecks: DeviationChecks,
  ) => {
    state.localState.deviationChecks = deviationChecks;
  },

  'itineraryFlow/SET_DEVIATION_REASON': (state: S, payload: FailedDeviationCheck) => {
    const failureIndex = state.localState.deviationChecks
      ?.failedChecks.findIndex((check) => check.name === payload.name) ?? -1;
    if (failureIndex === -1) throw toastError('Missing failure index');

    const nextFailureName = state.localState.deviationChecks
      ?.failedChecks[failureIndex + 1]?.name;

    state.localState.deviationChecks = update(state.localState.deviationChecks, {
      failedChecks: { [failureIndex]: { $set: payload } },
      ...(nextFailureName && { activeModal: { $set: nextFailureName } }),
    });
  },

  'itineraryFlow/SET_FLOW_DATA_IS_READY': (state: S, { itineraryID, ready }: {
    itineraryID: string;
    ready: boolean;
  }) => {
    state.localState.flowDataIsReady = update(
      state.localState.flowDataIsReady,
      { [itineraryID]: { $set: ready } },
    );
  },

  'itineraryFlow/SET_FLOW_LOADING': (state: S, loading: string | false) => {
    state.localState.flowLoading = loading;
  },

  'itineraryFlow/SET_FLOW_LOADING_ERROR': (state: S, message: string | null) => {
    state.localState.flowLoadingError = message;
    state.localState.flowLoading = false;
  },

  'itineraryFlow/SET_MAIL_IN_BOX_SCANNED': (state: S, value: boolean) => {
    state.localState = {
      ...state.localState,
      mailInBoxScanned: value,
    };
  },

  'itineraryFlow/SET_MAIL_IN_STATE': <
    T extends ItineraryFlowState['localState']['mailIn'],
    K extends keyof T
  >(
    state: S,
    valuesByName: { [key in K]?: T[key] },
  ) => {
    const spec = objectMap(valuesByName, (values) => ({
      $set: values,
    }));
    state.localState.mailIn = update(state.localState.mailIn, spec);
  },

  'itineraryFlow/SET_NEW_SPECIMEN_BAG_SCANNED': (
    state: S,
    { procedureID, newSpecimenBagScanned }: {
      procedureID: string;
      newSpecimenBagScanned: SupplyItemWithSupplyID | null;
    },
  ) => {
    if (newSpecimenBagScanned) {
      state.localState.centrifugation.newSpecimenBagsScanned = update(
        state.localState.centrifugation.newSpecimenBagsScanned,
        { [procedureID]: { $set: newSpecimenBagScanned } },
      );
    } else {
      state.localState.centrifugation.newSpecimenBagsScanned = update(
        state.localState.centrifugation.newSpecimenBagsScanned,
        { $unset: [procedureID] },
      );
    }
  },

  'itineraryFlow/SET_NEXT_BUTTON_LOADING': (state: S, isLoading: boolean) => {
    state.localState.nextButtonLoading = isLoading;
  },

  'itineraryFlow/SET_PACKING_FLOW_INSTRUCTION': (
    state: S,
    instruction: PackingFlowInstruction,
  ) => {
    state.localState = update(state.localState, {
      packingFlowInstructions: { $set: update(
        state.localState.packingFlowInstructions ?? {}, {
          [instruction]: { $set: !state?.localState?.packingFlowInstructions?.[instruction] },
        }) },
    });
  },

  'itineraryFlow/SET_SAMPLES_REQUIRING_CENTRIFUGATION_SCANNED': (
    state: S,
    { processingActionID, itemsScanned }: {
      processingActionID: string;
      itemsScanned: boolean;
    },
  ) => {
    state.localState.centrifugation.samplesRequiringCentrifugationScanned = {
      ...state.localState.centrifugation.samplesRequiringCentrifugationScanned,
      [processingActionID]: itemsScanned,
    };
  },

  'itineraryFlow/SET_SAMPLES_SCANNED_FOR_NEW_SPECIMEN_BAG': (
    state: S,
    { procedureID, sampleSupplyItemIDs }: {
      procedureID: string;
      sampleSupplyItemIDs: string[];
    },
  ) => {
    state.localState.centrifugation.samplesScannedForNewSpecimenBag = update(
      state.localState.centrifugation.samplesScannedForNewSpecimenBag,
      { [procedureID]: { $set: sampleSupplyItemIDs } },
    );
  },

  'itineraryFlow/SET_SCANNED_SHIPPING_LABEL_BARCODE': (
    state: S, { procedureID, barcode }: { procedureID: string; barcode: Barcode },
  ) => {
    state.localState.scannedShippingLabelBarcode = update(
      state.localState.scannedShippingLabelBarcode,
      { [procedureID]: { $set: barcode } },
    );
  },

  'itineraryFlow/SET_SPECIMEN_BAGS_SCANNED': (state: S, value: boolean) => {
    state.localState = {
      ...state.localState,
      specimenBagsScanned: value,
    };
  },

  'itineraryFlow/UPDATE_MODAL_VALUES': <
    T extends ItineraryFlowState['localState']['modals'],
    K extends keyof T
  >(
    state: S,
    valuesByName: { [key in K]?: Partial<T[key]> },
  ) => {
    const spec = objectMap(valuesByName, (values) => ({
      $merge: values,
    }));
    state.localState.modals = update(state.localState.modals, spec);
  },
};

export type ItineraryFlowMutations = typeof itineraryFlowMutations;

const itineraryFlowActions: ItineraryFlowActions = {
  'itineraryFlow/initFailedDeviationChecks': async (
    { commit, getters, state },
    {
      waypointID,
      checkSequence = [WaypointDeviationName.DURATION, WaypointDeviationName.LOCATION],
    },
  ) => {
    if (state.localState.deviationChecks) {
      // Once all reasons are entered, the completion action will be called
      // again. It is expected that all reasons will be available for the
      // tracking event, and the rest of the completion action will run,
      // followed by navigation to the next step.
      const reasonsReady = getters['itineraryFlow/getDeviationReasonsReady'];
      if (!reasonsReady) throw Error('Missing deviation reasons');
      return null;
    }

    const failedNames: WaypointDeviationName[] = checkSequence.filter((deviationName) =>
      deviationFailureGetters[deviationName](waypointID),
    );

    const failedChecks: FailedDeviationCheck[] = failedNames.map((deviationName) => ({
      name: deviationName,
      reason: null,
    }));

    if (!isNonEmptyArray(failedChecks)) return null;

    const activeModal = failedNames[0];
    if (!activeModal) throw Error('Unexpected missing modal name');

    const deviationChecks: DeviationChecks = {
      activeModal,
      failedChecks,
    };

    commit('itineraryFlow/SET_DEVIATION_CHECKS', deviationChecks);

    return failedChecks;
  },

  'itineraryFlow/initItineraryFlow': async ({ commit }, itineraryID) => {
    trackDebugEvent('startInitItineraryFlow', { itineraryID });
    commit('itineraryFlow/RESET_LOCAL_STATE', itineraryID);
    const route = getRoute().value;
    await getItineraryFlowHelpers(route).maybeFetchFlowData(route);
  },

  'itineraryFlow/performCompletionAction': async () => {
    const route = getRoute().value;
    await getItineraryFlowHelpers(route).performCompletionAction(route);
  },

  /** Set deviation reason comment override? */
  'itineraryFlow/setDeviationReasonAndContinue': async (
    { commit, dispatch, state }, { name, reason },
  ) => {
    const failureIndex = state.localState.deviationChecks
      ?.failedChecks.findIndex((check) => check.name === name) ?? -1;
    if (failureIndex === -1) throw toastError('Missing failure index');

    const nextFailureName = state.localState.deviationChecks
      ?.failedChecks[failureIndex + 1]?.name;

    commit('itineraryFlow/SET_DEVIATION_REASON', { name, reason });

    if (!nextFailureName) {
      // Track failures and go to next step. Otherwise, mutation above will
      // result in the next failure modal showing.
      const checks = state.localState.deviationChecks;
      if (!checks) throw Error('Missing deviation checks');
      await trackWaypointDelayFromDeviationChecks(checks);

      await dispatch('itineraryFlow/performCompletionAction');
      commit('itineraryFlow/RESET_DEVIATION_MODALS');
    }
  },
};

const itineraryFlowGetters = {
  'itineraryFlow/getBackStepLocationInItineraryFlow': () => (route: RouteLike): Location | null => {
    const location = getItineraryFlowHelpers(route).getBackOrNextStepRoute(route, 'back');
    return location ? keepQuery(location) : null;
  },

  'itineraryFlow/getChartingChecklistItem': (state: S) => (
    procedureID: string,
    itemIndexArray: number[],
    checklistItemIndex: number,
  ): ChartingChecklistItem | undefined => {
    const chartingItem = itineraryFlowGetters['itineraryFlow/getChartingItem'](state)(
      procedureID,
      itemIndexArray,
    );
    return chartingItem?.checklist?.[checklistItemIndex];
  },

  'itineraryFlow/getChartingItem': (state: S) => (
    procedureID: string,
    itemIndexArray: number[],
  ): ChartingItem | undefined => {
    const chartingItems = state.proceduresInput[procedureID]?.chartingItems;
    if (!chartingItems) return;
    return getChartingItemByIndexArray(chartingItems, itemIndexArray);
  },

  'itineraryFlow/getChartingItems': (state: S) => (
    procedureID: string,
  ): ChartingItem[] | null => state.proceduresInput[procedureID]?.chartingItems ?? null,

  'itineraryFlow/getCurrentStep': () => (route: Route): ItineraryStep | null =>
    getItineraryFlowHelpers(route).getStepFromRoute(route),

  'itineraryFlow/getCurrentStepCompletionAction': () => (route: Route): FlowCompletionAction | null =>
    getItineraryFlowHelpers(route).getCurrentStepCompletionAction(route),

  'itineraryFlow/getCurrentStepValidationFailures': () => (
    route: Route,
  ): NonEmptyArray<string> | null =>
    getItineraryFlowHelpers(route).getCurrentStepValidationFailures(route),

  'itineraryFlow/getDeviationReasonsReady': (state: S): boolean => {
    const checks = state.localState.deviationChecks;
    if (!checks) return false;
    const someReasonEmpty = checks.failedChecks.some((check) => !check.reason);
    return !someReasonEmpty;
  },

  'itineraryFlow/getExtendedCollectedSamples': (
    _state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (procedureID: string): ExtendedCollectedSample[] =>
    getExtendedCollectedSamples(procedureID, rootState),

  'itineraryFlow/getExtendedSample': (
    _state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (procedureID: string, sampleID: string): ExtendedSample | undefined => {
    const sample = rootState.procedures.procedures[procedureID]?.samples?.[sampleID];
    if (!sample) return;
    return getExtendedSampleForSample(sample, rootState);
  },

  'itineraryFlow/getFirstSampleSubStepLocation': () => (
    currentRoute: Route,
    procedureID: string,
    sampleID: string,
  ): Location | null => {
    const location = getFirstSampleSubStepLocation(currentRoute, procedureID, sampleID);
    return location ? keepQuery(location) : null;
  },

  'itineraryFlow/getIsCurrentStepValid': () => (
    route: Route,
  ): ComputedRef<boolean> => computed(() =>
    itineraryFlowGetters['itineraryFlow/getCurrentStepValidationFailures']()(route) === null,
  ),

  'itineraryFlow/getIsNewSpecimenBagScanned': (state: S) => (
    procedureID: string,
  ): boolean =>
    !!state.localState.centrifugation.newSpecimenBagsScanned[procedureID],

  'itineraryFlow/getNextStepLocationInOrder': () => (route: Route): Location | null => {
    const location = getItineraryFlowHelpers(route).getBackOrNextStepRoute(route, 'next');
    return location ? keepQuery(location) : null;
  },

  'itineraryFlow/getPatientWaypointEntryLocation': () => (
    itineraryID: string,
    waypointID: string,
  ): Location | null => {
    const location = getPatientWaypointEntryLocation(itineraryID, waypointID);
    return location ? keepQuery(location) : null;
  },

  'itineraryFlow/getPatientWaypointPatientLocation': () => (
    itineraryID: string,
    waypointID: string,
    waypointActionID: string,
  ): Location | null => {
    const location = getPatientLocation(itineraryID, waypointID, waypointActionID);
    return location ? keepQuery(location) : null;
  },

  'itineraryFlow/getPatientWaypointProcedureLocation': () => (
    itineraryID: string,
    waypointID: string,
    waypointActionID: string,
    procedureID: string,
  ): Location | null => {
    const location = getProcedureRoute(
      itineraryID,
      waypointID,
      waypointActionID,
      procedureID,
    );
    return location ? keepQuery(location) : null;
  },

  'itineraryFlow/getPickDropWaypointEntryLocation': () => (
    itineraryID: string,
    waypointID: string,
  ): Location | null => {
    const location = getPickDropWaypointEntryLocation(itineraryID, waypointID);

    return location ? keepQuery(location) : null;
  },

  'itineraryFlow/getRouteIsLastInSection': () => (route: Route): boolean =>
    routeIsLastInCurrentItinerarySubSteps(route),

  'itineraryFlow/getScannedShippingLabelBarcode': (state: S) => (
    procedureID: string,
  ): Barcode | undefined => state.localState.scannedShippingLabelBarcode?.[procedureID],

  'itineraryFlow/getSortedExtendedSamples': (
    _state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ) => (procedureID: string): ExtendedSample[] =>
    getSortedExtendedSamples(procedureID, rootState),
};

export type ItineraryFlowGetters = typeof itineraryFlowGetters;

export const itineraryFlowModule = {
  actions: itineraryFlowActions,
  getters: itineraryFlowGetters,
  mutations: itineraryFlowMutations,
  state: itineraryFlowState,
};

export type ItineraryFlowModule = typeof itineraryFlowModule;
