
import { BasicCard, ButtonComponent } from '@caresend/ui-components';
import Vue from 'vue';

import PageWithButton from '@/components/layout/pages/PageWithButton.vue';
import { workSettingsRoute } from '@/router/locations';

export default Vue.extend({
  name: 'PendingScreen',
  components: {
    BasicCard,
    ButtonComponent,
    'page-with-button': PageWithButton,
  },
  props: {},
  data() {
    return {
      workSettingsRoute,
    };
  },
});
