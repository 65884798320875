
import { TransitionDownUp } from '@caresend/ui-components';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OfficeDashboard',
  components: {
    TransitionDownUp,
  },
});
