
import { EncryptedUser, TrackingEvent } from '@caresend/types';
import Vue from 'vue';

import PageWithButton from '@/components/layout/pages/PageWithButton.vue';
import { trackEvent } from '@/functions/tracking/tracking';

export default Vue.extend({
  name: 'ScheduleInterview',
  components: {
    'page-with-button': PageWithButton,
  },
  props: {
    calendarPath: { type: String, required: true },
    title: { type: String, default: 'Book your interview' },
  },
  data() {
    return {};
  },
  computed: {
    calendlyURL(): string {
      let url = `https://calendly.com/${this.calendarPath}`;
      if (this.user) {
        url += `?name=${this.user.info.firstName} ${this.user.info.lastName}&email=${this.user.info.email}`;
      }
      return url;
    },
    user(): EncryptedUser | undefined {
      return this.$store.state.auth.user;
    },
  },
  beforeMount() {
    const isCalendlyEvent = (event: any) => event.data?.event?.indexOf('calendly') === 0;

    window.addEventListener('message', (event) => {
      if (isCalendlyEvent(event)) {
        if (event.data.event === 'calendly.event_scheduled') {
          const interviewUri = event.data.payload.event.uri;
          this.$emit('scheduled', interviewUri);
          trackEvent(TrackingEvent.SIGNUP_NURSE_INTERVIEW_SCHEDULE_EVENT);
        }
      }
    });
  },
  methods: {},
});
