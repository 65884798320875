/* eslint-disable no-restricted-properties */
import {
  AddressComponent,
  DbRef,
  LocationObject,
  LocationUser,
  Role,
  TrackingEvent,
} from '@caresend/types';
import { dbSet, getStore, toastError } from '@caresend/ui-components';
import { getLatLngDeviationInFt, getUnknownErrorMessage, initTimeZoneDate, isNullish } from '@caresend/utils';

import { removeSpaces } from '@/functions/methods';
import { trackEvent } from '@/functions/tracking/tracking';
import { GeolocationPermissionStatus } from '@/store/modules/app';
import { trackSetUserAssociatedPlaceID } from '@/store/modules/places/tracking';

const getLocation = (addressComponents: AddressComponent[]) => {
  const location = { country: '', state: '', city: '' };
  addressComponents.forEach((locationEntry: any) => {
    const locationType = locationEntry.types[0];
    if (locationType === 'locality') {
      location.city = removeSpaces(locationEntry.long_name);
    } else if (locationType === 'administrative_area_level_1') {
      location.state = removeSpaces(locationEntry.short_name);
    } else if (locationType === 'country') {
      location.country = removeSpaces(locationEntry.short_name);
    }
  });
  return location;
};

const cityStateDisplayFormat = (addressComponents: any) => {
  const location = { state: '', city: '' };
  addressComponents.forEach((locationEntry: any) => {
    const locationType = locationEntry.types[0];
    if (locationType === 'locality') {
      location.city = locationEntry.long_name;
    } else if (locationType === 'administrative_area_level_1') {
      location.state = locationEntry.short_name;
    }
  });
  return `${location.city}, ${location.state}`;
};

const setUserLocation = (pos: LocationObject) => {
  const store = getStore();

  const userID = store.state.auth.user?.id;
  const currentLocation: LocationUser['current'] = {
    ...pos,
    lastUpdate: initTimeZoneDate(undefined, Date.now()),
  };

  dbSet<LocationUser['current']>(
    `${DbRef.USERS}/${userID}/location/current`,
    currentLocation,
  );
};

export const setUserCurrentLocation = async (location: LocationObject | undefined) => {
  if (!location) return;
  const locationObject = location;
  if (!location.timeZone) {
    delete locationObject.timeZone;
  }
  await setUserLocation(location);
};

const LOCATION_EXPIRY_TIME = 300000; // 5 minutes in ms
const LOCATION_EXPIRY_DISTANCE = 100; // 100 feet
const maybeSetUserCurrentLocation = async (location: LocationObject) => {
  const store = getStore();

  /** Validate previous location. If no value is stored, store new location */
  const previousLocation = store.state.auth.user?.location?.current;
  if (
    isNullish(previousLocation)
    || isNullish(previousLocation.lastUpdate)
    || isNullish(previousLocation.lastUpdate.timestamp)
  ) return setUserCurrentLocation(location);

  /** Determine last time and place location is captured and compare to deviation requirements */
  const timeExpired = (Date.now() - previousLocation.lastUpdate.timestamp) > LOCATION_EXPIRY_TIME;
  const locationExpired = getLatLngDeviationInFt(previousLocation, location) > LOCATION_EXPIRY_DISTANCE;
  if (
    timeExpired
    || locationExpired
  ) return setUserCurrentLocation(location);
};

const maybeSetUserAssociatedPlaceID = async (location: LocationObject) => {
  const store = getStore();

  /** Only set associated placeID for Packers */
  if (store.state.auth.user?.role !== Role.PACKER) return;
  const placeID = await store.dispatch('places/setUserAssociatedPlaceID', location);
  trackSetUserAssociatedPlaceID(location, placeID);
};

const isGeolocationPositionError = (e: unknown): e is GeolocationPositionError => {
  const error: GeolocationPositionError = e as any;
  return (
    !!error.PERMISSION_DENIED
    && !!error.POSITION_UNAVAILABLE
    && !!error.TIMEOUT
    && !!error.code
    && !!error.message
  );
};

const trackWatchCurrentLocation = async ({ lat, lng, errorMessage }: {
  lat?: number;
  lng?: number;
  errorMessage?: string;
}) => {
  await trackEvent(
    TrackingEvent.WATCH_CURRENT_LOCATION,
    {
      lat,
      lng,
      errorMessage,
    },
  );
};

let qaOverrideInterval: ReturnType<typeof setInterval> | null = null;

const unwatchCurrentLocation = (id: number) => {
  if (qaOverrideInterval) clearInterval(qaOverrideInterval);
  navigator.geolocation.clearWatch(id);
};

/** Watch for revocation of permission if permissions API is available. */
const watchPermissions = async () => {
  const store = getStore();

  const permissionStatus = await navigator.permissions?.query({ name: 'geolocation' });
  if (!permissionStatus) return;
  permissionStatus.onchange = () => {
    const locationOverrides = store.state.app.qaOverrides?.location;
    const revoked = permissionStatus.state === 'denied' && !locationOverrides;
    if (revoked) {
      store.commit(
        'app/SET_GEOLOCATION_PERMISSION_STATUS',
        GeolocationPermissionStatus.PERMISSION_DENIED,
      );
      trackWatchCurrentLocation({
        errorMessage: 'Detected change to denied permission',
      });
    }
  };
};

const watchCurrentLocation = () => new Promise<number>(
  (resolve, reject) => {
    const store = getStore();

    /** Simulate functionality with QA overrides for testing purposes */
    // QA Test query = `?lat=33.9986678&lng=-118.4296201`
    const locationOverrides = store.state.app.qaOverrides?.location;
    console.info('watchCurrentLocation', locationOverrides);
    if (locationOverrides) {
      const { lat, lng } = locationOverrides;
      const setQAOverrides = async () => {
        await maybeSetUserCurrentLocation({ lat, lng });
        await maybeSetUserAssociatedPlaceID({ lat, lng });
        store.commit(
          'app/SET_GEOLOCATION_PERMISSION_STATUS',
          GeolocationPermissionStatus.ALLOWED,
        );
      };
      qaOverrideInterval = setInterval(setQAOverrides, 1000);
      resolve(0);
      return;
    }

    if (!('geolocation' in navigator)) {
      const errorMessage = 'Geolocation is not available';
      trackWatchCurrentLocation({ errorMessage });
      reject(toastError(errorMessage));
    }

    watchPermissions();

    let isFirstCallback = true;

    const handleSuccessfulLocationUpdate = async (
      pos: GeolocationPosition,
      watcherID: number,
    ) => {
      try {
        const newLocation: LocationObject = {
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        };
        await maybeSetUserCurrentLocation(newLocation);
        await maybeSetUserAssociatedPlaceID(newLocation);
        store.commit(
          'app/SET_GEOLOCATION_PERMISSION_STATUS',
          GeolocationPermissionStatus.ALLOWED,
        );

        if (isFirstCallback) {
          trackWatchCurrentLocation(newLocation);
          resolve(watcherID);
          isFirstCallback = false;
        }
      } catch (error) {
        trackWatchCurrentLocation({ errorMessage: getUnknownErrorMessage(error) });
        if (isFirstCallback) {
          reject(error);
          isFirstCallback = false;
        }
      }
    };

    const handleFailedLocationUpdate = (
      error: GeolocationPositionError,
      watcherID: number,
    ) => {
      trackWatchCurrentLocation({ errorMessage: getUnknownErrorMessage(error) });
      if (error.code === error.PERMISSION_DENIED) {
        store.commit(
          'app/SET_GEOLOCATION_PERMISSION_STATUS',
          GeolocationPermissionStatus.PERMISSION_DENIED,
        );
      } else {
        store.commit(
          'app/SET_GEOLOCATION_PERMISSION_STATUS',
          GeolocationPermissionStatus.ERROR,
        );
      }
      unwatchCurrentLocation(watcherID);
      if (isFirstCallback) {
        reject(error);
        isFirstCallback = false;
      }
    };

    const watcherID = navigator.geolocation.watchPosition(
      (pos) => handleSuccessfulLocationUpdate(pos, watcherID),
      (error) => handleFailedLocationUpdate(error, watcherID),
      {
        enableHighAccuracy: false,
        timeout: 30000,
        maximumAge: 0,
      },
    );
  });

const milesToKm = (distance: number) => distance * 62137;

const whiteDot = 'https://firebasestorage.googleapis.com/v0/b/caresend-dev.appspot.com/o'
  + '/icons%2Fwhite-dot.png?alt=media&token=449f945e-30b7-405a-8062-bb153bcad53f';

const iconSupplyUrl = whiteDot;
const iconPatientUrl = whiteDot;

const openMapDirection = (location: LocationObject) => {
  const url = `https://www.google.com/maps/dir/?api=1&origin=my+location&destination=${location.lat},${location.lng}`;
  window.open(url, '_blank');
};

const distanceBetweenLocations = (
  location1: LocationObject,
  location2: LocationObject,
  isMiles?: boolean,
) => {
  const { lat: lat1, lng: lon1 } = location1;
  const { lat: lat2, lng: lon2 } = location2;

  const toRadian = (angle: number) => (Math.PI / 180) * angle;
  const distance = (a: number, b: number) => (Math.PI / 180) * (a - b);
  const RADIUS_OF_EARTH_IN_KM = 6371;

  const dLat = distance(lat2, lat1);
  const dLon = distance(lon2, lon1);

  const lat1Rad = toRadian(lat1);
  const lat2Rad = toRadian(lat2);

  //  Haversine Formula
  const a
    = Math.pow(Math.sin(dLat / 2), 2)
    + Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1Rad) * Math.cos(lat2Rad);
  const c = 2 * Math.asin(Math.sqrt(a));

  let finalDistance = RADIUS_OF_EARTH_IN_KM * c;

  if (isMiles) {
    const conversionMilesToKmRatio = 1.60934;
    finalDistance /= conversionMilesToKmRatio;
  }
  return Number(finalDistance.toFixed(1));
};

const centerBetweenSources = (sources1: any, sources2: any) => {
  const coodinates1 = sources1.data[0].geometry.coordinates;
  const [lat1, lng1] = coodinates1;
  const coodinates2 = sources2.data[0].geometry.coordinates;
  const [lat2, lng2] = coodinates2;
  const center = { lat: (lng1 + lng2) / 2, lng: (lat1 + lat2) / 2 };
  return center;
};

const formatHiddenAddress = (formattedAddress: string | undefined): string => {
  if (!formattedAddress) return 'Unknown address';
  const arrayAddress = formattedAddress.split(',');
  arrayAddress.shift();
  return arrayAddress.join(', ');
};

export {
  centerBetweenSources,
  cityStateDisplayFormat,
  distanceBetweenLocations,
  formatHiddenAddress,
  getLocation,
  iconPatientUrl,
  iconSupplyUrl,
  isGeolocationPositionError,
  milesToKm,
  openMapDirection,
  unwatchCurrentLocation,
  watchCurrentLocation,
  whiteDot,
};
