import { NonEmptyArray } from '@caresend/types';
import { FlowStepValidationFailuresGetter, FlowStepValidators, getStore } from '@caresend/ui-components';
import { Route } from 'vue-router';

import { routeNames } from '@/router/model';
import { PackingFlowInstruction } from '@/store/modules/itineraryFlow/model';

const getItineraryFlowScanMailInBoxStepFailures: FlowStepValidationFailuresGetter = (
  _route: Route,
): NonEmptyArray<string> | null => {
  const mailInBoxIsScanned = getStore().state.itineraryFlow.localState.mailInBoxScanned;
  if (!mailInBoxIsScanned) return ['The mail-in box must be scanned'];
  return null;
};

const getItineraryFlowPackSpecimenBagsStepFailures: FlowStepValidationFailuresGetter = (
  _route: Route,
): NonEmptyArray<string> | null => {
  const specimenBagsAreScanned = getStore().state.itineraryFlow.localState.specimenBagsScanned;
  if (!specimenBagsAreScanned) return ['The specimen bags must be packed and scanned'];
  return null;
};

const validatePackingFlowInstructions = (
  instructionKeys: string[],
): NonEmptyArray<string> | null => {
  const packingFlowInstructions
    = getStore().state.itineraryFlow.localState?.packingFlowInstructions ?? {};

  const missingInstruction = instructionKeys
    .map((key, index) => ({ key, index }))
    .find(({ key }) => !packingFlowInstructions[key]);

  return missingInstruction
    ? [`Instruction ${missingInstruction.index + 1} must be completed`]
    : null;
};

const getItineraryFlowFollowInstructionsStepFailures: FlowStepValidationFailuresGetter = (
  _route: Route,
): NonEmptyArray<string> | null => validatePackingFlowInstructions([
  PackingFlowInstruction.REMOVE_TAPE_STRIPS,
  PackingFlowInstruction.REMOVE_PRESSURE_BAG,
  PackingFlowInstruction.PLACE_ICE_PACKS,
]);

/**
 * Validators to determine when the `Next` button should be enabled on
 * order flow steps.
 */
export const itineraryFlowPackingStepValidators: FlowStepValidators = {
  [routeNames.ITINERARY_FLOW_UNPACKED_MAIL_IN_ACTION_SCAN_MAIL_IN_BOX]:
    getItineraryFlowScanMailInBoxStepFailures,
  [routeNames.ITINERARY_FLOW_UNPACKED_MAIL_IN_ACTION_PACK_SPECIMEN_BAGS]:
    getItineraryFlowPackSpecimenBagsStepFailures,
  [routeNames.ITINERARY_FLOW_UNPACKED_MAIL_IN_ACTION_FOLLOW_INSTRUCTIONS]:
    getItineraryFlowFollowInstructionsStepFailures,
};
