
import { ButtonComponent } from '@caresend/ui-components';
import { defineComponent } from 'vue';

import MessageIcon from '@/assets/icons/message.svg';
import { showIntercomMessenger } from '@/database/intercom/methods';

export default defineComponent({
  name: 'SupportMessengerButton',
  components: {
    ButtonComponent,
    MessageIcon,
  },
  setup() {
    return {
      showIntercomMessenger,
    };
  },
});
