import { render, staticRenderFns } from "./ShiftOffersByDate.vue?vue&type=template&id=0b07705e&scoped=true&"
import script from "./ShiftOffersByDate.vue?vue&type=script&lang=ts&"
export * from "./ShiftOffersByDate.vue?vue&type=script&lang=ts&"
import style0 from "./ShiftOffersByDate.vue?vue&type=style&index=0&id=0b07705e&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b07705e",
  null
  
)

export default component.exports