
import { PropType, computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'EmptyList',
  props: {
    displayPastItems: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      required: true,
    },
    emptyListText: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
  },
  setup(props) {
    const messageEmptyList = computed<string>(() => {
      if (!props.displayPastItems) {
        return `${props.listType} will appear here.`;
      }
      return `Previous ${props.listType} will be displayed here.`;
    });

    const displayText = computed<string | undefined>(() => props.emptyListText || messageEmptyList.value);

    return { displayText };
  },
});
