import { WaypointType } from '@caresend/types';
import { DataFetchedByParam, FlowDataFetchers, getStore } from '@caresend/ui-components';
import { nullishFilter } from '@caresend/utils';

import { trackDebugEvent } from '@/functions/tracking/tracking';
import { ItineraryFlowParam } from '@/store/modules/itineraryFlow/model';

export const itineraryFlowGetDataFetched = (): DataFetchedByParam<ItineraryFlowParam> =>
  getStore().state.itineraryFlow.localState.dataFetched;

export const itineraryFlowSetDataFetched = (data: DataFetchedByParam<ItineraryFlowParam>) => {
  getStore().commit('itineraryFlow/SET_DATA_FETCHED', data);
};

export const itineraryFlowGetFlowLoading = (): string | boolean =>
  getStore().state.itineraryFlow.localState.flowLoading;

export const itineraryFlowSetFlowLoading = (loading: string | false) => {
  getStore().commit('itineraryFlow/SET_FLOW_LOADING', loading);
};

export const itineraryFlowSetFlowLoadingError = (error: string | null) => {
  getStore().commit('itineraryFlow/SET_FLOW_LOADING_ERROR', error);
};

const itineraryFetchAction = async (itineraryID: string) => {
  const store = getStore();
  const { commit, dispatch, getters } = store;
  // TODO: Fetching all place data is excessive. We may be able to update
  // fetchItineraryData and fetchWaypointData to return the specific
  // place-related data we need. Not awaiting as the flow does not require it.
  dispatch('places/bindAllPlaceData');
  await dispatch('waypoint/fetchItineraryData', itineraryID);

  // TODO: Consider creating a fetch action for waypoint actions. This logic
  // could be refactored and moved there.
  const supplyItemIDs = getters[
    'waypoint/getItineraryMailInActions'
  ](itineraryID).map((mailInAction) =>
    mailInAction.mailInBoxBarcode,
  ).filter(nullishFilter);
  await dispatch('supplies/fetchSupplyItems', { supplyItemIDs });

  trackDebugEvent('finishingItineraryFetchAction', { itineraryID });

  commit('itineraryFlow/SET_FLOW_DATA_IS_READY', { itineraryID, ready: true });
};

const getWaypointLoadingMessage = (waypointID: string): string => {
  const waypoint = getStore().getters['waypoint/getWaypointByID'](waypointID);
  if (!waypoint) return 'Loading waypoint details…';

  if (waypoint.type === WaypointType.PATIENT) {
    return 'Loading patient visit details…';
  }

  if (waypoint.type === WaypointType.PICK_DROP) {
    return 'Loading stop details…';
  }

  return 'Loading waypoint details…';
};

const waypointFetchAction = async (waypointID: string) => {
  const store = getStore();
  const { dispatch, getters } = store;
  // TODO: reduce/remove calls to fetchWaypointData in individual components
  // if possible.
  await dispatch('waypoint/fetchWaypointData', waypointID);

  const supplyItemIDs = getters[
    'waypoint/getWaypointMailInActions'
  ](waypointID).map((mailInAction) =>
    mailInAction.mailInBoxBarcode,
  ).filter(nullishFilter);
  await dispatch('supplies/fetchSupplyItems', { supplyItemIDs });
};

export const itineraryFlowDataFetchers: FlowDataFetchers<ItineraryFlowParam> = {
  itineraryID: {
    fetchAction: itineraryFetchAction,
    loadingMessageGetter: () => 'Loading your itinerary…',
    staleMinutes: 10,
  },
  waypointID: {
    fetchAction: waypointFetchAction,
    loadingMessageGetter: getWaypointLoadingMessage,
    staleMinutes: 10,
  },
};
