import { WaypointActionStatusName } from '@caresend/types';
import { FlowCompletionAction, FlowCompletionActions, reportException } from '@caresend/ui-components';
import { Route } from 'vue-router';

import { routeNames } from '@/router/model';
import { updateWaypointActionStatus } from '@itineraryFlow/helpers/updates';

const packSpecimenBagsCompletionAction: FlowCompletionAction = async (
  route: Route,
) => {
  try {
    if (!route.params.waypointActionID) throw Error('Waypoint action ID is undefined');
    await updateWaypointActionStatus(
      route.params.waypointActionID,
      WaypointActionStatusName.PACKED,
    );
  } catch (error) {
    console.error(error);
    reportException(error);
  }
};

/**
 * If a completion action is defined, the next button will trigger that action
 * rather than being a `<router-link>` to the next step. While the async action
 * is in progress, a loader will show on the next button. If the promise
 * resolves, the user will be navigated to the next step.
 *
 * **To cancel navigation, an error must be thrown, or false must be returned.**
 * Errors thrown within these actions will be shown to the user and prevent
 * navigation to the next step.
 */
export const itineraryFlowPackingCompletionActions: FlowCompletionActions = {
  [routeNames.ITINERARY_FLOW_UNPACKED_MAIL_IN_ACTION_PACK_SPECIMEN_BAGS]:
    packSpecimenBagsCompletionAction,
};
