// CENTRIFUGATION STEPS

import { ByID } from '@caresend/types';
import { addAssociatedIDs, getStore } from '@caresend/ui-components';

import { RouteName, routeNames } from '@/router/model';
import { ItineraryFlowParam, ItineraryStep, ItineraryStepMap } from '@/store/modules/itineraryFlow/model';

/**
 * Processing action steps
 *
 * (i.e., centrifugation)
 *
 * /itinerary/:itineraryID/processing-action/:processingActionID
 */
const buildProcessingActionStep = (
  routeName: RouteName,
  processingActionID: string,
  itineraryID: string,
  subSteps?: ByID<ItineraryStepMap>,
): ItineraryStep => addAssociatedIDs(
  {
    associatedParam: ItineraryFlowParam.PROCESSING_ACTION_ID,
    parentAssociatedParam: ItineraryFlowParam.ITINERARY_ID,
    parentRouteName: routeNames.ITINERARY_FLOW_ITINERARY,
    routeName,
    subSteps,
  },
  processingActionID,
  itineraryID,
);

/**
 * Centrifugation > Procedure (Centrifuge Processing Action) sub steps
 *
 * /itinerary/:itineraryID/centrifugation/:processingAction/procedure/:procedureID
 */
const buildCentrifugationProcedureStep = (
  routeName: RouteName,
  procedureID: string,
  processingActionID: string,
  subSteps?: ByID<ItineraryStepMap>,
): ItineraryStep => addAssociatedIDs(
  {
    associatedParam: ItineraryFlowParam.PROCEDURE_ID,
    parentAssociatedParam: ItineraryFlowParam.PROCESSING_ACTION_ID,
    parentRouteName: routeNames.ITINERARY_FLOW_CENTRIFUGATION_PACK_SPECIMEN_BAGS,
    routeName,
    subSteps,
  },
  procedureID,
  processingActionID,
);

const getCentrifugationProcedureScanSpecimenBagStep = (
  procedureID: string,
  processingActionID: string,
): ItineraryStep => buildCentrifugationProcedureStep(
  routeNames.ITINERARY_FLOW_CENTRIFUGATION_PROCEDURE_SCAN_SPECIMEN_BAG,
  procedureID,
  processingActionID,
);

const getCentrifugationProcedureScanSamplesStep = (
  procedureID: string,
  processingActionID: string,
): ItineraryStep => buildCentrifugationProcedureStep(
  routeNames.ITINERARY_FLOW_CENTRIFUGATION_PROCEDURE_SCAN_SAMPLES,
  procedureID,
  processingActionID,
);

const getScanSpecimenBagsStep = (
  processingActionID: string,
  itineraryID: string,
): ItineraryStep => buildProcessingActionStep(
  routeNames.ITINERARY_FLOW_CENTRIFUGATION_SCAN_SPECIMEN_BAGS,
  processingActionID,
  itineraryID,
);

const getScanSamplesStep = (
  processingActionID: string,
  itineraryID: string,
): ItineraryStep => buildProcessingActionStep(
  routeNames.ITINERARY_FLOW_CENTRIFUGATION_SCAN_SAMPLES,
  processingActionID,
  itineraryID,
);

const getCentrifugationInstructionsStep = (
  processingActionID: string,
  itineraryID: string,
): ItineraryStep => buildProcessingActionStep(
  routeNames.ITINERARY_FLOW_CENTRIFUGATION_INSTRUCTIONS,
  processingActionID,
  itineraryID,
);

const getCentrifugationPhotoConfirmationStep = (
  processingActionID: string,
  itineraryID: string,
): ItineraryStep => buildProcessingActionStep(
  routeNames.ITINERARY_FLOW_CENTRIFUGATION_PHOTO_CONFIRMATION,
  processingActionID,
  itineraryID,
);

const getCentrifugationProcedureSteps = (
  procedureID: string,
  processingActionID: string,
): ItineraryStepMap => {
  const scanSpecimenBagsStep = getCentrifugationProcedureScanSpecimenBagStep(
    procedureID,
    processingActionID,
  );
  const scanSamplesStep = getCentrifugationProcedureScanSamplesStep(
    procedureID,
    processingActionID,
  );

  const stepMap = new Map<RouteName, ItineraryStep>([
    [scanSpecimenBagsStep.routeName, scanSpecimenBagsStep],
    [scanSamplesStep.routeName, scanSamplesStep],
  ]);

  return stepMap;
};

const getCentrifugationProcedureStep = (
  processingActionID: string,
  itineraryID: string,
) => {
  const procedures = getStore().getters['waypoint/getProceduresOnWaypointAction'](
    processingActionID,
    true,
  );

  const procedureSubSteps = procedures.reduce<ByID<ItineraryStepMap>>((
    currentSubSteps,
    { id },
  ) => {
    const stepMap = getCentrifugationProcedureSteps(id, processingActionID);

    return {
      ...currentSubSteps,
      [id]: stepMap,
    };
  }, {});

  return buildProcessingActionStep(
    routeNames.ITINERARY_FLOW_CENTRIFUGATION_PACK_SPECIMEN_BAGS,
    processingActionID,
    itineraryID,
    procedureSubSteps,
  );
};

export const getCentrifugationSteps = (
  processingActionID: string,
  itineraryID: string,
): ItineraryStepMap => {
  const scanSpecimenBagsStep = getScanSpecimenBagsStep(processingActionID, itineraryID);
  const scanSamplesStep = getScanSamplesStep(processingActionID, itineraryID);
  const instructionsStep = getCentrifugationInstructionsStep(processingActionID, itineraryID);
  const photoConfirmationStep = getCentrifugationPhotoConfirmationStep(processingActionID, itineraryID);
  const procedureStep = getCentrifugationProcedureStep(processingActionID, itineraryID);
  const stepMap = new Map<RouteName, ItineraryStep>([
    [scanSpecimenBagsStep.routeName, scanSpecimenBagsStep],
    [scanSamplesStep.routeName, scanSamplesStep],
    [instructionsStep.routeName, instructionsStep],
    [photoConfirmationStep.routeName, photoConfirmationStep],
    [procedureStep.routeName, procedureStep],
  ]);

  return stepMap;
};
