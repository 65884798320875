import {
  ByID,
  Certification,
  DbRef,
  DocumentStatus,
  DraftEncryptedUser,
  ElementTimestamp,
  EncryptedUser,
  ItineraryOffer,
  ItineraryOfferStatus,
  LocationObject,
  LocationUser,
  MedicalLicense,
  PhoneNumber,
  Role,
  ShiftOffer,
  ShiftOfferStatus,
  TimeZoneDate,
  UserProcedure,
  UserShiftAvailabilityPreferences,
  UserShiftAvailabilityQuality,
  UserTask,
  UserTaskType,
  Wallet,
} from '@caresend/types';
import {
  AnyGetters,
  AuthModule,
  AuthState,
  ExtendedCustomModule,
  dbSet,
  dbUpdate,
  decryptData,
  firebaseAuth,
  firebaseUnbind,
  initAuthModule,
  toastErrorAndReport,
  toastSuccess,
  updateUserInfoRequest,
} from '@caresend/ui-components';
import {
  formatCentsToDollar,
  formatFullName,
  getUserPath,
  isNullish,
  objectMap,
  timeZoneDateMatchesLocalDate,
} from '@caresend/utils';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import Refiner from 'refiner-js';

import { patientURL } from '@/data/urls';
import { deleteUserRequest, generateAuthTokenRequest } from '@/database/firebase/API';
import { hideIntercomButton, setIntercomUserInfo } from '@/database/intercom/methods';
import { identifyAuthenticatedUserSegment, maybeIdentifyUserAgainSegment } from '@/functions/tracking';
import type { CustomActionContext, CustomStore, RootState } from '@/store/model';

export interface NLCStateInfo {
  stateCode: string;
  addedByNLC: boolean;
}

interface ExtraAuthState {
  wallet?: Wallet;
}

const extraAuthState: ExtraAuthState = {
  wallet: undefined,
};

type S = AuthState & ExtraAuthState;

const extraAuthMutations = {
  'auth/SET_USER_WALLET': (state: S, payload: Wallet | undefined) => {
    state.wallet = payload;
  },

  'auth/SET_USER_DEFAULT_LEAVE_FROM': (
    state: S,
    payload: LocationObject | undefined,
  ) => {
    state.user = update(state.user, {
      $merge: {
        shiftSettings: {
          ...state.user?.shiftSettings,
          defaultLeaveFrom: payload,
        },
      },
    });
  },

  'auth/SET_USER_DEFAULT_LEAVE_TO': (
    state: S,
    payload: LocationObject | undefined,
  ) => {
    state.user = update(state.user, {
      $merge: {
        shiftSettings: {
          ...state.user?.shiftSettings,
          defaultLeaveTo: payload,
        },
      },
    });
  },

  'auth/SET_USER_AVAILABILITY_QUALITY': (
    state: S,
    availabilityQuality: UserShiftAvailabilityQuality | undefined,
  ) => {
    state.user = update(state.user, {
      $merge: {
        shiftSettings: {
          ...state.user?.shiftSettings,
          availabilityQuality,
        },
      },
    });
  },

  'auth/SET_SHIFT_AVAILABILITY_QUALITY': (
    state: S,
    availabilityQuality: UserShiftAvailabilityQuality | undefined,
  ) => {
    const getUpdatedAvailabilityPreferences = (): UserShiftAvailabilityPreferences => {
      let updatedAvailabilityPreferences: UserShiftAvailabilityPreferences = {};

      const availabilityPreferences = state.user?.shiftSettings?.availabilityPreferences;

      Object.entries(availabilityPreferences ?? {}).forEach(([dayOfWeek, shiftAvailabilityArray]) => {
        const updatedShiftAvailability = shiftAvailabilityArray.map((shiftAvailability) => ({
          ...shiftAvailability,
          availabilityQuality,
        }));

        updatedAvailabilityPreferences = {
          ...updatedAvailabilityPreferences,
          [dayOfWeek]: updatedShiftAvailability,
        };
      });

      return updatedAvailabilityPreferences;
    };

    state.user = update(state.user, {
      $merge: {
        shiftSettings: {
          ...state.user?.shiftSettings,
          availabilityPreferences: getUpdatedAvailabilityPreferences(),
        },
      },
    });
  },

};

type ExtraAuthActionContext = CustomActionContext<'auth', S>

export interface SearchProductsParams {
  query?: string;
  filters?: string;
}

export type ExtraAuthActions = {
  'auth/bindUserDependencies': (
    context: ExtraAuthActionContext,
  ) => Promise<void>;

  'auth/deleteAccount': (
    context: ExtraAuthActionContext,
  ) => Promise<void>;

  'auth/editUserTaskType': (
    context: ExtraAuthActionContext,
    newTaskType: UserTaskType
  ) => void;

  'auth/unbindUserDependencies': (
    context: ExtraAuthActionContext,
  ) => Promise<void>;

  'auth/updateFax': (
    context: ExtraAuthActionContext,
    payload: {
      faxNumber: PhoneNumber;
    }
  ) => Promise<void>;

  'auth/updatePhone': (
    context: ExtraAuthActionContext,
    payload: {
      phone: PhoneNumber;
    }
  ) => Promise<void>;

  'auth/updateUser': (
    context: ExtraAuthActionContext,
    payload: {
      updatedUser: DraftEncryptedUser;
    }
  ) => Promise<void>;

  'auth/setUserAvailabilityQuality': (
    context: ExtraAuthActionContext,
    availabilityQuality: UserShiftAvailabilityQuality | undefined,
  ) => void;

  'auth/updateUserPicture': (
    context: ExtraAuthActionContext,
    payload: { filePath: string }
  ) => Promise<void>;
}

const extraAuthActions: ExtraAuthActions = {
  'auth/setUserAvailabilityQuality': ({ commit }, availabilityQuality) => {
    commit('auth/SET_USER_AVAILABILITY_QUALITY', availabilityQuality);
    commit('auth/SET_SHIFT_AVAILABILITY_QUALITY', availabilityQuality);
  },

  'auth/bindUserDependencies': async ({ commit, dispatch, state }) => {
    const { user } = state;
    if (!user) return;

    // `ui-components` is responsible for binding the office for office staff.

    if (!isNullish(user.shifts)) {
      dispatch('shifts/bindUserShifts');
    }

    if (user.wallet) {
      const decryptedWallet = await decryptData(user.wallet);
      commit('auth/SET_USER_WALLET', decryptedWallet);
      if (decryptedWallet.transactions) {
        dispatch('transactions/fetchTransactions', { transactionIDs: decryptedWallet.transactions });
      }
    }

    if (user.placeAccounts) {
      const placeAccountIDs = Object.keys(user.placeAccounts);
      dispatch('places/fetchPlaceAccountsAndGroups', { placeAccountIDs });
    }
  },

  'auth/deleteAccount': async ({ state }) => {
    try {
      const { user } = state;
      if (!user) return;

      await deleteUserRequest({ userID: user.id });
      toastSuccess('Your account has been deleted');
    } catch {
      toastErrorAndReport(
        'An error has occurred while trying to delete your account.',
      );
    }
  },

  'auth/editUserTaskType': ({ state }, newTaskType) => {
    dbSet<UserTaskType>(
      `${DbRef.USERS}/${state.user?.id}/taskTypes/${newTaskType.id}`,
      newTaskType,
    );
  },

  'auth/unbindUserDependencies': async ({ dispatch, state }) => {
    const { user } = state;

    if (user !== undefined) {
      if (user.shifts !== undefined) {
        Object.keys(user.shifts).forEach((shiftID) =>
          dispatch('shifts/unbindShift', { id: shiftID }),
        );
      }

      firebaseUnbind(`${DbRef.USERS}/${user.id}`);
    }
  },

  'auth/updateFax': async ({ state }, { faxNumber }) => {
    try {
      const { user } = state;
      if (!user) return;

      const path = getUserPath(user.id, 'info/faxNumber');
      await dbUpdate<PhoneNumber>(path, faxNumber);
      toastSuccess('Your fax number has been updated');
    } catch {
      toastErrorAndReport(
        'An error has occurred while trying to update your phone number.',
      );
    }
  },

  'auth/updatePhone': async ({ state }, { phone }) => {
    try {
      const { user } = state;
      if (!user) return;

      const path = getUserPath(user.id, 'info/phone');
      await dbUpdate<PhoneNumber>(path, phone);
      toastSuccess('Your phone number has been updated');
    } catch {
      toastErrorAndReport(
        'An error has occurred while trying to update your phone number.',
      );
    }
  },

  'auth/updateUser': async ({ state }, { updatedUser }) => {
    const { user } = state;
    if (!user) throw Error('User not found.');

    await updateUserInfoRequest({ updatedUser: updatedUser as EncryptedUser });
  },

  'auth/updateUserPicture': async ({ commit, state }, { filePath }: { filePath: string }) => {
    const { user } = state;
    if (!user) throw Error('User not found.');

    const updatedUser: EncryptedUser = {
      ...user,
      info: {
        ...user.info,
        picture: filePath,
      },
    };

    commit('auth/SET_USER', updatedUser);

    await updateUserInfoRequest({ updatedUser });
  },
};

const extraAuthGetters = {
  'auth/getFormattedWalletBalance': (state: S): string => {
    const balanceInCents = state.wallet?.balance ?? 0;
    return formatCentsToDollar(balanceInCents);
  },

  'auth/getFormattedWalletWithdrawableBalance': (state: S): string => {
    const balanceInCents = state.wallet?.withdrawableBalance ?? 0;
    return formatCentsToDollar(balanceInCents);
  },

  'auth/getWalletTransactions': (state: S): string[] => state.wallet?.transactions ?? [],

  'auth/getUserProcedures': (state: S): UserProcedure[] => {
    if (state.user?.procedures === undefined) return [];
    return Object.values(state.user.procedures);
  },

  'auth/getUserProceduresByTaskID': (state: S): (id: string) => UserProcedure[] =>
    (id) => {
      if (state.user?.procedures === undefined) return [];
      return Object.values(state.user.procedures)
        .filter(({ taskID }) => taskID === id);
    },

  'auth/getUserOffices': (state: S): ElementTimestamp[] | undefined =>
    state.user?.offices,

  'auth/getUserOfficeID': (state: S): string | null =>
    state.user?.offices?.[0]?.id ?? null,

  'auth/getUserWorkLocation': (state: S): LocationObject | undefined => {
    if (state.user?.location?.fromCustomLocation) {
      return state.user?.location?.custom;
    }
    return state.user?.location?.current;
  },

  'auth/getStartTimeItineraryByID': (state: S): (id: string) => TimeZoneDate | undefined =>
    (id) => {
      if (state.user?.itineraryOffers === undefined) return;
      const itineraryOffer = state.user?.itineraryOffers[id];
      if (!itineraryOffer) return;
      return itineraryOffer.startTime;
    },

  'auth/getUserShifts': (state: S): string[] => {
    if (!state.user?.shifts) return [];
    const shiftsArray = Object.keys(state.user?.shifts);
    return shiftsArray;
  },

  'auth/getUserItineraryOffers': (state: S) =>
    Object.values(state.user?.itineraryOffers ?? {})
      .filter((offer) => offer.status !== ItineraryOfferStatus.DECLINED),

  'auth/getUserItineraryOffersByDate': (state: S): (date: string) => ItineraryOffer[] =>
    (date) => extraAuthGetters['auth/getUserItineraryOffers'](state)
      .filter(({ startTime }) => timeZoneDateMatchesLocalDate(startTime, date)),

  'auth/getUserShiftOffers': (state: S): ShiftOffer[] =>
    Object.values(state.user?.shiftOffers ?? {})
      .filter((offer) => offer.status !== ShiftOfferStatus.DECLINED),

  'auth/getUserTaskTypeByID': (state: S): (id: string | undefined) => UserTaskType | undefined =>
    (id) => {
      if (!id) return undefined;
      return state.user?.taskTypes?.[id];
    },

  'auth/getUserTaskByID': (state: S): (id: string | undefined) => UserTask | undefined =>
    (id) => {
      if (!id) return undefined;
      return state.user?.tasks?.[id];
    },

  'auth/getUserLocation': (state: S): LocationUser | undefined =>
    state.user?.location,

  'auth/getUserTimeZone': (state: S): string =>
    state.user?.info.address?.timeZone ?? dayjs.tz.guess(),

  'auth/getNurseApprovedStates': (
    state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ): ByID<NLCStateInfo> => {
    if (!state.user?.certifications) return {};
    const licensesAndCertifications: (MedicalLicense | Certification)[] = [
      ...state.user.certifications,
      ...state.user.professionalInfo?.licences ?? [],
    ];
    const NLC = rootState.variables.variables?.NLCStates;
    if (!NLC) return {};

    let stateObject: ByID<NLCStateInfo> = {};

    const addAllNLCStatesToList = () => {
      const addedEntries = objectMap(
        NLC,
        (stateCode: string): NLCStateInfo => ({
          stateCode,
          addedByNLC: true,
        }));

      stateObject = {
        ...stateObject,
        ...addedEntries,
      };
    };

    licensesAndCertifications.forEach((
      val: MedicalLicense | Certification,
    ) => {
      const { status, stateCode } = val;
      if (!stateCode) return;
      if (status !== DocumentStatus.APPROVED) return;

      if (NLC[stateCode]) addAllNLCStatesToList();

      stateObject[stateCode] = {
        stateCode,
        addedByNLC: false,
      };
    });

    return stateObject;
  },
};

const authModuleExtension = {
  actions: extraAuthActions,
  getters: extraAuthGetters,
  mutations: extraAuthMutations,
  state: extraAuthState,
};

export const authModule: ExtendedCustomModule<
  AuthModule,
  typeof authModuleExtension
> = initAuthModule(authModuleExtension);

export type ExtendedAuthModule = typeof authModule;

export type ExtendedAuthMutations = ExtendedAuthModule['mutations'];
export type ExtendedAuthActions = ExtendedAuthModule['actions'];
export type ExtendedAuthGetters = ExtendedAuthModule['getters'];

export const handleUserBound = async ({ dispatch, state }: CustomStore, user: EncryptedUser) => {
  const userID = user?.id;

  Refiner('identifyUser', {
    id: userID,
    email: user.info.email,
    name: formatFullName(user),
  });

  const { role } = user;
  const { firstName, lastName, email } = user.info;
  if (role === Role.PATIENT) {
    const { token } = await generateAuthTokenRequest({ userID });
    firebaseAuth.signOut();
    window.open(`${patientURL}?token=${token}`, '_self');
    return;
  } if (role === Role.NURSE) {
    hideIntercomButton();
  }

  setIntercomUserInfo({ firstName: firstName ?? '', lastName: lastName ?? '', email, userID });
  await dispatch('auth/bindUserDependencies');

  identifyAuthenticatedUserSegment(user, state.office);
};

export const handleUserObjectUpdated = async (
  { state }: CustomStore,
  user: EncryptedUser,
  prevUser: EncryptedUser,
) => {
  maybeIdentifyUserAgainSegment(user, prevUser, state.office);
};

export const handleUserUnbound = async ({ dispatch }: CustomStore) => {
  dispatch('auth/unbindUserDependencies');
  dispatch('app/resetStore');
};
