import { TrackingEvent } from '@caresend/types';
import { getRoute } from '@caresend/ui-components';

import { DurationDeviationReason, LocationDeviationReason } from '@/components/shared/WaypointCompliance/model';
import { getActualWaypointDuration, getEstimatedWaypointDuration } from '@/functions/itinerary/waypoints';
import { trackEvent } from '@/functions/tracking/tracking';
import { DeviationChecks, FailedDeviationCheck, WaypointDeviationName } from '@/store/modules/itineraryFlow/model';

export const trackWaypointDelay = async (
  itineraryID: string,
  waypointID: string,
  reason?: DurationDeviationReason,
  tooFarReason?: LocationDeviationReason,
) => trackEvent(TrackingEvent.WAYPOINT_DELAY, {
  itineraryID: itineraryID,
  waypointID: waypointID,
  timestamp: Date.now(),
  estimatedWaypointDuration: `${getEstimatedWaypointDuration(waypointID)} minutes`,
  actualWaypointDuration: `${getActualWaypointDuration(waypointID)} minutes`,
  reason,
  tooFarReason,
}, true);

/** Do not call until all reasons set */
export const trackWaypointDelayFromDeviationChecks = async (
  deviationChecks: DeviationChecks,
) => {
  const {
    itineraryID,
    waypointID,
  } = getRoute().value.params;
  if (!itineraryID || !waypointID) throw Error('Missing itinerary or waypoint ID');

  let durationDeviationReason: DurationDeviationReason | undefined;
  let locationDeviationReason: LocationDeviationReason | undefined;

  const durationCheck = deviationChecks.failedChecks
    .find((check): check is FailedDeviationCheck<WaypointDeviationName.DURATION> =>
      check.name === WaypointDeviationName.DURATION,
    );
  if (durationCheck) {
    if (!durationCheck.reason) throw Error('Missing duration deviation reason');
    durationDeviationReason = durationCheck.reason;
  }

  const locationCheck = deviationChecks.failedChecks
    .find((check): check is FailedDeviationCheck<WaypointDeviationName.LOCATION> =>
      check.name === WaypointDeviationName.LOCATION,
    );
  if (locationCheck) {
    if (!locationCheck.reason) throw Error('Missing location deviation reason');
    locationDeviationReason = locationCheck.reason;
  }

  return trackWaypointDelay(
    itineraryID,
    waypointID,
    durationDeviationReason,
    locationDeviationReason,
  );
};
