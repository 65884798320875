import { ByID, Itinerary, Shift } from '@caresend/types';
import dayjs from 'dayjs';

export const getNextDay = (
  items: ByID<Shift>,
  currentDaysToLoad: number,
  displayPastItems: boolean,
) => {
  const futureItems = Object.values(items)
    .filter(
      (shift) => {
        if (displayPastItems) {
          return dayjs(shift.startTime?.timestamp).unix() < dayjs().add(currentDaysToLoad, 'day').unix();
        }
        return dayjs(shift.startTime?.timestamp).unix() > dayjs().add(currentDaysToLoad, 'day').unix();
      },
    )
    .sort((a, b) => {
      const start1 = b.startTime.timestamp ?? 0;
      const start2 = a.startTime.timestamp ?? 0;
      return start1 - start2;
    });
  const nextItem = futureItems[0]?.startTime;
  const today = dayjs();
  const nextDay = dayjs(nextItem?.timestamp).tz(nextItem?.timeZone);
  return dayjs(nextDay).diff(today, 'day');
};

export const sortItineraries = (a: Itinerary, b: Itinerary): number => {
  if (!a.startTime?.timestamp || !b.startTime?.timestamp) return 0;
  if (a.startTime.timestamp >= b.startTime.timestamp) return 1;
  if (a.startTime.timestamp <= b.startTime.timestamp) return -1;
  return 0;
};
