import { NonEmptyArray } from '@caresend/types';
import { FlowStepValidationFailuresGetter, FlowStepValidators, getStore } from '@caresend/ui-components';
import { Route } from 'vue-router';

import { routeNames } from '@/router/model';
import { CentrifugationState } from '@/store/modules/itineraryFlow/model';

const getCentrifugationScanSpecimenBagsStepFailures: FlowStepValidationFailuresGetter = (
  route: Route,
): NonEmptyArray<string> | null => {
  const store = getStore();
  const centrifugationState: CentrifugationState
    = store.state.itineraryFlow.localState.centrifugation;
  const allSpecimenBagsScanned = centrifugationState.initialSpecimenBagsScanned[
    route.params.processingActionID ?? ''
  ];
  if (!allSpecimenBagsScanned) return ['All specimen bags must be scanned'];

  return null;
};

const getScanSamplesStepFailures: FlowStepValidationFailuresGetter = (
  route: Route,
): NonEmptyArray<string> | null => {
  const store = getStore();
  const centrifugationState: CentrifugationState
    = store.state.itineraryFlow.localState.centrifugation;
  const allSamplesScanned = centrifugationState.samplesRequiringCentrifugationScanned[
    route.params.processingActionID ?? ''
  ];
  if (!allSamplesScanned) return ['All samples must be scanned'];

  return null;
};

const getPhotoConfirmationStepFailures: FlowStepValidationFailuresGetter = (
  route: Route,
): NonEmptyArray<string> | null => {
  const store = getStore();
  const centrifugationState: CentrifugationState
    = store.state.itineraryFlow.localState.centrifugation;
  if (!centrifugationState.photo[route.params.processingActionID ?? '']) {
    return ['A photo is required for confirmation'];
  }

  return null;
};

const getPackSpecimenBagsStepFailures: FlowStepValidationFailuresGetter = (
  route: Route,
): NonEmptyArray<string> | null => {
  const store = getStore();
  const { processingActionID } = route.params;
  if (!processingActionID) return ['ProcessingActionID is missing'];

  const procedures = store.getters['waypoint/getProceduresOnWaypointAction'](
    processingActionID,
    true,
  );
  if (!procedures.length) return ['No procedures found'];

  const centrifugationState: CentrifugationState
    = store.state.itineraryFlow.localState.centrifugation;
  const someProcedureNeedsNewSpecimenBag = procedures.some(
    (procedure) => !centrifugationState?.centrifugedProcedures[procedure.id],
  );
  if (someProcedureNeedsNewSpecimenBag) return ['Procedure(s) must be packed'];

  return null;
};

const getProcedureScanSpecimenBagStepFailures: FlowStepValidationFailuresGetter = (
  route: Route,
): NonEmptyArray<string> | null => {
  const store = getStore();
  const { procedureID } = route.params;
  if (!procedureID) return ['ProcedureID is missing'];

  const procedures = store.state.procedures.procedures[procedureID];
  if (!procedures) return ['No procedure found'];

  const centrifugationState: CentrifugationState
    = store.state.itineraryFlow.localState.centrifugation;
  const isNewSpecimenBagScanned = centrifugationState?.newSpecimenBagsScanned[procedureID];
  if (!isNewSpecimenBagScanned) return ['A new specimen bag must be scanned'];

  return null;
};

const getProcedureScanSamplesStepFailures: FlowStepValidationFailuresGetter = (
  route: Route,
): NonEmptyArray<string> | null => {
  const store = getStore();
  const { procedureID } = route.params;
  if (!procedureID) return ['ProcedureID is missing'];

  const procedure = store.state.procedures.procedures[procedureID];
  if (!procedure) return ['No procedure found'];

  const procedureSampleSupplyItemIDs = Object.values(procedure.specimenBag?.relatedSupplyItems ?? {})
    .map((supplyItem) => supplyItem.id);
  const centrifugationState: CentrifugationState
    = store.state.itineraryFlow.localState.centrifugation;
  const sampleSupplyItemIDs = centrifugationState?.samplesScannedForNewSpecimenBag[procedureID];
  if (
    !sampleSupplyItemIDs
    || !sampleSupplyItemIDs.length
    || procedureSampleSupplyItemIDs.some((sampleID) => !sampleSupplyItemIDs.includes(sampleID))
  ) return ['All samples must be scanned'];

  return null;
};

/**
 * Validators to determine when the `Next` button should be enabled on
 * itinerary flow steps.
 */
export const itineraryFlowCentrifugationStepValidators: FlowStepValidators = {
  [routeNames.ITINERARY_FLOW_CENTRIFUGATION_SCAN_SPECIMEN_BAGS]:
    getCentrifugationScanSpecimenBagsStepFailures,
  [routeNames.ITINERARY_FLOW_CENTRIFUGATION_SCAN_SAMPLES]:
    getScanSamplesStepFailures,
  [routeNames.ITINERARY_FLOW_CENTRIFUGATION_PHOTO_CONFIRMATION]:
    getPhotoConfirmationStepFailures,
  [routeNames.ITINERARY_FLOW_CENTRIFUGATION_PACK_SPECIMEN_BAGS]:
    getPackSpecimenBagsStepFailures,
  [routeNames.ITINERARY_FLOW_CENTRIFUGATION_PROCEDURE_SCAN_SPECIMEN_BAG]:
    getProcedureScanSpecimenBagStepFailures,
  [routeNames.ITINERARY_FLOW_CENTRIFUGATION_PROCEDURE_SCAN_SAMPLES]:
    getProcedureScanSamplesStepFailures,
};
