import { computed } from 'vue';
import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';

const routesWorkSettings: RouteConfig[] = [{
  path: '/:partnerName?/work-settings',
  name: routeNames.WORK_SETTINGS,
  component: () => import(
    /* webpackChunkName: 'chunk-profile' */
    '@/components/WorkSettings/WorkSettingOptions.vue'
  ),
  meta: {
    authRequired: true,
    profileCompletedRequired: true,
    heading: () => computed(() => ({
      title: 'Work Settings',
      backLocation: {
        path: '/',
      },
    })),
  },
},
{
  path: '/:partnerName?/work-settings/availability',
  name: routeNames.AVAILABILITY,
  component: () => import(
    /* webpackChunkName: 'chunk-profile' */
    '@/components/WorkSettings/subComponents/Availability.vue'
  ),
  meta: {
    authRequired: true,
    profileCompletedRequired: true,
    heading: () => computed(() => ({
      title: 'Availability',
      backLocation: {
        name: routeNames.WORK_SETTINGS,
      },
    })),
  },
},
{
  path: '/:partnerName?/work-settings/location-settings',
  name: routeNames.LOCATION_SETTINGS,
  component: () => import(
    /* webpackChunkName: 'chunk-profile' */
    '@/components/WorkSettings/subComponents/LocationSettings.vue'
  ),
  meta: {
    authRequired: true,
    profileCompletedRequired: true,
    heading: () => computed(() => ({
      title: 'Location Settings',
      backLocation: {
        name: routeNames.WORK_SETTINGS,
      },
    })),
  },
},
{
  path: '/:partnerName?/work-settings/work-area',
  name: routeNames.WORK_AREA_SETTINGS,
  component: () => import(
    /* webpackChunkName: 'chunk-profile' */
    '@/components/WorkSettings/WorkAreaSettings/WorkAreaSettings.vue'
  ),
  meta: {
    authRequired: true,
    profileCompletedRequired: true,
  },
},
{
  path: '/:partnerName?/work-settings/shift-preferences',
  name: routeNames.SHIFT_PREFERENCES,
  component: () => import(
    /* webpackChunkName: 'chunk-profile' */
    '@/components/WorkSettings/ShiftPreferences/ShiftPreferences.vue'
  ),
  meta: {
    authRequired: true,
    profileCompletedRequired: true,
  },
},
{
  path: '/:partnerName?/work-settings/my-procedures',
  name: routeNames.MY_PROCEDURES_SETTINGS,
  component: () => import(
    /* webpackChunkName: 'chunk-profile' */
    '@/components/WorkSettings/MyProceduresSettings/MyProceduresSettings.vue'
  ),
  meta: {
    authRequired: true,
    profileCompletedRequired: true,
  },
},
{
  path: '/:partnerName?/work-settings/service-region-settings/:serviceRegionGroupID',
  name: routeNames.SERVICE_REGION_SETTINGS,
  component: () => import(
    /* webpackChunkName: 'chunk-profile' */
    '@/components/WorkSettings/WorkAreaSettings/ServiceRegionGroup/ServiceRegionGroup.vue'
  ),
  props: true,
  meta: {
    authRequired: true,
    profileCompletedRequired: true,
  },
}];

export { routesWorkSettings };
