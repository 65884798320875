import { ByName } from '@caresend/types';

export enum PartnerName {
  REMEDY = 'remedy',
}

export interface PartnerLogo {
  size: 'small' | 'medium';
  url: string;
}

export interface PartnerInfo {
  friendlyName: string;
  logos: ByName<PartnerLogo>;
}

export const partnerInfo: { [key in PartnerName]: PartnerInfo } = {
  remedy: {
    friendlyName: 'Remedy',
    logos: {
      small: {
        size: 'small',
        url: 'https://myremedy.com/web/assets/images/logo/header-logo-color-aqua.png',
      },
      medium: {
        size: 'medium',
        url: 'https://myremedy.com/web/assets/images/logo/header-logo-color-aqua.png',
      },
    },
  },
};
