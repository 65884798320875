import { HeadingValues } from '@caresend/ui-components';
import { formatSupplyShipmentCarrier } from '@caresend/utils';
import { ComputedRef, computed } from 'vue';
import { RouteConfig } from 'vue-router';

import { HeadingParams, routeNames } from '@/router/model';

/**
 * Returns back step in order. If one is not found (first step), returns
 * Shipping page.
 */
export const getSupplyShipmentBackLocation = (
  { store, route }: HeadingParams,
): HeadingValues['backLocation'] => {
  const backStep = store.getters[
    'supplies/getBackSupplyShipmentStepInOrder'
  ](route) ?? undefined;
  const backLocation = backStep
    ? { name: backStep.routeName }
    : { name: routeNames.SHIPPING };
  return backLocation;
};

const headingWithSupplyShipmentBackNav = (title: string) => (
  headingParams: HeadingParams,
): ComputedRef<HeadingValues> => computed(() => ({
  backLocation: getSupplyShipmentBackLocation(headingParams),
  title,
}));

// Paths below are not full paths - they are based on the parent path:
// /:partnerName?/ship
export const routesSupplyShipment: RouteConfig[] = [
  {
    path: 'scan-shipping-box',
    name: routeNames.SUPPLY_SHIPMENT_SCAN_SUPPLY_BOX,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-shipping' */
      '@/views/packer/supplyShipmentFlow/steps/supplyShipment/SupplyShipmentScanShippingBox.vue'
    ),
    meta: {
      authRequired: true,
      // TODO: Correct text in dynamic heading. User name is just for example.
      heading: (params: HeadingParams) => computed<HeadingValues>(() => {
        const { store } = params;
        const { supplyShipmentID } = params.route.params;
        const supplyShipment = store.getters['supplies/getSupplyShipmentByID'](supplyShipmentID);
        const delivery = supplyShipment?.deliveryMethod;
        const heading = !delivery
          ? ''
          : `Submit to ${formatSupplyShipmentCarrier(delivery)}`;
        return headingWithSupplyShipmentBackNav(heading)(params).value;
      }),
      whiteBackground: true,
    },
  },
];
