import {
  Barcode,
  ByID,
  DbRef,
  EncryptedFile,
  FeatureFlag,
  Procedure,
  Sample,
  SampleCollectionFailure,
  SampleStatus,
  Shift,
  Shipment,
  ShopifyFulfillmentStatus,
  SupplyItemStatus,
  SupplyItemWithSupplyID,
  SupplyShipment,
  SupplyShipmentStatus,
  SupplyShipmentStatusName,
  SupplyTransferStatus,
  SupplyTransferStatusName,
  TrackingEvent,
  Waypoint,
} from '@caresend/types';
import {
  dbGroupSet,
  dbSet,
  dbUpdate,
  getStore,
  toastError,
  toastErrorAndReport,
} from '@caresend/ui-components';
import {
  MaybeAddProcedureFailedUpdateType,
  arrayToObj,
  getProcedurePath,
  getProcedureSamplePath,
  getShiftPath,
  getShipmentPath,
  getSupplyItemPath,
  getSupplyTransferPath,
} from '@caresend/utils';

import { getProcedureFailedUpdates } from '@/functions/procedures';
import { getPreviousItemFromBag, getSupplyItemsFromItemsToScan } from '@/functions/supplies/get';
import { ScannableItem, getInstructionIsSet } from '@/functions/supplies/scanning';
import { trackEvent } from '@/functions/tracking/tracking';
import { ExtendedSample } from '@/store/modules/itineraryFlow/model';
import {
  SetOrUnsetProcedureKit,
  SetOrUnsetProcedureSpecimenBag,
  SetProcedureInsulatedBag,
  SetProcedureSpecimenBagSupplyItem,
  SetSampleAmberBag,
} from '@/store/modules/procedures/model';
import { SetOrUnset } from '@/store/modules/supplies/model';
import { maybeUpdateWaypointActionStatus } from '@itineraryFlow/helpers/general';

// SUPPLY ITEM

/**
 * Updates status of a supply item, unless the supply item ID is
 * `disablePatientVisitSupplyScanning`.
 */
export const updateSupplyItemStatus = async (
  supplyItemID: string,
  status: SupplyItemStatus,
) => {
  // When scanning is disabled via feature flag, the feature flag name is
  // used in place of the supplyItemID. Do not attempt to update the supply
  // item status in this case.
  if (supplyItemID !== FeatureFlag.DISABLE_PATIENT_VISIT_SUPPLY_SCANNING) {
    await dbSet<SupplyItemStatus>(
      `${DbRef.SUPPLY_ITEMS}/${supplyItemID}/status`,
      status,
    );
  }
};

const updateSupplyItemsAtRef = async (
  supplyItems: ByID<SupplyItemWithSupplyID>,
  ref: DbRef,
  id: string,
): Promise<void> => {
  const store = getStore();

  const updates: Record<string, SupplyItemWithSupplyID> = {};

  Object.values(supplyItems).forEach((supplyItem) => {
    updates[`${ref}/${id}/supplyItems/${supplyItem.id}`] = {
      id: supplyItem.id,
      supplyID: supplyItem.supplyID ?? 'undefined supplyID',
    };
  });

  await dbGroupSet<SupplyItemWithSupplyID>(updates);
  const currentProcedure = store.getters['procedures/getProcedureByID'](id);
  if (currentProcedure) {
    await store.commit('procedures/SET_PROCEDURE', {
      ...currentProcedure,
      supplyItems: {
        ...currentProcedure.supplyItems,
        ...supplyItems,
      },
    });
  }
};

export const updateScannedSupplyItemsAtRef = async (
  itemsToScan: ScannableItem[],
  ref: DbRef,
  id: string,
): Promise<void> => {
  const supplyItems = await getSupplyItemsFromItemsToScan(
    itemsToScan,
  );

  await updateSupplyItemsAtRef(supplyItems, ref, id);
};

/**
 * Given a list of scannable items that have no missing supply item IDs or
 * supply IDs, updates the supply items at the given reference. This is meant
 * for use with click mode, where we do not have barcodes and use real supply
 * IDs, but assign random supply item IDs.
 * Example format:
 * ```
 * {
 *   scanDisabled-p03dqULdo1Ws1wybJczo: {
 *     supplyID: '6gorcl0h6v3kkox7j2e',
 *     supplyItemID: 'scanDisabled-p03dqULdo1Ws1wybJczo',
 *   }
 * }
 * ```
 */
export const updateClickedSupplyItemsAtRef = async (
  itemsToScan: ScannableItem[],
  ref: DbRef,
  id: string,
): Promise<void> => {
  const supplyItemsArray: SupplyItemWithSupplyID[] = itemsToScan.map((item) => {
    const { supplyItemID, supplyID } = item;
    if (!supplyItemID) throw Error('Missing supply item ID');
    if (!supplyID) throw Error('Missing supply ID');
    return {
      id: supplyItemID,
      supplyID,
    };
  });
  const supplyItems = arrayToObj(supplyItemsArray, 'id');

  await updateSupplyItemsAtRef(supplyItems, ref, id);
};

// PLACE

export const addSuppliesToPlace = async (
  scannedSupplies: ScannableItem[],
): Promise<void> => {
  try {
    const supplyItems = await getSupplyItemsFromItemsToScan(scannedSupplies);
    const updates: Record<string, SupplyItemStatus | boolean> = {};
    const placeID = getStore().state.places.userAssociatedPlaceID;
    if (!placeID) throw new Error('No place ID found');
    Object.values(supplyItems).forEach((supplyItem) => {
      updates[`${DbRef.PLACES}/${placeID}/supplies/supplyItems/${supplyItem.id}`] = true;
      updates[getSupplyItemPath(supplyItem.id, 'status')] = SupplyItemStatus.ACTIVATED;
    });

    await dbGroupSet<SupplyItemStatus | boolean>(updates);
    await trackEvent(TrackingEvent.SUPPLIES_ADDED_TO_LOCATION, {
      placeID,
      supplyItemIDs: Object.keys(supplyItems),
    });
  } catch (error) {
    toastErrorAndReport('Error adding supplies to place', error);
  }
};

// PROCEDURE

const setOrUnsetSampleSupplyItem = async (
  instruction: 'set' | 'unset',
  procedureID: string,
  sampleID: string,
  supplyItemID: string,
): Promise<void> => {
  const set = instruction === 'set';
  const newSupplyItemID = set ? supplyItemID : null;
  const newStatus = set ? SupplyItemStatus.USED : SupplyItemStatus.ACTIVATED;

  try {
    await updateSupplyItemStatus(supplyItemID, newStatus);
    await dbSet<Sample['supplyItemID'] | null>(
      getProcedureSamplePath(procedureID, sampleID, 'supplyItemID'),
      newSupplyItemID,
    );
    getStore().commit('procedures/SET_PROCEDURE_SAMPLE_SUPPLY_ITEM', {
      sampleID,
      procedureID,
      supplyItemID: newSupplyItemID ?? undefined,
    });
  } catch (error) {
    toastErrorAndReport(error);
  }
};

/**
 * - Sets supplyItemID on procedure sample
 * - Sets supplyItem to `used`.
 */
export const setSampleSupplyItem = async (
  procedureID: string,
  sampleID: string,
  supplyItemIdToSet: string,
): Promise<void> => {
  await setOrUnsetSampleSupplyItem('set', procedureID, sampleID, supplyItemIdToSet);
};

/**
 * - Unsets supplyItemID on procedure sample
 * - Sets supplyItem to `activated`.
 */
export const unsetSampleSupplyItem = async (
  procedureID: string,
  sampleID: string,
  supplyItemIdToUnset: string,
): Promise<void> => {
  await setOrUnsetSampleSupplyItem('unset', procedureID, sampleID, supplyItemIdToUnset);
};

export const updateSampleAmberBag = async (
  {
    procedureID,
    sampleID,
    amberBagSupplyItemID,
    amberBagSupplyID,
    tubeSupplyItemID,
    currentSampleAmberBag,
  }: SetSampleAmberBag,
): Promise<void> => {
  const store = getStore();

  const updates: Record<string, SupplyItemWithSupplyID | SupplyItemStatus | null> = {};
  const procedure: Procedure | undefined = store.getters['procedures/getProcedureByID'](procedureID);
  const sample: Sample | undefined
    = procedure?.samples?.[sampleID];
  if (!sample || !amberBagSupplyID || !tubeSupplyItemID) return;

  const amberBag: SupplyItemWithSupplyID | null = amberBagSupplyItemID
    ? {
      id: amberBagSupplyItemID,
      supplyID: amberBagSupplyID,
      relatedSupplyItems: {
        [tubeSupplyItemID]: {
          id: tubeSupplyItemID,
          supplyID: sample.supplyID,
        },
      },
    }
    : null;

  if (amberBagSupplyItemID) {
    updates[`${DbRef.PROCEDURES}/${procedureID}/amberBags/${amberBagSupplyItemID}`] = amberBag;
    updates[`${DbRef.SUPPLY_ITEMS}/${amberBagSupplyItemID}/status`] = SupplyItemStatus.USED;
  } else if (currentSampleAmberBag) {
    updates[`${DbRef.PROCEDURES}/${procedureID}/amberBags/${currentSampleAmberBag.id}`] = null;
    updates[`${DbRef.SUPPLY_ITEMS}/${currentSampleAmberBag.id}/status`] = SupplyItemStatus.ACTIVATED;
  }

  try {
    await dbGroupSet<SupplyItemWithSupplyID | SupplyItemStatus | null>(updates);
    if (amberBag) {
      store.commit('procedures/SET_PROCEDURE_SAMPLE_AMBER_BAG', {
        sampleID,
        procedureID,
        amberBag,
      });
    } else if (currentSampleAmberBag?.id) {
      store.commit('procedures/UNSET_PROCEDURE_SAMPLE_AMBER_BAG', {
        sampleID,
        procedureID,
        amberBagID: currentSampleAmberBag.id,
      });
    }
  } catch (error) {
    toastErrorAndReport(error);
  }
};

export const updateProcedureInsulatedBag = async (
  {
    procedureID,
    sampleID,
    insulatedBagSupplyItemID,
    insulatedBagSupplyID,
  }: SetProcedureInsulatedBag,
): Promise<void> => {
  const previousSupplyItem = getPreviousItemFromBag(
    procedureID,
    sampleID,
    true,
  );
  const updates: Record<string, string | SupplyItemWithSupplyID | null> = {};

  if (previousSupplyItem) {
    updates[`${DbRef.PROCEDURES}/${procedureID}/insulatedBag/id`] = insulatedBagSupplyItemID;
    updates[`${DbRef.PROCEDURES}/${procedureID}/insulatedBag/supplyID`] = insulatedBagSupplyID;
    updates[`${DbRef.PROCEDURES}/${procedureID}/insulatedBag/relatedSupplyItems/${previousSupplyItem.supplyItem.id}`]
      = previousSupplyItem.supplyItem;

    updates[`${DbRef.SUPPLY_ITEMS}/${insulatedBagSupplyItemID}/status`] = SupplyItemStatus.USED;
    try {
      await dbGroupSet<string | SupplyItemWithSupplyID | null>(updates);
    } catch (error) {
      toastError(error);
    }

    if (insulatedBagSupplyItemID) {
      getStore().commit('procedures/SET_PROCEDURE_INSULATED_BAG', {
        sampleID,
        procedureID,
        insulatedBagSupplyItemID,
        insulatedBagSupplyID,
        previousSupplyItem,
      });
    }
  }
};

export const setOrUnsetProcedureSpecimenBag = async (
  {
    instruction,
    procedureID,
    specimenBagSupplyItemID,
    specimenBagSupplyID,
  }: SetOrUnsetProcedureSpecimenBag,
): Promise<void> => {
  const updates: Record<string, string | null> = {};

  const set = instruction === SetOrUnset.SET;
  const newSupplyItemID = set ? specimenBagSupplyItemID : null;
  const newStatus = set ? SupplyItemStatus.USED : SupplyItemStatus.ACTIVATED;

  updates[getProcedurePath(procedureID, 'specimenBag/id')] = newSupplyItemID;
  updates[getProcedurePath(procedureID, 'specimenBag/supplyID')] = specimenBagSupplyID;

  try {
    await updateSupplyItemStatus(specimenBagSupplyItemID, newStatus);
    await dbGroupSet<string | null>(updates);
    getStore().commit('procedures/SET_PROCEDURE_SPECIMEN_BAG_ID', {
      instruction,
      procedureID,
      specimenBagSupplyItemID,
      specimenBagSupplyID,
    });
  } catch (error) {
    toastError(error);
  }
};

export const setOrUnsetProcedureKit = async ({
  instruction,
  procedureID,
  procedureKitSupplyItemID,
  procedureKitSupplyID,
}: SetOrUnsetProcedureKit): Promise<void> => {
  const newSupplyItem = instruction === SetOrUnset.SET
    ? {
      id: procedureKitSupplyItemID,
      supplyID: procedureKitSupplyID,
    }
    : null;

  try {
    await dbSet<SupplyItemWithSupplyID | null>(
      getProcedurePath(procedureID, 'kitSupplyItem'),
      newSupplyItem,
    );
    getStore().commit('procedures/SET_PROCEDURE_KIT_ID', {
      instruction,
      procedureID,
      procedureKitSupplyItemID,
      procedureKitSupplyID,
    });
  } catch (error) {
    toastError(error);
  }
};

export const updateProcedureSpecimenBagSupplyItem = async (
  {
    procedureID,
    specimenBagSupplyItem,
  }: SetProcedureSpecimenBagSupplyItem,
): Promise<void> => {
  if (!specimenBagSupplyItem) return;

  try {
    await dbUpdate<SupplyItemWithSupplyID>(
      getProcedurePath(
        procedureID,
        `specimenBag/relatedSupplyItems/${specimenBagSupplyItem.id}`,
      ),
      specimenBagSupplyItem,
    );
    getStore().commit('procedures/SET_PROCEDURE_SPECIMEN_BAG_SUPPLY_ITEM', {
      procedureID,
      specimenBagSupplyItem,
    });
  } catch (error) {
    toastError(error);
  }
};

export const updateBypassProcedureSupplyItems = async (
  procedureID: string,
): Promise<void> => {
  const store = getStore();

  const currentProcedure = store.getters['procedures/getProcedureByID'](procedureID);
  if (!currentProcedure) return;
  const supplyItem: SupplyItemWithSupplyID = {
    id: FeatureFlag.DISABLE_PATIENT_VISIT_SUPPLY_SCANNING,
    supplyID: 'unknown supplyID',
  };
  const supplyItems: Procedure['supplyItems'] = {
    [supplyItem.id]: supplyItem,
  };

  try {
    await dbUpdate<Procedure['supplyItems']>(
      getProcedurePath(procedureID, 'supplyItems'),
      supplyItems,
    );
    store.commit('procedures/SET_PROCEDURE', { ...currentProcedure, supplyItems });
  } catch (error) {
    toastError(error);
  }
};

type UpdatesType = Record<string,
  | SampleStatus
  | SampleCollectionFailure
  | number
  | string
  | null
  | MaybeAddProcedureFailedUpdateType
>;

export const updateSampleCollectionStatus = async (
  sample: ExtendedSample | undefined,
  procedureID: string,
  markAsSuccess: boolean,
  collectionFailure?: SampleCollectionFailure,
): Promise<void> => {
  const store = getStore();

  if (!sample) {
    toastErrorAndReport(`Missing sample when updating sample status on procedure ${procedureID}`);
    return;
  }
  const sampleID = sample.id;

  let newStatus: SampleStatus = SampleStatus.COLLECTED;
  const procedure: Procedure | undefined = store.getters['procedures/getProcedureByID'](procedureID);
  if (!procedure) throw toastError(`Missing procedure ${procedureID}`);
  let updates: UpdatesType = {};
  if (!markAsSuccess) {
    newStatus = SampleStatus.FAILED;
    if (!store.getters['auth/getUserRole']) {
      throw Error('Missing user role');
    }
    updates = await getProcedureFailedUpdates(newStatus, procedureID);
    const waypointActionID = Object.keys(procedure.waypointActions ?? {})[0];
    if (!waypointActionID) throw Error('updateSampleCollectionStatus(): Missing waypoint action ID');
    /**
     * Move to a cloud trigger because it's not practical to keep track of all of the places
     * where this needs to be placed
    */
    await maybeUpdateWaypointActionStatus(waypointActionID);
  } else {
    updates[getProcedureSamplePath(procedureID, sampleID, 'collectionTimestamp')]
      = Date.now();
  }

  // `supplyItemID` is normally assigned to samples when they are scanned
  // during charting. If a sample does not support scanning during charting,
  // manually assign the `supplyItemID` now.
  if (sample.noScanAfterCollection && !sample.supplyItemID) {
    const packedSupplyItems = Object.values(procedure?.supplyItems ?? {});
    const firstMatchingSupplyItem = packedSupplyItems.find((supplyItem) =>
      supplyItem.supplyID === sample.supplyID,
    );
    if (firstMatchingSupplyItem) {
      updates[getProcedureSamplePath(procedureID, sampleID, 'supplyItemID')]
        = firstMatchingSupplyItem.id;
      // We need a way to assign a supplyItem to a sample if it is not scanned.
      // But wouldn't it be scanned during procedure bag prep?
      store.commit('procedures/SET_PROCEDURE_SAMPLE_SUPPLY_ITEM', {
        sampleID,
        procedureID,
        supplyItemID: firstMatchingSupplyItem.id,
      });
    }
  }

  updates[getProcedureSamplePath(procedureID, sampleID, 'status')] = newStatus;
  updates[getProcedureSamplePath(procedureID, sampleID, 'collectionFailure')]
    = collectionFailure ?? null;

  await dbGroupSet<UpdatesType>(
    updates,
  );

  store.commit('procedures/SET_PROCEDURE_SAMPLE_STATUS', {
    sampleID,
    procedureID,
    status: newStatus,
    collectionFailure,
  });
};

export const completeDiscardedSample = async (
  sample: ExtendedSample,
  procedureID: string,
): Promise<void> => {
  const sampleID = sample.id;

  await dbSet<SampleStatus>(
    getProcedureSamplePath(procedureID, sampleID, 'status'),
    SampleStatus.COMPLETED,
  );

  getStore().commit('procedures/SET_PROCEDURE_SAMPLE_STATUS', {
    sampleID,
    procedureID,
    status: SampleStatus.COMPLETED,
  });
};

// SHIFT

export const updateShiftBag = async (
  supplyItem: SupplyItemWithSupplyID,
  shiftID: string,
): Promise<void> => {
  const store = getStore();

  try {
    await updateSupplyItemStatus(supplyItem.id, SupplyItemStatus.ACTIVATED);
    await dbUpdate<Shift['supplyItem']>(
      getShiftPath(shiftID, 'supplyItem'),
      supplyItem,
    );
    const currentShift = store.getters['shifts/getShiftByID'](shiftID);
    if (!currentShift) return;
    store.commit('shifts/SET_SHIFT', { ...currentShift, supplyItem });
  } catch (error) {
    toastError(error);
  }
};

// SUPPLY SHIPMENT

export const setOrUnsetShipmentBox = async (
  shipment: Shipment | undefined,
  supplyItem: SupplyItemWithSupplyID | null,
): Promise<boolean> => {
  if (!shipment) { throw toastError('Missing shipment.'); }

  const instruction = supplyItem ? SetOrUnset.SET : SetOrUnset.UNSET;
  const instructionIsSet = getInstructionIsSet(instruction, supplyItem);

  const newSupplyItemStatus = instructionIsSet
    ? SupplyItemStatus.USED
    : SupplyItemStatus.ACTIVATED;
  const supplyItemIdToUpdate = instructionIsSet
    ? supplyItem.id
    : shipment.shipmentBoxSupplyItem?.id;

  if (!supplyItemIdToUpdate) {
    throw toastError('Missing supply item ID for update.');
  }

  try {
    await updateSupplyItemStatus(supplyItemIdToUpdate, newSupplyItemStatus);
    await dbSet<SupplyItemWithSupplyID | null>(
      getShipmentPath(shipment.id, 'shipmentBoxSupplyItem'),
      supplyItem,
    );
    getStore().commit('supplies/UPDATE_SHIPMENT', {
      id: shipment.id,
      shipmentBoxSupplyItem: supplyItem ?? undefined,
    });
    return true;
  } catch (error) {
    toastError(error);
    return false;
  }
};

export const setScannedShippingLabel = async (
  shipment: SupplyShipment | undefined,
  barcode: Barcode,
): Promise<boolean> => {
  if (!shipment) { throw toastError('Missing shipment.'); }

  try {
    await dbSet<string>(
      getShipmentPath(shipment.id, 'scannedShippingLabelBarcode'),
      barcode.id,
    );
    getStore().commit('supplies/UPDATE_SHIPMENT', {
      id: shipment.id,
      scannedShippingLabelBarcode: barcode.id,
    });
    return true;
  } catch (error) {
    toastError(error);
    return false;
  }
};

export const setShipmentBoxImage = async (
  shipmentID: string,
  encryptedFile: EncryptedFile | null,
) => {
  try {
    await dbSet<EncryptedFile | null>(
      getShipmentPath(shipmentID, 'shipmentBoxImage'),
      encryptedFile,
    );
    getStore().commit('supplies/UPDATE_SHIPMENT', {
      id: shipmentID,
      shipmentBoxImage: encryptedFile ?? undefined,
    });
  } catch (error) {
    toastErrorAndReport(error);
  }
};

// SUPPLY TRANSFER

export const finalizeSupplyTranferFlow = async (
  supplyTransferID: string,
  supplyShipmentID: string,
): Promise<void> => {
  const store = getStore();

  const updates: Record<string, SupplyShipmentStatus | SupplyTransferStatus> = {};

  const supplyTransferStatus: SupplyTransferStatus = {
    status: SupplyTransferStatusName.PACKED,
    timestamp: Date.now(),
  };
  const supplyShipmentStatus: SupplyShipmentStatus = {
    status: SupplyShipmentStatusName.READY_TO_SHIP,
    timestamp: Date.now(),
  };

  updates[getSupplyTransferPath(supplyTransferID, 'status')] = supplyTransferStatus;
  updates[getShipmentPath(supplyShipmentID, 'status')] = supplyShipmentStatus;

  await dbGroupSet<SupplyShipmentStatus | SupplyTransferStatus>(updates);

  store.commit('supplies/UPDATE_SHIPMENT', {
    id: supplyShipmentID,
    status: supplyShipmentStatus,
  });
  store.commit('supplies/UPDATE_SUPPLY_TRANSFER', {
    id: supplyTransferID,
    status: supplyTransferStatus,
  });
  store.commit('supplies/ADD_COMPLETED_PACKS',
    supplyTransferID,
  );
};

// USER

export const addSuppliesToNurse = async (
  itemsToScan: ScannableItem[],
  fulfillmentID: string,
): Promise<void> => {
  const userID = getStore().state.auth.user?.id;
  if (!userID) return;
  const updates: Record<string, SupplyItemStatus | ShopifyFulfillmentStatus | boolean | null> = {};

  const supplyItems = await getSupplyItemsFromItemsToScan(itemsToScan);

  Object.values(supplyItems).forEach((supplyItem) => {
    updates[`${DbRef.USERS}/${userID}/supplies/supplyItems/${supplyItem.id}`] = true;
    updates[`${DbRef.SUPPLY_ITEMS}/${supplyItem.id}/status`] = SupplyItemStatus.ACTIVATED;
  });

  updates[`${DbRef.USERS}/${userID}/supplies/shopifyFulfillments/${fulfillmentID}/status`]
       = ShopifyFulfillmentStatus.ACTIVATED;
  updates[`${DbRef.USERS}/${userID}/supplies/shopifyFulfillments/${fulfillmentID}/lineItems`] = null;
  await dbGroupSet<SupplyItemStatus | ShopifyFulfillmentStatus | boolean | null>(updates);
};

// WAYPOINT

export const updateWaypointBag = async (
  supplyItem: SupplyItemWithSupplyID,
  waypointID: string,
  isMockSupplyItem: boolean,
): Promise<void> => {
  const store = getStore();

  if (!isMockSupplyItem) {
    await updateSupplyItemStatus(supplyItem.id, SupplyItemStatus.USED);
  }
  await dbUpdate<Waypoint['supplyItem']>(
    `${DbRef.WAYPOINTS}/${waypointID}/supplyItem/`,
    supplyItem,
  );
  const currentWaypoint = store.getters['waypoint/getWaypointByID'](waypointID);
  if (!currentWaypoint) return;
  store.commit('waypoint/SET_WAYPOINT', { ...currentWaypoint, supplyItem });
};
