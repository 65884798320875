import { SampleProcessingAction, SupplyInstructionOptions } from '@caresend/types';
import { HeadingValues, LocationData } from '@caresend/ui-components';
import { ComputedRef, computed } from 'vue';
import { CurrentLocation } from 'vue-router';

import SupportMessengerButton from '@/components/layout/buttons/SupportMessengerButton.vue';
import { centrifugationRoute } from '@/router/locations';
import { HeadingParams } from '@/router/model';
import { getItineraryFlowHelpers } from '@/store/modules/itineraryFlow/steps/root';

export const getBackLocation = (
  { store, route }: HeadingParams,
): HeadingValues['backLocation'] =>
  store.getters['itineraryFlow/getBackStepLocationInItineraryFlow'](route) ?? undefined;

export const getNextLocation = (
  { store, route }: HeadingParams,
): HeadingValues['backLocation'] =>
  store.getters['itineraryFlow/getNextStepLocationInOrder'](route) ?? undefined;

const getConfirmLoading = (
  { store }: HeadingParams,
): HeadingValues['confirmLoading'] =>
  store.state.itineraryFlow.localState.nextButtonLoading;

const getConfirmEvent = (
  { route }: HeadingParams,
): HeadingValues['confirmEvent'] => {
  const helpers = getItineraryFlowHelpers(route);
  const hasCompletionAction = !!helpers.getCurrentStepCompletionAction(route);
  if (!hasCompletionAction) return;

  return () => helpers.performCompletionAction(route);
};

const getConfirmTooltip = (
  { route }: HeadingParams,
): HeadingValues['confirmTooltip'] => {
  const helpers = getItineraryFlowHelpers(route);
  const failures = helpers.getCurrentStepValidationFailures(route);
  return failures?.join('\n');
};

const getNextDisabled = (
  { route }: HeadingParams,
): HeadingValues['confirmDisabled'] => computed<boolean>(() => {
  const helpers = getItineraryFlowHelpers(route);
  const failures = helpers.getCurrentStepValidationFailures(route);
  return !!failures?.length;
});

/**
 * Older steps (patient visit) include footer in each view. Newer steps use
 * the confirm button and secondary components, which render in the footer.
 */
const getGlobalFooterParams = (
  headingParams: HeadingParams,
): Partial<HeadingValues> => {
  const { route } = headingParams;
  if (route.meta?.noGlobalFooter) return {};

  return {
    secondaryComponent: SupportMessengerButton,
    globalFooter: true,
    confirmDisabled: getNextDisabled(headingParams),
    confirmEvent: getConfirmEvent(headingParams),
    confirmLoading: getConfirmLoading(headingParams),
    confirmText: 'Next',
    confirmTo: getNextLocation(headingParams),
    confirmTooltip: getConfirmTooltip(headingParams),
  };
};

export const itineraryFlowHeadingWithNav = (
  title: string,
  overrides?: Partial<HeadingValues>,
) => (
  headingParams: HeadingParams,
): ComputedRef<HeadingValues> => computed(() => ({
  backLocation: getBackLocation(headingParams),
  ...getGlobalFooterParams(headingParams),
  title,
  ...overrides,
}));

export const getSampleProcessingActionRoute = (
  sampleProcessingAction: SampleProcessingAction,
): CurrentLocation | undefined => {
  const { id: processingActionID, supplyInstruction } = sampleProcessingAction;
  const params: LocationData['params'] = { processingActionID };
  switch (supplyInstruction) {
    case SupplyInstructionOptions.CENTRIFUGE:
      return centrifugationRoute({ params });
    default:
      return undefined;
  }
};
