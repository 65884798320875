import {
  AnyGetters,
  ExtendedCustomModule,
  SchedulingFlowModule,
  SchedulingFlowState,
  initSchedulingFlowModule,
} from '@caresend/ui-components';

import { draftWaypointDetailsRoute } from '@/router/locations';
import type { RootState } from '@/store/model';

type S = SchedulingFlowState;

const extraOrderFlowGetters = {
  'schedulingFlow/getVisitDetailsRoute': (
    _state: S,
    _getters: AnyGetters,
    _rootState: RootState,
  ) =>
    (draftWaypointID: string) => draftWaypointDetailsRoute({
      params: {
        draftWaypointID,
      },
    }),
};

const schedulingFlowModuleExtension = {
  getters: extraOrderFlowGetters,
};

export const schedulingFlowModule: ExtendedCustomModule<
  SchedulingFlowModule<RootState>,
  typeof schedulingFlowModuleExtension
> = initSchedulingFlowModule(schedulingFlowModuleExtension);

export type ExtendedSchedulingFlowModule = typeof schedulingFlowModule;

export type ExtendedSchedulingFlowMutations = ExtendedSchedulingFlowModule['mutations'];
export type ExtendedSchedulingFlowActions = ExtendedSchedulingFlowModule['actions'];
export type ExtendedSchedulingFlowGetters = ExtendedSchedulingFlowModule['getters'];
