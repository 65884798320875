import {
  DbRef,
  FeatureFlag,
  LocationObject,
  Shift,
  ShiftGeoCheck,
  ShiftOffer,
  ShiftOfferStatus,
  ShiftStatus,
  TrackingEvent,
  WaypointTransitStatus,
  WaypointType,
} from '@caresend/types';
import {
  dbGroupSet,
  dbSet,
  dbUpdate,
  getStore,
  getValueOnce,
} from '@caresend/ui-components';
import {
  deduplicateArray,
  formatCentsToDollar,
  formatTimeZoneDate,
  getFullLocationObject,
  getServiceRegionFromLocation,
  getShiftPath,
  hoursToMilliseconds,
  initTimeZoneDate,
  isNullish,
  nullishFilter,
  timeInMsToHoursAndMinutes,
} from '@caresend/utils';
import dayjs, { QUnitType } from 'dayjs';

import { getItineraryDrivingEstimateRequest } from '@/database/firebase/API';
import { getCurrentLocation } from '@/functions/getCurrentLocation';
import { getFirstItineraryIDOnShift } from '@/functions/supplies/get';
import { trackEvent } from '@/functions/tracking/tracking';

export interface WaypointTransitInfo {
  status: WaypointTransitStatus | null;
  drivingEstimate: number;
}

export const updateShiftStatus = async (
  shiftID: string,
  status: ShiftStatus,
) => {
  const store = getStore();

  const shift = store.state.shifts.shifts[shiftID];
  if (!shift) {
    throw new Error(`Could not update shift status because shift ${shiftID}
    was not found`);
  }
  /** Update shift in store */
  store.commit(
    'shifts/SET_SHIFT',
    {
      ...shift,
      status: ShiftStatus.IN_PROGRESS,
    },
  );
  /** Update shift in firebase */
  const shiftStatusPath = `${DbRef.SHIFTS}/${shiftID}/status`;
  try {
    await dbSet<ShiftStatus>(shiftStatusPath, status);
  } catch (error) {
    throw new Error(`Could not update shift status because there was an error
    updating the status in firebase: ${error}`);
  }
};

export const howLongUntilShiftStarts = (
  shift: Shift,
  unit: QUnitType,
): number => {
  const { startTime } = shift;
  const shiftStartTime = dayjs.utc(startTime?.timestamp).tz(startTime?.timeZone);
  return shiftStartTime.diff(dayjs().tz(startTime?.timeZone), unit);
};

export const acceptShiftTrackingEvent = (
  shiftID: string,
  leaveFrom: LocationObject,
  leaveTo: LocationObject,
) => {
  const store = getStore();

  const userID = store.getters['auth/getUserID'];
  const timestamp = Date.now();

  trackEvent(TrackingEvent.NURSE_ACCEPTED_SHIFT_OFFER, {
    shiftID,
    userID,
    timestamp,
    leaveFrom,
    leaveTo,
  }, true);
};

export const declineShiftOffer = async (shiftID: string, reason: string): Promise<void> => {
  const store = getStore();

  const userID = store.state.auth.user?.id;
  if (!userID) return;

  const updates = {
    [`${DbRef.USERS}/${userID}/shiftOffers/${shiftID}/declineReason`]: reason,
    [`${DbRef.USERS}/${userID}/shiftOffers/${shiftID}/status`]: ShiftOfferStatus.DECLINED,
    [`${DbRef.SHIFTS}/${shiftID}/nursesOffered/${userID}/declineReason`]: reason,
    [`${DbRef.SHIFTS}/${shiftID}/nursesOffered/${userID}/status`]: ShiftOfferStatus.DECLINED,
  };

  await dbGroupSet<ShiftOfferStatus | string>(updates);

  trackEvent(TrackingEvent.NURSE_DECLINED_SHIFT_OFFER, {
    shiftID,
    nurseID: userID,
    declineReason: reason,
  }, true);
};

/** Formatted nurse earnings. Example: '$50' */
export const getShiftOfferEarnings = (
  shift: Shift,
  shiftOffer: ShiftOffer,
): string => {
  const baseEarnings = shift.baseEarnings ?? 0;
  const additionalEarnings = shiftOffer.additionalEarnings ?? 0;
  const total = baseEarnings + additionalEarnings;
  return formatCentsToDollar(total);
};

export const getShiftServiceRegionNames = (shift: Shift): string[] => {
  const store = getStore();

  const serviceRegionIDs = Object.keys(shift.serviceRegions ?? {});
  const serviceRegionNames = serviceRegionIDs
    .map((serviceRegionID) => store.getters['variables/getServiceRegionByID'](serviceRegionID)?.name)
    .filter(nullishFilter);
  return serviceRegionNames;
};

export const getShiftTaskNames = (shift: Shift): string[] => {
  const store = getStore();

  const taskIDs = Object.keys(shift.taskIDs ?? {});
  const serviceRegionNames = taskIDs
    .map((taskID) => store.getters['variables/getTaskByID'](taskID)?.info.friendlyName)
    .filter(nullishFilter);
  return serviceRegionNames;
};

const filterPatientWaypoint = (waypointID: string) =>
  getStore().state.waypoint.waypoints[waypointID]?.type === WaypointType.PATIENT;

export const getWaypointIDsByShiftID = (shiftID: string): string[] => {
  const store = getStore();

  const shift = store.state.shifts.shifts[shiftID];
  if (isNullish(shift)) return [];
  const itineraries = Object.keys(shift.itineraries ?? {}).map(
    (id) => store.state.waypoint.itineraries[id],
  ).filter(nullishFilter);
  const waypointIDs = itineraries.map((itinerary) => itinerary.waypoints).flat();
  return deduplicateArray(
    waypointIDs.map((idObj) => idObj.id),
  ).filter(filterPatientWaypoint);
};

export const completeShiftGeoCheck = async (shift: Shift): Promise<void> => {
  const store = getStore();

  if (!shift.geoCheck) throw Error('No geocheck to complete.');

  const location = await getCurrentLocation();
  const fullLocation = await getFullLocationObject(location);
  const { variables } = store.state.variables;
  const completedServiceRegion = await getServiceRegionFromLocation(fullLocation, variables, getValueOnce);

  const isInServiceRegion = !isNullish(completedServiceRegion)
    && !isNullish(shift.serviceRegions?.[completedServiceRegion.id]);

  if (!isInServiceRegion) {
    throw Error('Not in service region');
  }

  const geoCheck: ShiftGeoCheck = {
    ...shift.geoCheck,
    completedLocation: fullLocation,
    completedAt: Date.now(),
    completedServiceRegionID: completedServiceRegion.id,
  };

  trackEvent(TrackingEvent.COMPLETE_SHIFT_GEOCHECK, {
    isInServiceRegion,
    shiftID: shift.id,
    clinicianID: shift.userID,
    clinicianLocation: fullLocation.formattedAddress,
    currentRegion: completedServiceRegion.name,
  }, true);

  await dbUpdate<ShiftGeoCheck>(getShiftPath(shift.id, 'geoCheck'), geoCheck);
};

export const setShiftLeaveFromLocation = async (
  shiftID: string | undefined,
  /** if leaveFrom isn't passed, this property will be set to null on the shift */
  leaveFrom?: LocationObject,
): Promise<void> => {
  const store = getStore();

  if (!shiftID) {
    throw Error(`There was an error setting the shift LeaveFrom location because
    shiftID is undefined`);
  }
  /** Update shift in store */
  const currentShift = store.getters['shifts/getShiftByID'](shiftID);
  if (!currentShift) {
    throw Error(`Could not update shift leaveFrom in store because shift was not
    found`);
  }
  await store.commit('shifts/SET_SHIFT', { ...currentShift, leaveFrom });
  /** Update shift in firebase */
  try {
    await dbSet<LocationObject | null>(
      getShiftPath(shiftID, 'leaveFrom'),
      leaveFrom ?? null,
    );
  } catch (error) {
    console.error(`There was an error setting the shift LeaveFrom location because
    the firebase update failed: ${error}`);
  }
};

export const getWaypointTransitStatus = async (
  itineraryID: string,
): Promise<WaypointTransitInfo> => {
  const store = getStore();

  const itinerary = store.getters['waypoint/getItineraryByID'](itineraryID);
  const now = Date.now();
  const itineraryStartTimestamp = itinerary?.startTime?.timestamp ?? now;

  // get user's current location
  let latestCurrentLocation: LocationObject | undefined;
  try {
    latestCurrentLocation = await getCurrentLocation();
  } catch (error) {
    console.info(error);
  }

  // get transit time from user's current location
  const drivingEstimate = await getItineraryDrivingEstimateRequest({
    leaveFrom: latestCurrentLocation ?? store.state.auth.user?.location?.current,
    itineraryID,
  }) ?? 0;

  /**
  * (transit time current location + current time) < (waypoint start - 2 hours)
  * the nurse will arrive more than 2 hours before the startTime of the waypoint
  */
  if ((drivingEstimate + now) < (itineraryStartTimestamp - hoursToMilliseconds(2))) {
    return {
      status: WaypointTransitStatus.TOO_EARLY,
      drivingEstimate,
    };
  }

  /**
  * (transit time current location + current time) < (waypoint start - 20 minutes)
  * the nurse will arrive more than 20 minutes before the startTime of the waypoint
  */
  if ((drivingEstimate + now) < (itineraryStartTimestamp - hoursToMilliseconds(0.33))) {
    return {
      status: WaypointTransitStatus.EARLY,
      drivingEstimate,
    };
  }

  /**
  * (transit time current location + current time) > waypoint start AND
  * (transit time current location + current time) < (waypoint start + 30 minutes)
  * if the nurse will arrive after the waypoint start AND they will arrive later than 30 minutes
  * after the waypoint start
  */
  if ((drivingEstimate + now) > itineraryStartTimestamp
    && (drivingEstimate + now) < itineraryStartTimestamp + hoursToMilliseconds(0.5)
  ) {
    return {
      status: WaypointTransitStatus.LATE,
      drivingEstimate,
    };
  }

  /*
  * (transit time current location + current time) > (waypoint start + 30 minutes)
  * the Nurse will arrive later than 30 minutes after the waypoint start
  */
  if ((drivingEstimate + now) > itineraryStartTimestamp + hoursToMilliseconds(0.5)) {
    return {
      status: WaypointTransitStatus.TOO_LATE,
      drivingEstimate,
    };
  }

  return {
    status: null,
    drivingEstimate,
  };
};

/** returns a boolean determining if we need to show a blocking modal or not */
export const isWaypointTransitStatusBlocking = (
  status: WaypointTransitStatus | null,
): boolean => {
  const store = getStore();

  if (!status) return false;
  const disablePatientVisitSupplyScanning
    = store.getters['variables/getFeatureFlagByName'](FeatureFlag.DISABLE_WAYPOINT_TRANSIT_STATUS_BLOCKING);

  switch (status) {
    case WaypointTransitStatus.TOO_EARLY:
    case WaypointTransitStatus.TOO_LATE:
      return !disablePatientVisitSupplyScanning;
    case WaypointTransitStatus.EARLY:
    case WaypointTransitStatus.LATE:
      return false;
    default:
      return false;
  }
};

export const getWaypointTransitTextButton = (
  status: WaypointTransitStatus | null,
): string => {
  switch (status) {
    case WaypointTransitStatus.TOO_EARLY:
      return 'I understand';
    case WaypointTransitStatus.TOO_LATE:
      return 'I understand';
    case WaypointTransitStatus.EARLY:
      return 'Yes I’m sure';
    case WaypointTransitStatus.LATE:
      return 'I understand';
    default:
      return 'I understand';
  }
};

export const getWaypointTransitAlertTitle = (
  status: WaypointTransitStatus | null,
): string => {
  switch (status) {
    case WaypointTransitStatus.TOO_EARLY:
      return 'Too early';
    case WaypointTransitStatus.TOO_LATE:
      return 'Contact support';
    case WaypointTransitStatus.EARLY:
      return 'Confirm action';
    case WaypointTransitStatus.LATE:
      return 'Late arrival';
    default:
      return 'Transit warning';
  }
};

export const getWaypointTransitAlertLabel = (
  itineraryID: string,
  status: WaypointTransitStatus | null,
  drivingEstimate: number,
): string => {
  const store = getStore();

  const itinerary = store.getters['waypoint/getItineraryByID'](itineraryID);
  const now = Date.now();
  const itineraryStartTimestamp = itinerary?.startTime?.timestamp ?? now;

  const dateItinerary = formatTimeZoneDate(itinerary?.startTime, 'M/D/YYYY');
  const currentDate = formatTimeZoneDate(
    initTimeZoneDate(itinerary?.startTime?.timeZone, Date.now()),
    'M/D/YYYY',
  );
  // Display date if current date different than itinerary date
  const displayDateOnTime = currentDate !== dateItinerary ? 'M/D/YYYY, ' : '';
  const itineraryTime = formatTimeZoneDate(itinerary?.startTime, `${displayDateOnTime}h:mm a`);
  const wordBeforeItineraryTime = currentDate !== dateItinerary ? 'on' : 'at';
  const eta = now + drivingEstimate;
  const etaFormatted = formatTimeZoneDate(
    initTimeZoneDate(itinerary?.startTime?.timeZone, eta),
    `${displayDateOnTime}h:mm a`,
  );

  switch (status) {
    case WaypointTransitStatus.TOO_EARLY:
      return `It’s too early to start driving to this waypoint.
      You’re expected to be at this stop ${wordBeforeItineraryTime} ${itineraryTime}.\n
      It looks like you’re trying to drive ${timeInMsToHoursAndMinutes(itineraryStartTimestamp - eta)} `
      + 'earlier than expected. Please try again later.';
    case WaypointTransitStatus.TOO_LATE:
      return `
      Your ETA to this location is ${etaFormatted} for a ${itineraryTime} appointment.\n
      Given the significant delay this appointment may be rescheduled, please contact support immediately
      `;
    case WaypointTransitStatus.EARLY:
      return `Your ETA to this location is ${etaFormatted} for a ${itineraryTime} appointment.\n
      Are you sure you want to proceed? This will notify the patient you’re on your way
      `;
    case WaypointTransitStatus.LATE:
      return `
      Your ETA to this location is ${etaFormatted} for a ${itineraryTime} appointment.\n
      We will notify the patient of your late arrival
      `;
    default:
      return 'Transit warning';
  }
};

export const shouldWaypointTransitAlertBeDisplayed = (
  itineraryID: string,
  waypointID: string,
  status: WaypointTransitStatus | null,
): boolean => {
  const store = getStore();

  const itinerary = store.getters['waypoint/getItineraryByID'](itineraryID);
  const firstItineraryID = getFirstItineraryIDOnShift(itinerary?.shiftID);
  if (firstItineraryID !== itineraryID) return false;
  if (itinerary?.waypoints[0]?.id !== waypointID) return false;
  return !!status;
};
