import {
  AnyGetters,
  ExitRouteParams,
  ExtendedCustomModule,
  OrderFlowModule,
  OrderFlowState,
  chartingFlowPrintRequisitionFormsRoute,
  chartingFlowSpecimenBagsRoute,
  homeRoute,
  initOrderFlowModule,
  toastError,
  useUserRole,
  visitsPageRoute,
} from '@caresend/ui-components';

import type { RootState } from '@/store/model';
import { ExtendedOfficesActions, ExtendedOfficesMutations } from '@/store/modules/offices';
import { ExtendedVariablesGetters } from '@/store/modules/variables';

type S = OrderFlowState;

const extraOrderFlowGetters = {
  'orderFlow/getExitRoute': (
    state: S,
    _getters: AnyGetters,
    _rootState: RootState,
  ) => (params: ExitRouteParams) => {
    try {
      if (!params.id) throw new Error('Draft order does not have an ID');

      if (state.draftOrder?.isFulfilledByOffice) {
        return params.isDraft ? chartingFlowSpecimenBagsRoute(
          params.id,
          params.isDraft ?? false,
        ) : chartingFlowPrintRequisitionFormsRoute(
          params.id,
        );
      }

      const userRole = useUserRole();
      if (userRole.isOfficeStaff.value) {
        return visitsPageRoute({ params: { orderID: params.id } });
      }
    } catch (error) {
      toastError(error);
    }

    return homeRoute();
  },
};

const orderFlowModuleExtension = {
  getters: extraOrderFlowGetters,
};

export const orderFlowModule: ExtendedCustomModule<
  OrderFlowModule<
    RootState,
    ExtendedOfficesMutations,
    ExtendedOfficesActions,
    ExtendedVariablesGetters
  >,
  typeof orderFlowModuleExtension
> = initOrderFlowModule(orderFlowModuleExtension);

export type ExtendedOrderFlowModule = typeof orderFlowModule;

export type ExtendedOrderFlowModuleMutations = ExtendedOrderFlowModule['mutations'];
export type ExtendedOrderFlowModuleActions = ExtendedOrderFlowModule['actions'];
export type ExtendedOrderFlowModuleGetters = ExtendedOrderFlowModule['getters'];
