import {
  Barcode,
  BarcodeType,
  BatchSku,
  ByID,
  DbRef,
  FeatureFlag,
  Sku,
  SupplyItem,
  SupplyItemWithSupplyID,
} from '@caresend/types';
import { getStore, getValueOnce, toastError, toastErrorAndReport } from '@caresend/ui-components';
import { generateID, getSupplyItemPath } from '@caresend/utils';

import { SupplyItemType } from '@/store/modules/procedures/model';
import { SetOrUnset } from '@/store/modules/supplies/model';
import { trackScanItem } from '@/views/nurse/helpers/tracking';

export interface ScannableItem {
  barcode?: Barcode;
  /** Barcode text from scanned item if `isScanned: true` */
  barcodeValue?: string;
  /**
   * Needed for Shift supplies, with this we can show the tube color in the list
   */
  color?: string;
  /**
   * Needed for Shift supplies, with this we can show the tube color in the list
   *
   * TODO: the name of this property is outdated because we now also use it for
   * the icons of the blood draw and urine collection supplies, rename to
   * something more generic
   */
  imageColor?: string;
  isScanned: boolean;
  name: string;
  /**
   * If serial (from `SupplyItem.serial`) is passed, it will be strictly used
   * for matching in MultiSupplyFromListScanner. Only include if the user must
   * scan the exact serial.
   */
  serial?: string;
  skuID?: string;
  supplyID?: string;
  /**
   * Supply Item ID from scanned item if `isScanned: true` and the scanned item
   * was a supply item
   */
  supplyItemID?: string;
}

export interface ScannableItemWithKey extends ScannableItem {
  /** A temporary unique key to uniquely identify an item to scan */
  key: string;
}

export interface ScannedItem {
  barcode: Barcode;
  barcodeValue: string;
  /** `SupplyItem.serial` if the scanned item is a supply item. */
  serial?: string;
  skuID?: string;
  supplyID?: string;
}

export interface SupplyItemsToUpdate {
  supplyItems: ByID<SupplyItemWithSupplyID>;
}

export interface SupplyToCheck {
  color?: string;
  imageColor?: string;
  instructions?: string[];
  isChecked?: boolean;
  name: string;
  supplyID: string;
  supplyItemID?: string;
}

export type ScannedItemWithoutBarcode = Omit<ScannedItem, 'barcode' | 'barcodeValue'>;

/**
 * Used to type guard the `value` arg not to be null when `instruction` is `set`.
 */
export const getInstructionIsSet = <T>(
  instruction: SetOrUnset,
  value: T | null,
): value is T => instruction === SetOrUnset.SET && !!value;

export const supplyNameAmberBag = SupplyItemType.AMBER_BAG;
export const supplyNameCareSendBag = SupplyItemType.CARESEND_BAG;
export const supplyNameInsulatedBag = SupplyItemType.INSULATED_BAG;
export const supplyNameProcedureBag = SupplyItemType.PROCEDURE_BAG;
export const supplyNameSpecimenBag = SupplyItemType.SPECIMEN_BAG;

const handleScanningErrorOrWarning = async (
  message: string,
  reportToSentry = false,
  barcode: Partial<Barcode>,
) => {
  if (reportToSentry) toastErrorAndReport(message);
  else toastError(message);

  await trackScanItem(barcode, message);
};

/**
 * Toast, track and report unexpected scanning issue to sentry. 2nd arg can be a
 * `Partial<Barcode>` or supplyItemID. (Do not pass a string as 2nd arg unless
 * it is a supplyItemID.)
 */
export async function handleScanningError(message: string, barcode: Partial<Barcode>): Promise<void>
export async function handleScanningError(message: string, supplyItemID: string): Promise<void>
export async function handleScanningError(message: string, item: Partial<Barcode> | string): Promise<void> {
  const barcodeToTrack = typeof item === 'string'
    ? { id: item, type: BarcodeType.SUPPLY_ITEM }
    : item;

  await handleScanningErrorOrWarning(message, true, barcodeToTrack);
}

/**
 * Toast and track normal scanning issue. 2nd arg can be a `Partial<Barcode>`
 * or supplyItemID.  (Do not pass a string as 2nd arg unless it is a
 * supplyItemID.)
 */
export async function handleScanningWarning(message: string, barcode: Partial<Barcode>): Promise<void>
export async function handleScanningWarning(message: string, supplyItemID: string): Promise<void>
export async function handleScanningWarning(message: string, item: Partial<Barcode> | string): Promise<void> {
  const barcodeToTrack = typeof item === 'string'
    ? { id: item, type: BarcodeType.SUPPLY_ITEM }
    : item;

  await handleScanningErrorOrWarning(message, false, barcodeToTrack);
}

const getBatchItemData = async (
  barcode: Barcode,
): Promise<ScannedItemWithoutBarcode | undefined> => {
  const batchSkuID = await getValueOnce<string>(`${DbRef.BATCH_ITEMS}/${barcode.id}/batchSkuID`);
  if (!batchSkuID) {
    handleScanningError('Invalid batch barcode', barcode);
    return;
  }
  const skuID = batchSkuID;
  const batchSku = await getValueOnce<BatchSku>(`${DbRef.BATCH_SKUS}/${batchSkuID}`);
  const { individualSkuID } = batchSku ?? {};
  const sku = await getValueOnce<Sku>(`${DbRef.SKUS}/${individualSkuID}`);
  const supplyID = sku?.supplyID;

  return {
    skuID,
    supplyID,
  };
};

const getSupplyItemData = async (
  barcode: Barcode,
): Promise<ScannedItemWithoutBarcode | undefined> => {
  const supplyItem = await getValueOnce<SupplyItem>(getSupplyItemPath(barcode.id));
  if (!supplyItem?.skuID) {
    handleScanningError('Invalid supply barcode', barcode);
    return;
  }
  const { serial } = supplyItem;
  const skuID = supplyItem.skuID ?? '';
  const sku = await getValueOnce<Sku>(`${DbRef.SKUS}/${skuID}`);
  if (!sku?.active) {
    handleScanningWarning('Inactive supply. Please contact support.', barcode);
    return;
  }
  const { supplyID } = sku;

  return {
    serial,
    skuID,
    supplyID,
  };
};

export const barcodeValidation = async (
  rawBarcodeValue: string | undefined,
  items?: ScannableItem[],
): Promise<ScannedItem | undefined> => {
  if (!rawBarcodeValue) {
    return;
  }

  /**
   * Shipping labels may contain the "Group Separator" character, which
   * must be stripped.
   */
  const groupSeparatorChar = '\x1D';
  const barcodeValue = rawBarcodeValue.split(groupSeparatorChar).join('');

  const barcode = await getValueOnce<Barcode>(`${DbRef.BARCODES}/${barcodeValue}`);
  if (!barcode) {
    handleScanningWarning('Invalid barcode', { id: barcodeValue });
    return;
  }
  const isBarcodeAlreadyScanned = items?.find((item) => item.barcodeValue === barcodeValue);
  if (isBarcodeAlreadyScanned) {
    handleScanningWarning('Barcode already scanned.', barcode);
    return;
  }

  switch (barcode.type) {
    case BarcodeType.BATCH_ITEM: {
      const data = await getBatchItemData(barcode);
      if (!data) return;
      return { barcode, barcodeValue, ...data };
    }

    case BarcodeType.SUPPLY_ITEM: {
      const data = await getSupplyItemData(barcode);
      if (!data) return;
      return { barcode, barcodeValue, ...data };
    }

    case BarcodeType.GENERIC:
    case BarcodeType.SHIPPING_LABEL: {
      return { barcode, barcodeValue };
    }
  }
};

/**
 * If the item scanned/clicked is not in the list of items to scan, or is
 * already scanned, returns null.
 */
export const getUpdatedItemsToScan = (
  itemsToScan: ScannableItemWithKey[],
  /** Must be passed if scanning */
  scannedItem?: ScannedItem,
  /** Must be passed if using "click" bypass mode (scanning disabled) */
  keyBypass?: string,
): ScannableItemWithKey[] | null => {
  const indexToUpdate = itemsToScan.findIndex((item) => {
    if (item.isScanned) return false;
    if (keyBypass) return item.key === keyBypass;
    if (!scannedItem) return false;

    const scannedItemHasSupplyID = !!scannedItem.supplyID;
    const matchesSupplyID = (
      scannedItemHasSupplyID
      && item.supplyID === scannedItem.supplyID
    );

    /** If `item.serial` is present, serials must match exactly */
    const serialMustMatch = !!item.serial;
    if (serialMustMatch) {
      const matchesSerial = item.serial === scannedItem.serial;
      return matchesSupplyID && matchesSerial;
    }

    const matchesSkuID = item.skuID === scannedItem.skuID;

    return matchesSkuID || matchesSupplyID;
  });

  const currentItem = itemsToScan[indexToUpdate];
  if (!currentItem) {
    handleScanningWarning('Wrong supply', scannedItem?.barcode ?? {});
    return null;
  }

  let supplyItemID: string | undefined;
  if (scannedItem?.barcode?.type === BarcodeType.SUPPLY_ITEM) {
    supplyItemID = scannedItem.barcode.id;
  } else if (keyBypass) {
    // In click mode, assume user is clicking a supplyItem and use the random
    // key as supplyItemID. Include the "scanDisabled" so it is clear that
    // these are fake supplyItemIDs.
    supplyItemID = `scanDisabled-${keyBypass}`;
  }

  const updatedCurrentItem = {
    ...currentItem,
    isScanned: true,
    barcode: scannedItem?.barcode,
    barcodeValue: scannedItem?.barcodeValue,
    supplyItemID,
  };

  // When using bypass, do not change order of items.
  if (keyBypass) {
    // Spread to avoid mutation original array.
    const newItemsToScan = [...itemsToScan];
    newItemsToScan[indexToUpdate] = updatedCurrentItem;
    return newItemsToScan;
  }

  const itemsToScanWithoutCurrentItem = itemsToScan
    .filter((_item, index) => index !== indexToUpdate);

  // Scanned items go to the bottom of the list
  return [...itemsToScanWithoutCurrentItem, updatedCurrentItem];
};

export type ItemWithBarcodeType<T extends BarcodeType> = (ScannableItem | ScannedItem) & {
  barcode: Barcode & { type: T };
}

export const hasBarcodeType = <T extends BarcodeType>(
  item: ScannableItem | ScannedItem,
  type: BarcodeType,
): item is ItemWithBarcodeType<T> => item.barcode?.type === type;

export const getScanningPageLabel = (
  supplyName?: string,
): string => {
  const disablePatientVisitSupplyScanning
    = getStore().getters['variables/getFeatureFlagByName'](FeatureFlag.DISABLE_PATIENT_VISIT_SUPPLY_SCANNING);
  return disablePatientVisitSupplyScanning ? 'Click on the supply below to activate it.'
    : `Scan the barcode on the ${supplyName?.toLowerCase() ?? 'supply'}`;
};

export const initMockSupplyItemWithSupplyID = (
  supplyID: string,
): SupplyItemWithSupplyID => ({
  id: `scanDisabled-${generateID()}`,
  supplyID,
});
