import {
  ByID,
  DenormalizedOrder,
  DraftOrderNew,
  EncryptedOrder,
  OfficeType,
} from '@caresend/types';
import {
  AnyGetters,
  ExtendedCustomModule,
  OfficeModule,
  OfficePatientOption,
  OfficeState,
  initOfficeModule,
} from '@caresend/ui-components';
import { arrayToObj } from '@caresend/utils';
import update from 'immutability-helper';

import type { RootState } from '@/store/model';
import { ExtendedOfficesActions, ExtendedOfficesMutations } from '@/store/modules/offices';
import { ExtendedPlacesActions } from '@/store/modules/places';
import { ExtendedUsersMutations } from '@/store/modules/users';
import { ExtendedVariablesGetters } from '@/store/modules/variables';

type S = OfficeState;

const extendedOfficeMutations = {
  'office/SET_SCHEDULING_OFFICE_MEMBER_ID': (state: S, id: string) => {
    const newOrderFlowSettings = update(state.localSettings.orderFlow, {
      defaultOfficeSchedulingUserID: { $set: id },
    });

    state.localSettings = update(state.localSettings, {
      orderFlow: { $set: newOrderFlowSettings },
    });
  },
};

export type ExtraOfficeActions = {
}

const extraOfficeActions: ExtraOfficeActions = {

};

const extraOfficeGetters = {
  'office/getPatientOptions': (
    state: S,
    _getters: AnyGetters,
    rootState: RootState,
  ): OfficePatientOption[] => {
    const officeID = state.office?.id;
    if (!officeID) return [];
    if (state.office?.type === OfficeType.LAB) {
      return Object.values(
        Object.values(state.office?.partners ?? {}).reduce<ByID<OfficePatientOption>>(
          (acc, office) => ({
            ...acc,
            ...arrayToObj(rootState.offices.patientOptionsByOffice[office.officeID] ?? [], 'id'),
          }), {},
        ));
    }

    return rootState.offices.patientOptionsByOffice[officeID] ?? [];
  },

  'office/isThePrescriberOrInitiator': (
    state: S, _getters: AnyGetters, rootState: RootState,
  ) => (
    orderID: string,
    _order?: DraftOrderNew | EncryptedOrder | DenormalizedOrder,
  ): boolean => {
    const order = _order ?? rootState.orders.orders[orderID];
    const officeID = state.office?.id;
    const officeIsInitiator = order?.createdByOfficeID === officeID;
    const officeIsPrescriber = order?.officeID === officeID;
    return officeIsInitiator || officeIsPrescriber;
  },
};

export const officeModuleExtension = {
  actions: extraOfficeActions,
  getters: extraOfficeGetters,
  mutations: extendedOfficeMutations,
};

export const officeModule: ExtendedCustomModule<
  OfficeModule<
    RootState,
    ExtendedUsersMutations & ExtendedOfficesMutations,
    ExtendedPlacesActions & ExtendedOfficesActions,
    ExtendedVariablesGetters
  >,
  typeof officeModuleExtension
> = initOfficeModule(officeModuleExtension);

export type ExtendedOfficeModule = typeof officeModule;

export type ExtendedOfficeMutations = ExtendedOfficeModule['mutations'];
export type ExtendedOfficeActions = ExtendedOfficeModule['actions'];
export type ExtendedOfficeGetters = ExtendedOfficeModule['getters'];
