import { computed } from 'vue';
import { Location, RouteConfig } from 'vue-router';

import { shiftRoute } from '@/router/locations';
import { routeNames } from '@/router/model';

// Paths below are not full paths - they are based on the parent path:
// /:partnerName?/itinerary-legacy/:itineraryID/
export const routesItineraryFlowRoot: RouteConfig[] = [
  {
    path: '',
    name: routeNames.ITINERARY_FLOW_ITINERARY,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/ItineraryFlowItinerary/ItineraryFlowItinerary.vue'
    ),
    meta: {
      authRequired: true,
      heading: ({ store, route }) => computed(() => {
        let backLocation: Location = { path: '/' };
        const { itineraryID } = route.params;
        const itinerary = itineraryID
          ? store.state.waypoint.itineraries[itineraryID]
          : null;
        const shiftID = itinerary?.shiftID;

        if (shiftID) {
          backLocation = shiftRoute({
            params: { shiftID },
          });
        }

        return {
          title: 'Itinerary details',
          backLocation,
        };
      }),
      noPadding: true,
      profileCompletedRequired: true,
    },
  },
];
