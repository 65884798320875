import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';
import { PasswordMode } from '@/views/signUp/CreatePassword/model';

const routesSignUp: RouteConfig[] = [
  {
    path: '/:partnerName?/signup/verifyemail',
    name: routeNames.VERIFY_EMAIL,
    component: () => import(
      /* webpackChunkName: 'chunk-verify-email' */
      '@/views/signUp/VerifyEmail.vue'
    ),
    meta: {
      hideSignUpButton: true,
      hideSignInButton: true,
    },
    props: true,
  },
  {
    path: '/:partnerName?/finishSignUp',
    name: routeNames.FINISH_SIGN_UP,
    component: () => import(
      /* webpackChunkName: 'chunk-create-password' */
      '@/views/signUp/CreatePassword/CreatePassword.vue'
    ),
    meta: {
      hideSignUpButton: true,
      hideSignInButton: true,
    },
    props: {
      passwordMode: PasswordMode.CREATE,
    },
  },
  {
    path: '/:partnerName?/resetPassword',
    name: routeNames.RESET_PASSWORD,
    component: () => import(
      /* webpackChunkName: 'chunk-create-password' */
      '@/views/signUp/CreatePassword/CreatePassword.vue'
    ),
    meta: {
      hideSignUpButton: true,
      hideSignInButton: true,
    },
    props: {
      passwordMode: PasswordMode.RESET,
    },
  },
  {
    path: '/:partnerName?/signup',
    name: routeNames.SIGN_UP,
    component: () => import(
      /* webpackChunkName: 'chunk-signup' */
      '@/views/signUp/SignUp.vue'
    ),
    meta: {
      hideSignUpButton: true,
    },
  },
  {
    path: '/:partnerName?/joinOffice',
    name: routeNames.JOIN_OFFICE,
    component: () => import(
      /* webpackChunkName: 'chunk-join-office' */
      '@/views/signUp/JoinOffice.vue'
    ),
  },
  {
    path: '/:partnerName?/signup/prescriber',
    name: routeNames.PRESCRIBER_SIGN_UP,
    component: () => import(
      /* webpackChunkName: 'chunk-signup' */
      '@/views/signUp/PrescriberAdmin.vue'
    ),
    meta: {
      hideSignUpButton: true,
    },
    props: true,
  },
  {
    path: '/:partnerName?/signup/assistant',
    name: routeNames.ASSISTANT_SIGN_UP,
    component: () => import(
      /* webpackChunkName: 'chunk-signup' */
      '@/views/signUp/PrescriberAdmin.vue'
    ),
    meta: {
      hideSignUpButton: true,
    },
    props: true,
  },
  {
    path: '/:partnerName?/signup/nurse',
    name: routeNames.NURSE_SIGN_UP,
    component: () => import(
      /* webpackChunkName: 'chunk-signup' */
      '@/views/signUp/Nurse.vue'
    ),
    meta: {
      hideSignUpButton: true,
    },
    props: true,
  },
];

export { routesSignUp };
