import { capitalize } from '@caresend/utils';

export const sanitizeData = <T>(data: T): T =>
  JSON.parse(JSON.stringify(data));

export const isOdd = (num: number): boolean => num % 2 === 1;

export const nameWithVersionNumber = (name: string): string =>
  `${name}-${process.env.VUE_APP_VERSION}`;

export const makePlural = (str: string): string =>
  str.slice(-1) === 's' || str.slice(-1) === 'y' ? str : `${str}s`;

export const titleCase = (string: string): string => string.replace(
  /\w\S*/g,
  (str: string) => str.charAt(0).toUpperCase() + str.substr(1),
);

export const removeSpaces = (str: string): string => str.replace(/\s/g, '');

export const emailIsValid = (email: string): boolean =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const toUpperCaseFilter = (str: string): string => str.toUpperCase();

/**
 * Example: production-staging => Production Staging
 */
export const formatKebabCase = (str: string): string => str.split('-').map(capitalize).join(' ');

export const arrayToString = (
  array: Array<string | null | undefined>,
) => array.filter((item) => !!item).map((item) => item !== null && capitalize(item ?? '')).join(' • ');

export const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const stringToBoolean = (string: string | undefined): boolean => {
  if (!string) return false;
  return string === 'true';
};

export const formatPrice = (cents: number): string => {
  if (cents % 100 === 0) {
    // Ex: $99
    return `$${cents / 100}`;
  }

  // Ex: $99.99
  return `$${(cents / 100).toFixed(2)}`;
};

// TODO: document
export const removeDuplicatesArrayObj = <T>(
  arr: any[],
  field: string,
): T[] => Object.values(arr.reduce((acc, cur) => Object.assign(acc, { [cur[field]]: cur }), {}));

/**
 * Prevents the default behaviour on click, and prevents bubbling events to
 * parent elements.
 *
 * This is not suitable for use directly on router links, as it will prevent
 * navigation.
 */
export const preventDefaultAndParentClick = (event: Event) => {
  event.preventDefault();
  event.stopPropagation();
};
