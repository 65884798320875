import { Shift, ShiftOffer } from '@caresend/types';
import { BasicCard } from '@caresend/ui-components';
import { formatShiftDateTime, isNullish } from '@caresend/utils';
import { PropType, computed, defineComponent } from 'vue';
import { RawLocation } from 'vue-router';

import { getShiftOfferEarnings, getShiftServiceRegionNames } from '@/functions/shifts';
import { shiftOfferRoute } from '@/router/locations';
import { ShiftOfferData } from '@/store/modules/shifts/model';

export default defineComponent({
  name: 'ShiftOfferCard',
  components: {
    BasicCard,
  },
  props: {
    shiftOfferData: {
      type: Object as PropType<ShiftOfferData>,
      required: true,
    },
  },
  setup(props) {
    const shift = computed<Shift>(() => props.shiftOfferData.shift);
    const shiftOffer = computed<ShiftOffer>(() =>
      props.shiftOfferData.shiftOffer,
    );

    const earnings = computed<string>(() =>
      getShiftOfferEarnings(shift.value, shiftOffer.value),
    );
    const formattedShiftTime = computed<string | undefined>(() => {
      if (!shift.value) return 'Shift time not available.';
      return formatShiftDateTime(shift.value, false);
    });

    const shiftServiceRegionNames = computed<string[]>(() => {
      if (isNullish(shift.value)) return [];
      // TODO: store
      return getShiftServiceRegionNames(shift.value);
    });

    const offerRoute = computed<RawLocation>(() =>
      shiftOfferRoute({ params: { shiftID: shift.value.id } }),
    );

    return {
      earnings,
      formattedShiftTime,
      offerRoute,
      shift,
      shiftServiceRegionNames,
    };
  },
});
