import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';

const routesProfile: RouteConfig[] = [
  {
    path: '/:partnerName?/profile',
    name: routeNames.PROFILE,
    component: () => import(
      /* webpackChunkName: 'chunk-profile' */
      '@/views/profile/Profile.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
    },
  },
  {
    path: '/:partnerName?/completeInfo',
    name: routeNames.COMPLETE_INFO,
    component: () => import(
      /* webpackChunkName: 'chunk-complete-info' */
      '@/views/signUp/CompleteInfo.vue'
    ),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/:partnerName?/orderKit',
    name: routeNames.ORDER_KIT,
    component: () => import(
      /* webpackChunkName: 'chunk-order-kit' */
      '@/views/profile/OrderKit.vue'
    ),
    meta: {
      authRequired: true,
    },
  },
];

export { routesProfile };
