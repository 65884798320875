import {
  DenormalizedProcedure,
  EncryptedUser,
  ProcedureFailed,
  ProcedureStatus,
  SampleStatus,
  SupplyCondition,
} from '@caresend/types';
import { getStore } from '@caresend/ui-components';
import { determineProcedureFailureReason, maybeGetProcedureFailedUpdates } from '@caresend/utils';

export const getProcedureFailedUpdates = async (
  mostRecentSampleStatus: SampleStatus,
  procedureID: string,
) => {
  const store = getStore();

  const procedure = store.state.procedures.procedures[procedureID];

  if (!procedure) {
    throw Error(`handleProcedureFailure(): Missing procedure ${procedureID}`);
  }

  const failureReason = determineProcedureFailureReason(
    mostRecentSampleStatus,
  );

  const userID = store.getters['auth/getUserID'];
  if (!userID) {
    throw Error('handleProcedureFailure(): Missing userID');
  }

  const failed: ProcedureFailed = {
    reason: failureReason,
    failedBy: userID,
    failedByRole: store.getters['auth/getUserRole'],
    supplyCondition: SupplyCondition.SUPPLIES_USED,
    timestamp: Date.now(),
  };

  store.commit('procedures/UPDATE_PROCEDURE', {
    id: procedure.id,
    status: ProcedureStatus.FAILED,
    failed,
  });

  const updates = await maybeGetProcedureFailedUpdates(
    procedure,
    failureReason,
    userID,
    store.getters['auth/getUserRole'],
  );

  return updates;
};

export const getPatientByProcedureID = (
  procedureID: string,
): EncryptedUser | undefined => {
  const store = getStore();

  const procedure = store.state.procedures.procedures[procedureID];
  const booking = store.state.bookings.bookings[procedure?.bookingID ?? ''];
  return store.state.users.users[booking?.patientID ?? ''];
};

/**
     * Filter out procedures associated with other labs if the office is
     * neither the prescriber nor the initiator.
     */
export const filterDenormalizedProceduresByPlaceGroup = (
  procedures: DenormalizedProcedure[],
): DenormalizedProcedure[] => {
  const placeGroupID = getStore().state.office?.office?.placeGroupID;
  /**
   * We have only reached this stage because the office is not the prescriber or initiator
   *
   * if the office does not have a placeGroupID, they have no permission to view any procedures
   */
  if (!placeGroupID) return [];

  return procedures.filter((procedure) => {
    const procedurePlaceGroupIDs = Object.keys(procedure.placeGroups ?? {});
    return procedurePlaceGroupIDs.includes(placeGroupID);
  });
};
