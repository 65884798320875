import { computed } from 'vue';
import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';
import { routesSupplyShipment } from '@/router/routes/supplyShipment';
import { routesSupplyTransfer } from '@/router/routes/supplyTransfer';

const routesPacker: RouteConfig[] = [
  {
    path: '/:partnerName?/shipping',
    name: routeNames.SHIPPING,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-packing-shipping' */
      '@/views/packer/Shipping/Shipping.vue'
    ),
    meta: {
      authRequired: true,
      heading: () => computed(() => ({
        title: 'Shipping requests',
        backLocation: { path: '/' },
      })),
      whiteBackground: true,
    },
  },
  {
    path: '/:partnerName?/ship/:supplyShipmentID',
    props: true,
    children: routesSupplyShipment,
    component: () => import(
      /* webpackChunkName: 'chunk-packing-shipping' */
      '@/views/packer/supplyShipmentFlow/SupplyShipmentFlow.vue'
    ),
    meta: {
      authRequired: true,
      whiteBackground: true,
    },
  },
  {
    path: '/:partnerName?/pick-and-pack',
    name: routeNames.PICK_AND_PACK,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-packing-shipping' */
      '@/views/packer/PickAndPack/PickAndPack.vue'
    ),
    meta: {
      authRequired: true,
      heading: () => computed(() => ({
        title: 'Packing requests',
        backLocation: { path: '/' },
      })),
      whiteBackground: true,
    },
  },
  {
    path: '/:partnerName?/pick-and-pack/:supplyTransferID',
    props: true,
    children: routesSupplyTransfer,
    component: () => import(
      /* webpackChunkName: 'chunk-packing-shipping' */
      '@/views/packer/supplyTransferFlow/SupplyTransferFlow.vue'
    ),
    meta: {
      authRequired: true,
      whiteBackground: true,
    },
  },
  {
    path: '/:partnerName?/place-supplies/activate',
    name: routeNames.ADD_SUPPLIES_TO_PLACE,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-add-supplies' */
      '@/views/packer/supplies/AddSuppliesToPlace/AddSuppliesToPlace.vue'
    ),
    meta: {
      authRequired: true,
      heading: () => computed(() => ({
        title: 'Activate supplies',
        backLocation: {
          path: '/',
        },
      })),
      whiteBackground: true,
    },
  },
];

export { routesPacker };
