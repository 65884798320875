import {
  ByID,
  ChartingItem,
  ChartingStatus,
  DbRef,
  EncryptedResult,
  ProcedureStatus,
  Result,
  Sample,
  WaypointActionStatus,
  WaypointActionStatusName,
} from '@caresend/types';
import {
  dbSet,
  encryptData,
  getStore,
  toastError,
  toastErrorAndReport,
} from '@caresend/ui-components';
import { arrayToObj, initStatus, isNullish } from '@caresend/utils';

import { getBookingByProcedureID } from '@/functions/itinerary/waypoints';

export const updateChartingItemsForProcedure = (
  procedureID: string,
  chartingItems: ChartingItem[],
  markCompleted?: boolean,
) => {
  const store = getStore();
  const baseChartingPath
    = `${DbRef.PROCEDURES}/${procedureID}/charting`;
  const itemsPath = `${baseChartingPath}/items`;
  dbSet<ChartingItem[]>(itemsPath, chartingItems);
  if (markCompleted) {
    const statusPath = `${baseChartingPath}/status`;
    dbSet<ChartingStatus>(statusPath, ChartingStatus.COMPLETE);
  }

  const procedureCharting = store.getters['procedures/getProcedureByID'](procedureID)?.charting;
  if (isNullish(procedureCharting)) return;

  store.commit('procedures/UPDATE_PROCEDURE', {
    id: procedureID,
    charting: {
      ...procedureCharting,
      items: chartingItems,
      status: ChartingStatus.COMPLETE,
    },
  });
};

export const updateProcedureStatus = async (
  procedureID: string,
  status: ProcedureStatus,
) => {
  const store = getStore();
  const procedureStatusPath = `${DbRef.PROCEDURES}/${procedureID}/status`;
  try {
    await dbSet<ProcedureStatus>(procedureStatusPath, status);
  } catch {
    throw Error(`There was an error updating procedure ${procedureID} status to
    ${status} in firebase`);
  }

  store.commit('procedures/UPDATE_PROCEDURE', {
    id: procedureID,
    status,
  });
};

export const updateProcedureResults = async (
  procedureID: string,
  results: ByID<Result>,
) => {
  const store = getStore();
  const procedure = store.state.procedures.procedures[procedureID];
  const booking = getBookingByProcedureID(procedureID);

  if (!booking || !procedure) {
    toastErrorAndReport('Missing booking or procedure when saving results.');
    return;
  }

  const { patientID } = booking;
  const resultsArray = Object.values(results);
  if (resultsArray.length && patientID) {
    const resultsPath = `${DbRef.PROCEDURES}/${procedureID}/results`;

    const promises: Promise<EncryptedResult>[] = resultsArray
      .map(async (result) => {
        if (!result.result) throw toastError('Missing result during save attempt');
        return {
          ...result,
          result: await encryptData(result.result, [{ userID: patientID }]),
        };
      });

    const encryptedResultsArray = await Promise.all(promises);
    const encryptedResults = arrayToObj(encryptedResultsArray, 'productID');

    dbSet<ByID<EncryptedResult>>(resultsPath, encryptedResults);
  }
};

export const updateSamplesForProcedure = (
  procedureID: string,
  samples: ByID<Sample>,
) => {
  const samplesPath = `${DbRef.PROCEDURES}/${procedureID}/samples`;
  dbSet<ByID<Sample>>(samplesPath, samples);
};

export const updateWaypointActionStatus = async (
  waypointActionID: string,
  status: WaypointActionStatusName,
) => {
  getStore().commit('waypoint/SET_WAYPOINT_ACTION_STATUS', {
    waypointActionID,
    status,
  });

  const waypointActionStatusPath
    = `${DbRef.WAYPOINT_ACTIONS}/${waypointActionID}/status`;
  const newStatusObj: WaypointActionStatus = {
    status,
    timestamp: Date.now(),
  };
  try {
    await dbSet<WaypointActionStatus>(waypointActionStatusPath, newStatusObj);
  } catch (error) {
    throw Error(`There was an error changing the waypoint action status because:
    ${error}`);
  }
};

export const updateProcessingActionStatus = async (
  processingActionID: string,
  status: WaypointActionStatusName,
): Promise<void> => {
  getStore().commit('waypoint/SET_PROCESSING_ACTION_STATUS', {
    processingActionID,
    status,
  });

  const processingActionStatusPath
    = `${DbRef.WAYPOINT_ACTIONS}/${processingActionID}/status`;
  const newStatusObj = initStatus(status);
  try {
    await dbSet<WaypointActionStatus>(processingActionStatusPath, newStatusObj);
  } catch (error) {
    throw Error(`There was an error changing the processing action status because:
    ${error}`);
  }
};
